import { useState } from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Button,
  Card,
  Col,
  Edit,
  Form,
  Input,
  ListButton,
  Modal,
  RefreshButton,
  // List,
  Row,
  Select,
  Space,
  Typography,
  useForm,
  useModal,
  useSelect,
} from "@pankod/refine-antd";
import { Avatar, List, Menu, Dropdown } from 'antd';
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IBooking } from "interfaces";
import { CheckOutlined, CloseOutlined, DownOutlined } from "@ant-design/icons";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
dayjs.extend(localizedFormat)

const { Title, Text } = Typography;

export const BookingEdit: React.FC<IResourceComponentsProps> = () => {
  // const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const { modalProps: relocateModalProps, show: relocateShow, close: relocateClose } = useModal();

  const t = useTranslate();

  const rights = {
    // admin: true,
    penalty: false,
  }

  // const { formProps, saveButtonProps, queryResult } = useForm<IBooking>();
  const { formProps, saveButtonProps, queryResult, form, formLoading } = useForm<IBooking>({
    redirect: false,
    // metaData: {
    //   depth: olddepthInput,
    // }
  });

  const record = queryResult?.data?.data;

  const menuOption = (
    <Menu>
      <Menu.Item onClick={relocateShow} key={'menu_item_01'}>{t("pages.booking.relocate")}</Menu.Item>
      <Menu.Item key={'menu_item_02'}>{t("pages.booking.hours_dates")}</Menu.Item>
    </Menu>
  );

  const menuSupplement = (
    <Menu>
      <Menu.Item key={'menu_item_01'}>{t("pages.booking.extras")}</Menu.Item>
      <Menu.Item key={'menu_item_02'}>{t("pages.booking.consos")}</Menu.Item>
      <Menu.Item key={'menu_item_03'}>{t("pages.booking.invoices")}</Menu.Item>
    </Menu>
  );

  const menuMail = (
    <Menu>
      <Menu.Item key={'menu_item_01'}>{t("pages.booking.mail_confimation")}</Menu.Item>
      <Menu.Item key={'menu_item_02'}>{t("pages.booking.mail_invoice")}</Menu.Item>
    </Menu>
  );

  // const record = 
  // const { selectProps: categorySelectProps } = useSelect<IBooking>({
  //   resource: "categories",
  //   defaultValue: queryResult?.data?.data.category.id,
  // });
  // return (<></>)
  return (
    <>
      <Edit
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{
          extra: [
            // <div key={'div_01'}>
            <Dropdown key={'div_extra_01'} overlay={menuSupplement}>
              <Button className="antd-btn-warning">
                <Space>
                  {t("pages.booking.supplement")}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>,
            <Dropdown key={'div_extra_02'} overlay={menuMail}>
              <Button className="antd-btn-success">
                <Space>
                  {t("pages.booking.email")}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>,
            <Dropdown key={'div_extra_03'} overlay={menuOption}>
              <Button className="antd-btn-success">
                <Space>
                  {t("pages.booking.options")}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>,
            <ListButton key={'div_extra_04'}></ListButton>,
            <RefreshButton key={'div_extra_05'}></RefreshButton>,
            // </div>
          ]
        }}
      >
        <Form {...formProps} layout="vertical">
          {/* <Form.Item
            label={t("Bookings.fields.title")}
            name="title"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item> */}
          <Row >

            <Col xs={24} sm={24} md={24} lg={12} xl={10}
            // style={{justifyContent:'center', alignItems:'center', }}
            >
              <Card key={"card_01_client"} title={t("pages.booking.client")} extra={record?.client.fancy_name} style={{ backgroundColor: "#EFEFEF", width: "98%", maxWidth: "490px", padding: '10px', margin: "0px auto 10px auto" }}>
                <List
                // style={{margin: '1em'}}

                // style={{alignSelf: "center"}}

                >
                  {/* <List.Item>
          <Title level={5}>{t("pages.booking.client")}</Title>
          <Text>{record?.client.fancy_name}</Text>
        </List.Item> */}

                  <List.Item>
                    <Title level={5}>{t("pages.booking.email")}</Title>
                    <Text>{record?.client.email}</Text>
                  </List.Item>

                  <List.Item>
                    <Title level={5}>{t("pages.booking.phone")}</Title>
                    <Text>{record?.client.phone}</Text>
                  </List.Item>

                  {/* <List.Item onClick={() => setShowCustomRequest(!showCustomRequest)}>
          <Title level={5}>{t("pages.booking.custom_request")}</Title>
          <Text>
          {showCustomRequest ? record?.custom_request : `${record?.custom_request ? record?.custom_request.substring(0, 50)+'...' : ''}`}
          {!showCustomRequest && record?.custom_request ? <EllipsisOutlined style={{fontSize: 25, color:'grey'}} /> : '' }
          </Text>
        </List.Item> */}

                  <List.Item style={{ justifyContent: 'center' }}>
                    <Title level={5}>{t("pages.booking.custom_request")}</Title>
                  </List.Item>

                  <List.Item>
                    <Text>
                      {record?.custom_request}
                    </Text>
                  </List.Item>

                </List>

              </Card>
            </Col>


            <Col xs={24} sm={24} md={24} lg={12} xl={14}>

              <Card key={"card_02_resa"} title={t("pages.booking.paid")} extra={record?.status.fullvalid ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />} style={{ backgroundColor: "#EFEFEF", width: "98%", maxWidth: "490px", padding: '10px', margin: "0px auto 10px auto" }}>
                <List
                // style={{margin: '1em'}}


                >
                  {/* <List.Item>
            <Title level={5}>{t("pages.booking.paid")}</Title>
            <Text>{record?.status.fullvalid ? <CheckOutlined style={{color: "green"}}/> : <CloseOutlined style={{color: "red"}}/> }</Text>
          </List.Item> */}

                  <List.Item>
                    <Title level={5}>{t("pages.booking.total_price")}</Title>
                    <Text>{`${record?.total_price ? record?.total_price : '-'} €`}</Text>
                  </List.Item>

                  <List.Item>
                    <Title level={5}>{t("pages.booking.is_insurance")}</Title>
                    <Text>{record?.is_insurance ? <CheckOutlined style={{ color: "green" }} /> : <CloseOutlined style={{ color: "red" }} />}</Text>
                  </List.Item>


                  {
                    !rights.penalty &&
                    <List.Item>
                      <Title level={5}>{t("pages.booking.tr_book")}</Title>
                      <Text>{record?.tr_book}</Text>
                    </List.Item>
                  }

                  {
                    !rights.penalty &&
                    <List.Item>
                      <Title level={5}>{t("pages.booking.invoice")}</Title>
                      <Text>{null}</Text> {/*todo*/}
                    </List.Item>
                  }

                  <List.Item>
                    <Title level={5}>{record?.camefrom}</Title>
                    <Text>{dayjs(record?.booking_timestamp).locale(t("short_lang")).format('ddd D/M hh:mm:ss')}</Text>
                  </List.Item>

                  {/* <List.Item>
            <Title level={5}>{t("pages.booking.booking_timestamp")}</Title>
            <Text>{dayjs(record?.booking_timestamp).locale(t("short_lang")).format('ddd D/M hh:mm:ss')}</Text>
          </List.Item> */}

                </List>
              </Card>

              {record?.bookingrooms.map((item, i) =>
                <Card key={"card_03_rooms"} title={item.room.name} style={{ backgroundColor: "#EFEFEF", width: "98%", maxWidth: "490px", padding: '10px', margin: "0px auto 10px auto" }}>

                  <List>

                    <List.Item>
                      <Title level={5}>{item.room.name}</Title>
                      <Text>{`${dayjs(item.start).locale(t("short_lang")).format('ddd D MMM HH:mm')} - ${dayjs(item.end).locale(t("short_lang")).format('ddd D MMM HH:mm')}`}</Text>
                    </List.Item>

                    <List.Item>
                      <Title level={5}>{t("pages.booking.room_code")}</Title>
                      <Text>{item.code}</Text>
                    </List.Item>

                    <List.Item>
                      <Title level={5}>{t("pages.booking.nb_guests")}</Title>
                      <Text>{item.nb_guest}</Text>
                    </List.Item>

                    <List.Item>
                      <Title level={5}>{t("pages.booking.infos_clean")}</Title>
                    </List.Item>

                    {Object.keys(item.infos_clean).map((valDate, index) => {
                      const infos = item.infos_clean[valDate]
                      return (
                        <List.Item key={`list_infosclean_cleantype_${index}`}>
                          <Text>{`${valDate}:`}</Text>
                          <Text>{infos.clean_type}</Text>
                          <Text>{infos.comment}</Text>
                        </List.Item>
                      )
                    })}

                    <List.Item>
                      <Title level={5}>{t("pages.booking.extras")}</Title>
                    </List.Item>


                    <List.Item>
                      <Title level={5}>{t("pages.booking.consos")}</Title>
                    </List.Item>

                  </List>
                </Card>
              )}
            </Col>

          </Row>

        </Form>
      </Edit>
      <Modal onOk={relocateClose} title={t("pages.booking.relocate")} {...relocateModalProps}>
        Dummy Modal Content
      </Modal>
    </>
  );
};
