import { useState, useEffect } from "react";
import { useTranslate, IResourceComponentsProps, useList, useNavigation } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Select,
  useSelect,
  useForm,
  DatePicker,
} from "@pankod/refine-antd";
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
import { IYieldAnalysis, IPriceZone } from "interfaces";

dayjs.extend(localizedFormat)

export const YieldanalysisCreate: React.FC<IResourceComponentsProps> = (props) => {

  const url = window.location.pathname
  const url_pricezone_id = Number(url.substring(url.lastIndexOf('/')+1))

  const [pricezone, setPricezone] = useState((url_pricezone_id === NaN)? "": `${url_pricezone_id}`);

  const t = useTranslate();
  
  const { formProps, saveButtonProps, form, formLoading } = useForm<IYieldAnalysis>({
    redirect: 'edit',
  });

  const { selectProps: pricezoneSelectProps } = useSelect<IPriceZone>({
    resource: "pricezones",
    optionLabel: "name",
  });

  const { data: lastYieldAnalysisQueryResult, isLoading } = useList<IYieldAnalysis>({
      resource: "yieldanalysis",
      config: {
        sort: [{ order: "desc", field: "id" }],
        filters: [
          {
              field: "pricezone__id",
              operator: "eq",
              value: pricezone,
          },
        ],
        pagination: { pageSize: 1}
      },
  });

  const minEndDate = (lastYieldAnalysisQueryResult?.data[0]?.end)? dayjs.utc(lastYieldAnalysisQueryResult?.data[0]?.end): dayjs().add(6, 'month');
  const maxEndDate = minEndDate.add(1, 'year');
  const comment = (lastYieldAnalysisQueryResult?.data[0]?.comment)? lastYieldAnalysisQueryResult.data[0].comment: '';
  const compared_pricezones = (lastYieldAnalysisQueryResult?.data[0]?.compared_pricezones)? lastYieldAnalysisQueryResult.data[0].compared_pricezones.map(obj => obj.id): [];

  const disabledDate = (current:dayjs.Dayjs) => {
    return current && (current < minEndDate || current > maxEndDate);
  }
  
  useEffect(() => {  
    form.setFields([
      {name: 'end', value: minEndDate},
      {name: 'pricezone_id', value: pricezoneSelectProps.options?.find(option => option.value == pricezone)?.value},
      {name: 'comment', value: comment},
      {name: 'compared_pricezone_ids', value: compared_pricezones},
    ])
  });

  return (
    <Create 
      saveButtonProps={saveButtonProps}
      isLoading={formLoading}
      title={t("yieldanalysis.newyieldanalysis")}
    >

      <Form {...formProps} layout="vertical">

        <Form.Item
          label={t("rooms.pricezone")}
          name={"pricezone_id"}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select onChange={e => setPricezone(`${e}`)} {...pricezoneSelectProps} />
        </Form.Item>

        <Form.Item
          label={t("yieldanalysis.end")}
          name="end"
          rules={[
            {
              required: true,
            },
          ]}
          getValueProps={(value) => ({
            value: value ? dayjs.utc(value) : "",
          })}

          hidden={ pricezone==""||isLoading }
          
        >
          <DatePicker allowClear={false} showToday={false} disabledDate={disabledDate} format={t("short_date")} monthCellRender={(curr) => dayjs(curr).locale("fr").format("MMM")}/>
        </Form.Item>

        <Form.Item
          label={t("yieldanalysis.start")}
          name="start"
          rules={[
            {
              required: true,
            },
          ]}
          initialValue={dayjs()}
          hidden
        >
          <DatePicker allowClear={false} showToday={false} format={t("short_date")}/>
        </Form.Item>

        <Form.Item
          label={t("comment")}
          name="comment"
          hidden={ pricezone==""||isLoading }
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label={t("menu.pricezones")}
          name="compared_pricezone_ids"
          hidden={ pricezone==""||isLoading }
        >
          <Select
            mode="multiple"
            allowClear
            options={pricezoneSelectProps.options?.filter(x => x.value != pricezone)}
          >
          </Select>

        </Form.Item>

      </Form>
    </Create>
  );
};
