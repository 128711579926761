import React, { FC } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

type inputEditorProps = {
    value?: string,
    onChange?: any,
    modules?: any | undefined,
    formats?: string[] | undefined,
}
const Color = Quill.import("formats/color");
Quill.register(Color, true);
  
export const InputEditor: FC<inputEditorProps> = ({ value, onChange, modules, formats }) => {
    if (modules === undefined) {
        modules = {
            toolbar: {
                container: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote",],
                    [{ color: [] }],
                    [{ background: [] }],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["clean"],
                ],
            },
        };
    }
    if (formats === undefined) {
        formats = [
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "color",
            "background",
            "list",
            "bullet",
        ];
    }

        

    const onChangeHandler = (value:any) => onChange(replaceRGBWithHex(value))

    const quillRef = React.createRef<any>();

    return (
            <ReactQuill
                ref={quillRef}
                theme="snow"
                modules={modules}
                formats={formats}
                defaultValue={value}
                onChange={onChangeHandler}
            />
    );
};

const replaceRGBWithHex = (content: string): string => {
    // Replace RGB colors with their hex equivalents
    const updatedContent = content.replace(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/g, (match, r, g, b) => {
      return rgbToHex(`rgb(${r}, ${g}, ${b})`);
    });
  
    return updatedContent;
  };
  
const rgbToHex = (rgb: string): string => {
    // Convert RGB to hex
    const hex = rgb.replace(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/, (match, r, g, b) => {
      return `#${(1 << 24 | +r << 16 | +g << 8 | +b).toString(16).slice(1)}`;
    });
    return hex;
  };
