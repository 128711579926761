import React from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import { Edit, useForm } from "@pankod/refine-antd";
import { IThematic } from "interfaces";
import { ThematicForm } from "./form";

export const ThematicEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, queryResult, form, formLoading, onFinish, mutationResult } = useForm<IThematic>({ 'redirect': 'edit' });
  const obj_name = queryResult?.data?.data?.default_name

  return (
    <Edit saveButtonProps={saveButtonProps} title={`${t('buttons.edit')} ${obj_name}`}>
      <ThematicForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />
    </Edit>
  );
};
