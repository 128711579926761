import React from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {  Create, useForm } from "@pankod/refine-antd";
import { IPartner, IPartnerCategory } from "interfaces";
import { PartnerCategoryForm, PartnerForm } from "./form";

export const PartnerCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, queryResult, form, formLoading, onFinish, mutationResult } = useForm<IPartner>({ 'redirect': 'edit' });

  return (
    <Create saveButtonProps={saveButtonProps} title={`${t('buttons.create')} ${t('menu.partners')}`}>
      <PartnerForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />
    </Create>
  );
};

export const PartnerCategoryCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, queryResult, form, formLoading, onFinish, mutationResult } = useForm<IPartnerCategory>({ 'redirect': 'edit' });

  return (
    <Create saveButtonProps={saveButtonProps} title={`${t("buttons.create")}s ${t("posts.fields.category.title")}s ${t("menu.partners")}`}>
      <PartnerCategoryForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />
    </Create>
  );
};
