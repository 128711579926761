import { useModalForm, Modal, DatePicker, Form, Input, Typography, Select } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { InputPlusMinus } from "components/inputs/inputplusminus";
import { IBooking, IBookingRoom, IBookingRoomPrice, IInfosClean } from "interfaces";
import { forwardRef, useImperativeHandle, useState } from "react";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
dayjs.extend(localizedFormat)

const { Title, Text } = Typography;


export const ChangeCleanConsignModal = forwardRef((props, ref) => {
    const t = useTranslate();
    const { modalProps, formProps, form, queryResult, id, show, close, formLoading, } = useModalForm<IBooking>({
      action: "edit",
      redirect: false,     
    });
    const bookingrooms: [IBookingRoom] = formProps.form?.getFieldValue("bookingrooms")
    const booking = queryResult?.data?.data;
    modalProps.title = <Title level={4} >{`${t("buttons.edit")} ${t("pages.booking.title")}`}</Title>
  
    useImperativeHandle(ref, () => ({
      show(id: string) {
        show(id);
      }
    }));
  
  
    const formSubmit = () => {
      form.submit() 
    }    
  
    // if (formLoading || booking == undefined || bookingrooms == undefined) return (<></>)
    return (
      <Form  {...formProps}>
      <Modal title={t("pages.booking.relocate")} {...modalProps} okButtonProps={{onClick: () => formSubmit()}}>
  
      {/* <DatePicker.RangePicker allowClear={false} format={t("short_date")} onChange={rangeChange} monthCellRender={(curr) => dayjs(curr).locale("fr").format("MMM")} defaultValue={[dayjs.utc(bookingrooms?.[0].start), dayjs.utc(bookingrooms?.[0].end)]}/> */}
  
          <Form.Item
            name="context"
            initialValue="update_infosclean"
            hidden
          ><Input/></Form.Item>
          
        {bookingrooms?.map((bookingroom: IBookingRoom, id: string | number) => {
        return (<div key={`ChangeCleanConsignModal_input_div_${id}`}>
            <Form.Item
                name={["bookingrooms", id, "id"]}
                initialValue={bookingroom.id}
                hidden
            >
                <Input/>
            </Form.Item>

            <br></br>  
            <Title level={4}>{`${bookingroom?.room?.name}`}</Title>
            <table>
                <tbody>   
                {Object.keys(bookingroom.infos_clean).map((valDate, index) => {
                    const infos = bookingroom.infos_clean[valDate]
                    return (
                    <tr
                        key={`ChangeCleanConsignModal_input_div__${id}_${index}`}
                    >

                        <td> {/* LABEL */}
                          <div className="ant-col ant-form-item-label">
                            <div className="ant-row ant-form-item ant-form-item-has-success">
                              <label style={{fontWeight: "bold"}} >{dayjs.utc(valDate).locale(t("short_lang")).format('dddd DD/MM/YY')}:</label>
                            </div>
                          </div>
                        </td>

                        <td>
                          <Form.Item
                            name={["bookingrooms", id, "infos_clean", valDate, "clean_type"]}
                            rules={[
                                {
                                required: true,
                                },
                            ]}
                            >
                            <Select
                              options={[
                                { value: 'DND', label: t('pages.booking.infos_cleans.DND') },
                                { value: 'LCL', label: t('pages.booking.infos_cleans.LCL') },
                                { value: 'FCL', label: t('pages.booking.infos_cleans.FCL') }
                              ]}
                            />
                          </Form.Item>
                        </td>
                        <td>
                            <Form.Item
                            label={""}
                            name={["bookingrooms", id, "infos_clean", valDate, "comment"]}
                            >
                            <Input placeholder={t("comment")} ></Input>
                            </Form.Item>
                        </td>
                    </tr>
                    )
                })}
                </tbody>
            </table>
          </div>)
        })}
      </Modal>
      </Form>
    )
});