import {
  useTranslate,
  IResourceComponentsProps,
  useMany,
  useNavigation,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useSelect,
  TagField,
  FilterDropdown,
  Select,
  ShowButton,
  Button,
} from "@pankod/refine-antd";
import { IAgenda, ICategory } from "interfaces";

export const AgendaList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { create } = useNavigation()

  const { tableProps, sorter } = useTable<IAgenda>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  // const categoryIds =
  //   tableProps?.dataSource?.map((item) => item.category.id) ?? [];
  // const { data: categoriesData, isLoading } = useMany<ICategory>({
  //   resource: "categories",
  //   ids: categoryIds,
  //   queryOptions: {
  //     enabled: categoryIds.length > 0,
  //   },
  // });

  // const { selectProps: categorySelectProps } = useSelect<ICategory>({
  //   resource: "categories",
  // });

  return (
    <List
      title={t("menu.agendas")}
      pageHeaderProps={{
        extra: [
          <Button key="page_header_extra_1" onClick={() => create(t("menu.agendas"))}>+</Button>,
        ],
        // subTitle:`${t("yieldanalysis.yieldanalysis")} ${resource?.dateref ? ' - ' + dayjs(resource.dateref).locale(t("short_lang")).format('ddd D/M') : ''} ${resource?.modified ? ` (${t('last_modified')} ` + dayjs(resource.modified).locale(t("short_lang")).format('D/M, HH:mm') + ')' : ''}`
      }}

    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("agendas.agenda")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("agenda", sorter)}
          sorter
        />
        {/* <Table.Column
          dataIndex="status"
          key="status"
          title={t("agendas.")}
          render={(value) => <TagField value={value} />}
          defaultSortOrder={getDefaultSortOrder("status", sorter)}
          sorter
        /> */}
        {/* <Table.Column
          dataIndex="createdAt"
          key="createdAt"
          title={t("agendas.fields.createdAt")}
          render={(value) => <DateField value={value} format="LLL" />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        /> */}
        {/* <Table.Column
          dataIndex={["category", "id"]}
          title={t("agendas.fields.category.title")}
          render={(value) => {
            if (isLoading) {
              return <TextField value={t("loading")} />;
            }

            return (
              <TextField
                value={
                  categoriesData?.data.find((item) => item.id === value)?.title
                }
              />
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("agendas.fields.category.filter.placeholder")}
                {...categorySelectProps}
              />
            </FilterDropdown>
          )}
        /> */}
        <Table.Column<IAgenda>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
