import {
  useTranslate,
  IResourceComponentsProps,
  useNavigation,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  Button,
  Form,
  Input,
  Icons,
} from "@pankod/refine-antd";
import { IThematic } from "interfaces";
import { useState } from "react";

export const ThematicList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { create } = useNavigation()

  const { tableProps, sorter, searchFormProps } = useTable<IThematic>({
    initialPageSize: 20,
    initialSorter: [
      {
        field: "texts__name",
        order: "asc",
      },
    ],
  });

  return (
    <List
      title={t("menu.thematics")}
      pageHeaderProps={{
        extra: [
          <Button key="page_header_extra_1" onClick={() => create(t("menu.thematics"))}>+</Button>,
        ],
      }}

    >
      <Form 
        onValuesChange={() => searchFormProps.form?.submit()}
        key="form"
        {...searchFormProps}
      >
      </Form>

      <Table {...tableProps} 
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
        rowKey="id"
        >
        <Table.Column
          dataIndex="default_name"
          key="texts__name"
          title={t("customers.lname")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("default_name", sorter)}
          sorter
        />
        <Table.Column<IThematic>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

// export const PartnerCategoryList: React.FC<IResourceComponentsProps> = () => {
//   const t = useTranslate();
//   const { create } = useNavigation()

//   const [ inputValue, setInputValue ] = useState("")

//   const meta = inputValue == '' ? {} : { search: inputValue }

//   const { tableProps, sorter } = useTable<IPartnerCategory>({
//     initialPageSize: 20,
//     metaData: meta,
//     initialSorter: [
//       {
//         field: "default_name",
//         order: "asc",
//       },
//     ],
//   });

//   return (
//     <List
//       title={`${t("posts.fields.category.title")}s ${t("menu.thematics")}`}
//       pageHeaderProps={{
//         extra: [
//           <Button key="page_header_extra_1" onClick={() => create("partnercategories")}>+</Button>,
//         ],
//       }}
//     >
//       <Table {...tableProps} 
//         pagination={{
//           ...tableProps.pagination,
//           showSizeChanger: true,
//         }}
//         rowKey="id"
//         >
//         <Table.Column
//           dataIndex="default_name"
//           key="default_name"
//           title={t("customers.lname")}
//           render={(value) => <TextField value={value} />}
//           defaultSortOrder={getDefaultSortOrder("default_name", sorter)}
//           sorter
//         />
//         <Table.Column
//           dataIndex="display_order"
//           key="display_order"
//           title={t("display_order")}
//           render={(value) => <TextField value={value} />}
//           defaultSortOrder={getDefaultSortOrder("display_order", sorter)}
//           sorter
//         />
//         <Table.Column<IPartner>
//           title={t("table.actions")}
//           dataIndex="actions"
//           render={(_, record) => (
//             <Space>
//               <EditButton hideText size="small" recordItemId={record.id} />
//               <DeleteButton hideText size="small" recordItemId={record.id} />
//             </Space>
//           )}
//         />
//       </Table>
//     </List>
//   );
// };
