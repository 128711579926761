import { useEffect } from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  DatePicker,
  Edit,
  Form,
  Input,
  Radio,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IAgenda, IEvent } from "interfaces";
import dayjs from "dayjs";
import 'dayjs/locale/fr'

import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

export const EventEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { form, formProps, saveButtonProps, queryResult: eventQueryResult, id: eventId } = useForm<IEvent>();
  // const event = eventQueryResult?.data?.data

  const { selectProps: agendaSelectProps } = useSelect<IAgenda>({
    resource: "agendas",
    optionLabel: "name",
  });


  const eventagenda_ids = (eventQueryResult?.data?.data.eventagendas)? eventQueryResult?.data?.data.eventagendas.map(obj => obj.id): [];
  const eventagenda_range = { 
    'start': dayjs.utc(eventQueryResult?.data?.data.start),
    'end': dayjs.utc(eventQueryResult?.data?.data.end)
  }
  useEffect(() => {  
    form.setFields([
      {name: 'eventagenda_ids', value: eventagenda_ids},
      {name: 'start', value: eventagenda_range['start']},
      {name: 'end', value: eventagenda_range['end']},
      {name: 'range', value: [
        eventagenda_range['start'], 
        eventagenda_range['end']
      ]},
    ])
  });

  function rangeChange(a: any) {
    form.setFields([
      {name: 'start', value: a[0]},
      {name: 'end', value: a[1]},
    ])
  }

  const resource = eventQueryResult?.data?.data as IEvent

  return (
    <Edit title={`${resource?.name || ''}`} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("events.event")}
          name="name"
          rules={[
            {
              required: true,
              max: 25,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("menu.agendas")}
          name={"eventagenda_ids"}
        >
          <Select
            mode="multiple"
            allowClear
            options={agendaSelectProps.options}
          >
          </Select>
        </Form.Item>
        <Form.Item

          label={t("events.dates")}
          name="range"
          rules={[
            {
              required: true,
            },
          ]}          
        >
          <DatePicker.RangePicker allowClear={false} format={t("short_date")} onChange={rangeChange}  monthCellRender={(curr) => dayjs(curr).locale("fr").format("MMM")}/>
        </Form.Item>
        <Form.Item
          name="start"
          hidden
        >
          <DatePicker></DatePicker>
        </Form.Item> 
        <Form.Item
          name="end"
          hidden
        >
          <DatePicker></DatePicker>
        </Form.Item> 
        <Form.Item
          label={t("events.comment")}
          name="comment"
          rules={[
            {
              required: false,
              max: 75,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("events.grade")}
          name="grade"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Radio.Group>
            <Radio value={0}>0</Radio>
            <Radio value={1}>1</Radio>
            <Radio value={2}>2</Radio>
            <Radio value={3}>3</Radio>
            <Radio value={4}>4</Radio>
            <Radio value={5}>5</Radio>
            <Radio value={6}>6</Radio>
            <Radio value={7}>7</Radio>
            <Radio value={8}>8</Radio>
            <Radio value={9}>9</Radio>
            <Radio value={10}>10</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Edit>
  );
};
