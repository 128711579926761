import React, { useState } from "react";
import { useTranslate, IResourceComponentsProps, file2Base64 } from "@pankod/refine-core";
import { EuroCircleOutlined, PlusOutlined, DeleteOutlined, CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Upload, getValueFromEvent, Select, Switch, Typography, useSelect, Rate, Button, FormInstance, FormProps, TimePicker, Tabs, UploadFile, Image, Space } from "@pankod/refine-antd";
import { IPartnerCategory, IPartnerCategorytext, IPartnerSchedule, IPartnertext } from "interfaces";
import IntlTelInput from "react-intl-tel-input";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Title, Text } = Typography;

interface FunctProps extends IResourceComponentsProps {
    formProps: FormProps<{}>;
    form: FormInstance<{}>;
    queryResult: any;
    formLoading: boolean;
}


export const PartnerForm: React.FC<FunctProps> = ({ form, formLoading, formProps, queryResult }) => {
    const t = useTranslate();
    const col_center_half_xs = { span: 24, offset: 0 }
    const col_center_full_lg = { span: 12, offset: 6 }

    return (

        <Tabs defaultActiveKey="1" centered>
            <Tabs.TabPane forceRender tab={`${t('partner.infos')}`} key="1">
                <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                    {!formLoading && <PartnerInfoForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />}
                </Col>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender tab={`${t('rooms.texts.app.translate')}`} key="2">
                <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                    {!formLoading && <LangTextPartnerForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />}
                </Col>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender tab={`${t('partner.picture')}s`} key="3">
                <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                    {!formLoading && <PartnerPictureForms form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />}
                </Col>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender tab={`${t('partner.opening_hours')}s`} key="4">
                <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                    {!formLoading && <PartnerSchedulesForm form={form} queryResult={queryResult} formProps={formProps} />}
                </Col>
            </Tabs.TabPane>
        </Tabs>
    );
}

export const PartnerInfoForm: React.FC<FunctProps> = (props) => {
    const [phoneError, setPhoneError] = useState<boolean>(false)

    const t = useTranslate();
    const { formProps, queryResult } = props

    const { selectProps: categorySelectProps } = useSelect<IPartnerCategory>({
        resource: "partner_categories",
        defaultValue: queryResult?.data?.data?.id,
        optionLabel: 'default_name',
    });

    const customIcons: Record<number, React.ReactNode> = {
        1: <EuroCircleOutlined />,
        2: <EuroCircleOutlined />,
        3: <EuroCircleOutlined />,
        4: <EuroCircleOutlined />,
    };

    return (
        <Form {...props.formProps} layout="vertical">

            <Form.Item
                label={t("posts.fields.category.title")}
                name={["partner_category", "id"]}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Select {...categorySelectProps} />
            </Form.Item>

            <br />
            <Row>
                <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${queryResult?.data?.data?.lat}%2C${queryResult?.data?.data?.lng}`}>
                    {`${t('position')}: lat ${queryResult?.data?.data?.lat} lng ${queryResult?.data?.data?.lng}`}
                </a>
            </Row>
            <br />
            <Form.Item
                label={t("address.address")}
                name="street_address"
                rules={[{ required: true, max: 35 }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("address.street_number")}
                name="street_number"
                rules={[{ required: true, max: 10 }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("address.additional_address")}
                name="additional_address"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("address.zip")}
                name="postal_code"
                rules={[{ required: true, max: 10 }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("address.city")}
                name="city"
                rules={[{ required: true, max: 30 }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("products.price")}
                name="price_type"
                rules={[{ required: true }]}
                getValueFromEvent={(e) => e > 0 ? e : 1}
            >
                <Rate count={4} character={({ index }: { index: number }) => customIcons[index + 1]} />
            </Form.Item>
            <Form.Item
                label={t("pages.booking.email")}
                name="email"
                rules={[{ max: 254 }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("partner.phone")}
                name="phone"
                initialValue={''}
            >
                <IntlTelInput
                    formatOnInit
                    fieldName="phone"
                    inputClassName={`ant-input ${phoneError ? '' : 'ant-input-status-error'}`}
                    placeholder="Enter Your Number"
                    numberType="MOBILE"
                    value={formProps.form?.getFieldValue("phone")}
                    onPhoneNumberChange={(status, phoneNumber, country, fullNumber) => {
                        formProps.form?.setFieldsValue({ "phone": fullNumber.replace(/\s+/g, '') })
                        setPhoneError(status)
                    }}
                />
            </Form.Item>
        </Form>
    );
};

interface PartnerPictureFormsProps extends IResourceComponentsProps {
    formProps: FormProps<{}>;
    form: FormInstance<{}>;
    queryResult: any;
    formLoading: boolean;
}

export const PartnerPictureForms: React.FC<PartnerPictureFormsProps> = (props) => {
    const t = useTranslate();
    const { formProps, queryResult, form, formLoading } = props

    const rules_35 = [
        {
            max: 35,
        }
    ]
    console.log('value')

    // Custom validator to handle base64 encoding directly in the form field
    const validatePictures = async (rule: any, value: any, callback: any) => {
        const base64Files = [];

        for (const file of value) {
            if (file.originFileObj) {
                const path = await file2Base64(file);

                base64Files.push({
                    ...file,
                    path,
                    display_order: 0,
                });
            } else {
                base64Files.push(file);
            }
        }

        form.setFieldsValue({ pictures: base64Files });
        // callback(); // Call the callback to indicate validation success
    };

    interface myfile extends UploadFile<any> {
        id: number;
    }

    return (
        <Form  {...props.formProps} layout="vertical">

            <Form.Item label="">
                <Form.Item
                    name={['pictures']}
                    valuePropName="fileList"
                    getValueFromEvent={getValueFromEvent}
                    rules={[{ validator: validatePictures }]}
                    noStyle
                >
                    <Upload.Dragger
                        listType="picture-card"
                        beforeUpload={() => false}
                        accept="image/jpeg,image/png,image/gif"
                        itemRender={(originNode, file, currFileList) => {
                            const currId = (file as myfile)?.id ?? file.uid
                            const currFormKey = currFileList.findIndex((item) => ((item as myfile).id == currId || item.uid == `${currId}`))
                            return (
                                <div>
                                    {originNode}
                                    <Form.Item
                                        label=''
                                        name={["pictures", currFormKey, "display_order"]}
                                        // name={["pictures",(file as myfile)?.id,"display_order"]}
                                        rules={[{ required: true }]}
                                        initialValue={0}
                                    >
                                        <Input placeholder={t("display_order")} />
                                    </Form.Item>
                                </div>
                            )
                        }}
                    >
                        <p className="ant-upload-text">Drag & drop a file in this area</p>
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item>
        </Form>
    )
}

export const LangTextPartnerForm: React.FC<FunctProps> = (props) => {
    const t = useTranslate();
    const { formProps, form, queryResult, formLoading } = props
    const texts: IPartnertext[] = queryResult?.data?.data?.texts;
    const defaultTexts: IPartnertext[] = [
        { "name": "", "description": "", "lang": { "id": '1', 'short_name': 'FR' } },
        { "name": "", "description": "", "lang": { "id": '2', 'short_name': 'EN' } }
    ];

    const partnertexts = (texts) ? texts : defaultTexts;

    return (
        <Form {...formProps} layout="vertical">
            {partnertexts?.map((partnertext, id) => <TextPartnerForm id={id} partnertext={partnertext} key={`TextPartnerForm_${id}`} />)}
        </Form>
    )
}

interface TextPartnerFormProps extends IResourceComponentsProps {
    partnertext: IPartnertext;
    id: any;
}


const TextPartnerForm: React.FC<TextPartnerFormProps> = (props) => {
    const t = useTranslate();
    const { partnertext, id } = props

    return (
        <React.Fragment>
            <Title level={4}>{`${t('rooms.texts.app.translate')} ${partnertext?.lang?.short_name}`}</Title>

            <Form.Item name={['texts', id, 'lang', 'id']} initialValue={partnertext.lang.id} hidden>
                <Input />
            </Form.Item>

            <Form.Item label={t("partner.name")} name={['texts', id, 'name']} rules={[{ max: 50 }]} required>
                <Input />
            </Form.Item>

            <Form.Item label={t("partner.desc")} name={['texts', id, 'description']} rules={[{ max: 2000 }]} required>
                <Input.TextArea rows={5} style={{ height: 120, resize: 'none' }} showCount />
            </Form.Item>

            <Form.Item hidden name={['texts', id, 'id']} />
        </React.Fragment>
    )
}

interface EditableScheduleRowProps extends PartnerSchedulesFormProps {
    id: any;
    day: any;
}


const EditableScheduleRow: React.FC<EditableScheduleRowProps> = ({ id, day, queryResult, form }) => {
    const [opnClse, setOpnClse] = useState<boolean>(queryResult?.data?.data?.schedules?.[id]?.is_closed)
    const isNew = queryResult?.data?.data?.schedules?.[id] ? false : true
    const format = 'HH:mm';

    const switchOpenClose = (v: any) => {
        setOpnClse(v)
    }

    return (
        <Row gutter={24} style={{ marginBottom: 16 }} key={`schedule__form_${id}`}>
            <Col span={6}>
                {isNew &&
                    <Form.Item name={['schedules', id, 'day']} initialValue={day.short} hidden>
                        <Input />
                    </Form.Item>}
                {!isNew &&
                    <Form.Item name={['schedules', id, 'id']} hidden>
                        <Input />
                    </Form.Item>}
            </Col>
            {!opnClse && (
                <>
                    <Col span={6}>
                        <Form.Item name={['schedules', id, 'start']} rules={[{ message: 'Please enter start time' }]}
                            getValueFromEvent={(e: any) => e?.format(format)}
                            getValueProps={(e: string) => ({
                                value: e ? dayjs(`2000-01-01 ${e}:00`) : "",
                            })}
                        >
                            <TimePicker format={format} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={['schedules', id, 'end']} rules={[{ message: 'Please enter end time' }]}
                            getValueFromEvent={(e: any) => e?.format(format)}
                            getValueProps={(e: string) => ({
                                value: e ? dayjs(`2000-01-01 ${e}:00`) : "",
                            })}
                        >
                            <TimePicker format={format} />
                        </Form.Item>
                    </Col>
                </>
            )}
            {opnClse && (
                <>
                    <Col style={{ textAlign: 'center' }} span={12}>
                        <CloseCircleOutlined style={{ fontSize: '3em', color: 'red' }} />
                    </Col></>
            )}

            <Col span={6}>
                <Form.Item name={['schedules', id, 'is_closed']} valuePropName="checked">
                    <Switch onChange={(v) => switchOpenClose(v,)} />
                </Form.Item>
            </Col>
        </Row>
    )

}

interface ScheduleFormProps extends PartnerSchedulesFormProps {
    day: any;
    newSchedule: IPartnerSchedule[];
    setNewSchedule: React.Dispatch<any>;
}

const EditableSchedule: React.FC<ScheduleFormProps> = ({ day, formProps, queryResult, form, newSchedule, setNewSchedule }) => {
    const t = useTranslate();
    const qSchedules = queryResult?.data?.data?.schedules
    const [schedules, setSchedules] = useState<IPartnerSchedule[]>(qSchedules ? queryResult?.data?.data?.schedules : [])
    const idenMaxSchedule = Number(qSchedules ? queryResult?.data?.data?.schedules?.length : 0)

    const addNewDescField = () => {
        setNewSchedule([...newSchedule, { day: day.short, end: null, start: null, is_closed: false }]);
    }
    const removeLast = () => {
        const lastIndex = schedules
            .slice()
            .reverse()
            .findIndex((item: IPartnerSchedule) => item.day === day.short);

        const lastNewIndex = newSchedule
            .slice()
            .reverse()
            .findIndex((item: IPartnerSchedule) => item.day === day.short);

        if (lastNewIndex !== -1) {
            // Remove the item from the array
            const updatedSchedule = newSchedule.slice();
            updatedSchedule.splice(newSchedule.length - 1 - lastNewIndex, 1);
            setNewSchedule(updatedSchedule);
        } else if (lastIndex !== -1) {
            // Remove the item from the array
            const updatedSchedule = schedules.slice();
            updatedSchedule.splice(schedules.length - 1 - lastIndex, 1);
            setSchedules(updatedSchedule);
        }
    }

    return (
        <Form {...formProps} >
            <Row>
                <Col span={6}>
                    <Typography.Title level={4}>{`${day.full}`}</Typography.Title>
                </Col>
                <Col span={6}>
                </Col>
            </Row>
            {schedules.map((schedule: IPartnerSchedule, id: number) => {
                if (schedule.day == day.short)
                    return (<EditableScheduleRow id={id} day={day} queryResult={queryResult} form={form} formProps={formProps} key={`schedule__form_${id}`} />)
                return (null)
            })}
            {newSchedule.map((schedule: IPartnerSchedule, id: number) => {
                if (schedule.day == day.short)
                    return (<EditableScheduleRow id={id + idenMaxSchedule} day={day} queryResult={queryResult} form={form} formProps={formProps} key={`schedule__form_${id + idenMaxSchedule}`} />)
                return (null)
            })}
            <Row style={{ marginBottom: 16 }}>
                <Col span={6}>
                    <Button icon={<PlusOutlined />} size="small" onClick={addNewDescField} className="antd-btn-info" style={{ marginRight: '8px' }}></Button>

                    {(schedules.findIndex((item: IPartnerSchedule) => item.day === day.short) != -1 || newSchedule.findIndex((item: IPartnerSchedule) => item.day === day.short) != -1) &&
                        <Button icon={<DeleteOutlined />} size="small" onClick={removeLast} className="antd-btn-danger" ></Button>
                    }
                </Col>
                <Col span={6}>
                </Col>
            </Row>
        </Form>
    );
};

interface PartnerSchedulesFormProps extends IResourceComponentsProps {
    formProps: FormProps<{}>;
    form: FormInstance<{}>;
    queryResult: any;
}

export const PartnerSchedulesForm: React.FC<PartnerSchedulesFormProps> = ({ formProps, queryResult, form }) => {
    const daysOfWeek = [
        { short: 'mon', full: 'Monday' },
        { short: 'tue', full: 'Tuesday' },
        { short: 'wed', full: 'Wednesday' },
        { short: 'thu', full: 'Thursday' },
        { short: 'fri', full: 'Friday' },
        { short: 'sat', full: 'Saturday' },
        { short: 'sun', full: 'Sunday' },
    ];

    const [newSchedule, setNewSchedule] = useState<IPartnerSchedule[]>([])

    return (
        <React.Fragment>
            <Row gutter={24} style={{ marginBottom: 16 }}>
                <Col span={6}>
                    <Typography.Text strong></Typography.Text>
                </Col>
                <Col span={6}>
                    <Typography.Text strong>Start Time</Typography.Text>
                </Col>
                <Col span={6}>
                    <Typography.Text strong>End Time</Typography.Text>
                </Col>
                <Col span={6}>
                    <Typography.Text strong>Closed</Typography.Text>
                </Col>
            </Row>

            {daysOfWeek.map((day) =>

                <React.Fragment key={`schedule__form_${day.short}`}>
                    <EditableSchedule day={day} formProps={formProps} queryResult={queryResult} form={form} newSchedule={newSchedule} setNewSchedule={setNewSchedule} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};


// Categories partnaires
// 
// 

export const PartnerCategoryForm: React.FC<FunctProps> = ({ form, formLoading, formProps, queryResult }) => {
    const t = useTranslate();
    const col_center_half_xs = { span: 24, offset: 0 }
    const col_center_full_lg = { span: 12, offset: 6 }

    return (
        <Col xs={col_center_half_xs} lg={col_center_full_lg}>
            {!formLoading && <PartnerCategoryLangTextForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />}
            {!formLoading && <PartnerCategoryInfoForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />}
        </Col>
    )
}

const PartnerCategoryInfoForm: React.FC<FunctProps> = (props) => {
    const t = useTranslate();
    const { formProps, queryResult, form } = props

    const validatePictures = async (rule: any, value: any, callback: any) => {
        try {
            console.log(value)
            console.log(value.originFileObj)
            if (value && value.length > 0 && value[0].originFileObj) {
                const path = await file2Base64(value[0]);

                form.setFieldsValue({ path: path });
                // Invoke the callback to indicate successful validation
                // callback();
            } else {
                // Invoke the callback with an error message if the file is missing
                callback('Please upload a picture');
            }
        } catch (error: any) {
            // Invoke the callback with an error message
            callback(error?.message);
        }
    };

    return (
        <Form {...props.formProps} layout="vertical">
            <Form.Item
                label={t("fa_icon")}
                name="fa_icon"
                rules={[{ required: true }]}
            >
                <Input addonAfter={<FontAwesomeIcon icon={queryResult?.data?.data?.fa_icon} />} />
            </Form.Item>

            <Form.Item label="">
                <Form.Item
                    name='path'
                    valuePropName="file"
                    getValueFromEvent={getValueFromEvent}
                    rules={[{ validator: validatePictures }]}
                    noStyle
                >

                    <Upload.Dragger
                        listType="picture-card"
                        beforeUpload={() => false}
                        accept="image/jpeg,image/png,image/gif"
                    >
                        <p className="ant-upload-text">Drag & drop a file in this area</p>
                    </Upload.Dragger>
                </Form.Item>
                <br/>
                {queryResult?.data?.data?.url && 
                <Image
                    width={150}
                    src={`${queryResult?.data?.data?.url}`}
                    placeholder={<Image preview={false} width={200} src={`${queryResult?.data?.data?.url}`}/>}
                />}
                
            </Form.Item>
            <Form.Item
                label={t("display_order")}
                name="display_order"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
        </Form>
    );
};

const PartnerCategoryLangTextForm: React.FC<FunctProps> = (props) => {
    const t = useTranslate();
    const { formProps, form, queryResult, formLoading } = props
    const texts: IPartnerCategorytext[] = queryResult?.data?.data?.texts;
    const defaultTexts: IPartnerCategorytext[] = [
        { "title": "", "lang": { "id": '1', 'short_name': 'FR' } },
        { "title": "", "lang": { "id": '2', 'short_name': 'EN' } }
    ];

    const partnercategorytexts = (texts) ? texts : defaultTexts;

    return (
        <Form {...formProps} layout="vertical">
            {partnercategorytexts?.map((partnercategorytext, id) => <PartnerCategoryTextForm id={id} partnercategorytext={partnercategorytext} key={`TextPartnerForm_${id}`} />)}
        </Form>
    )
}

interface PartnerCategoryTextFormProps extends IResourceComponentsProps {
    partnercategorytext: IPartnerCategorytext;
    id: any;
}


const PartnerCategoryTextForm: React.FC<PartnerCategoryTextFormProps> = (props) => {
    const t = useTranslate();
    const { partnercategorytext, id } = props

    return (
        <React.Fragment>

            <Form.Item name={['texts', id, 'lang', 'id']} initialValue={partnercategorytext.lang.id} hidden>
                <Input />
            </Form.Item>

            <Form.Item label={`${t('rooms.texts.app.translate')} ${partnercategorytext?.lang?.short_name}`} name={['texts', id, 'title']} rules={[{ max: 1050 }]} required>
                <Input />
            </Form.Item>

            <Form.Item hidden name={['texts', id, 'id']} />
        </React.Fragment>
    )
}
