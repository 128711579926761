import { useModalForm, Modal, Form, Input, Typography, Select, FormInstance, useSelect, Button, Space, InputNumber } from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { InputPlusMinus } from "components/inputs/inputplusminus";
import { IBooking, IBookingRoom, IExtra } from "interfaces";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
import { DeleteOutlined } from "@ant-design/icons";
import React from "react";
dayjs.extend(localizedFormat)

const { Title, Text } = Typography;

interface ItemObjProps {
  id: string;
  name: string;
  number: number;
  price: number;

  room_id: number;
  bookingroom_id: number;
  bookingroomform_id: number;
}

export const AddExtrasModal = forwardRef((props, ref) => {
  const t = useTranslate();
  const { modalProps, formProps, form, queryResult, id, show, close, formLoading, } = useModalForm<IBooking>({
    action: "edit",
    redirect: false,
  });

  const bookingrooms: [IBookingRoom] = formProps.form?.getFieldValue("bookingrooms")
  modalProps.title = <Title level={4} >{`${t("buttons.add")} ${t("pages.booking.extras")}`}</Title>

  useImperativeHandle(ref, () => ({
    show(id: string) {
      show(id);
      if (itemData.length) {
        form.resetFields()
        setItemData([])
      }
    }
  }));

  const [itemData, setItemData] = useState<any>([])

  const formSubmit = () => {
    form.submit()
    setItemData([])
  }

  useEffect(() => {
    form.resetFields()
  }, [])

  const addExtraToPush = (extra: IExtra, bookingroom_id: number, room_id: number, bookingroomform_id: number) => {
    const extra_push = { id: extra?.id, bookingroom_id: bookingroom_id, name: extra?.name, number: 1, custom_unit_price: Number(extra.price), price: Number(extra.price), bookingroomform_id: bookingroomform_id, room_id: room_id }
    setItemData([...itemData, extra_push]);

    let tmp = form.getFieldValue(`bookingrooms`)
    tmp[bookingroomform_id].extrabookingrooms[itemData.filter((item: any) => room_id == item?.room_id).length] = { custom_unit_price: Number(extra.price), number: 1, extra_id: extra?.id, bookingroom_id: bookingroom_id }
    form.setFieldsValue([{ 'bookingrooms': tmp }]);
  }

  const removeExtraToPush = (item: ItemObjProps, bookingroom_id: number, room_id: number, bookingroomform_id: number) => {
    const filteredItemData = itemData.filter((_item: ItemObjProps) => (_item.id != item.id || _item.room_id != room_id || _item.bookingroom_id != bookingroom_id));
    setItemData(filteredItemData);

    let tmp = form.getFieldValue('bookingrooms');
    const extrabookingrooms = tmp[bookingroomform_id].extrabookingrooms.filter((extraItem: any) => (extraItem.extra_id != item.id));
    tmp[bookingroomform_id].extrabookingrooms = extrabookingrooms;
    form.setFieldsValue({ 'bookingrooms': tmp });
  }

  return (
    <Form  {...formProps}>
      <Modal {...modalProps} okButtonProps={{ onClick: () => formSubmit() }}>

        <Form.Item
          name="context"
          initialValue="add_product"
          hidden
        ><Input /></Form.Item>

        {bookingrooms?.map((bookingroom: IBookingRoom, id: number) => {

          return (<div key={`AddExtrasModal_input_div_${id}`}>
            <Form.Item
              name={["bookingrooms", id, "id"]}
              initialValue={bookingroom.id}
              hidden
            >
              <Input />
            </Form.Item>

            <AddRoomExtraForm bookingroom={bookingroom} bookingroomform_id={id} itemData={itemData} form={form} addExtraToPush={addExtraToPush} removeExtraToPush={removeExtraToPush} key={`AddRoomExtraForm_${bookingroom.room?.id}`} />
          </div>)
        })}
      </Modal>
    </Form>
  )
});

interface IAddRoomExtraFormProps {
  bookingroom: IBookingRoom;
  itemData: ItemObjProps[];
  bookingroomform_id: number;
  form: FormInstance<{}>;
  addExtraToPush: (extra: IExtra, bookingroom_id: number, room_id: number, bookingroomform_id: number) => void;
  removeExtraToPush: (item: ItemObjProps, bookingroom_id: number, room_id: number, bookingroomform_id: number) => void;
}


export const AddRoomExtraForm = (props: IAddRoomExtraFormProps) => {
  const { bookingroom, bookingroomform_id, itemData, form, addExtraToPush, removeExtraToPush } = props
  const t = useTranslate();

  const { selectProps: extraSelectProps, queryResult: extraQueryResult } = useSelect<IExtra>({
    resource: "products/extras",
    optionValue: "id",
    optionLabel: "full_desc",
    debounce: 50,
    fetchSize: 100,
    filters: [
      { field: "rooms", operator: "eq", value: bookingroom?.room.id },
      { field: "status_internal", operator: "eq", value: 1 }
    ],
    onSearch: (value) => [
      { field: "search", operator: "eq", value, }
    ],
  });

  const checkExtra = (id: number) => {
    const extra = extraQueryResult?.data?.data.find((e: IExtra) => id == Number(e?.id))
    if (extra) {
      addExtraToPush(extra, Number(bookingroom?.id), Number(bookingroom?.room_id), bookingroomform_id)
    }
  }


  return (
    <div>
      <Title level={4}>{`${bookingroom?.room?.name}`}</Title>

      <div className="ant-row ant-form-item">
        <div className="ant-col ant-form-item-label">
          <Text>{t("Ajouter extra:")}</Text>
        </div>
        <div className="ant-col ant-form-item-control">
          <Select allowClear onChange={(value: any, option: any) => checkExtra(option?.value)} {...extraSelectProps} />
        </div>
      </div>

      {itemData.filter((item: ItemObjProps) => Number(bookingroom?.room?.id) == item?.room_id).map((item: ItemObjProps, id: number) => {
        return (
          <ExtraRow item={item} bookingroomform_id={bookingroomform_id} extrabookingroomform_id={id} bookingroom={bookingroom} room_id={bookingroom?.room?.id} key={`extra_extrabookings_${bookingroomform_id}_${id}`} removeExtraToPush={removeExtraToPush} />)
      })}


    </div>
  )
}

interface ExtraRowProps extends IResourceComponentsProps {
  item: ItemObjProps;
  room_id: any;
  bookingroomform_id: any;
  extrabookingroomform_id: number;
  bookingroom: IBookingRoom;
  removeExtraToPush: (item: ItemObjProps, bookingroom_id: number, room_id: number, bookingroomform_id: number) => void;
}


const ExtraRow: React.FC<ExtraRowProps> = ({ item, bookingroom, room_id, bookingroomform_id, extrabookingroomform_id, removeExtraToPush }) => {

  return (<>
    <div style={{ fontWeight: 700, paddingBottom: '1rem', paddingTop: '1rem' }}>{item?.name}</div>
    <Space wrap>

      <Form.Item name={["bookingrooms", bookingroomform_id, "extrabookingrooms", extrabookingroomform_id, "extra_id"]} hidden >
        <Input />
      </Form.Item>
      <ExtraInputs item={item} bookingroomform_id={bookingroomform_id} extrabookingroomform_id={extrabookingroomform_id} bookingroom={bookingroom} room_id={bookingroom?.room?.id} removeExtraToPush={removeExtraToPush} />

      {/* {optionIsShow && <>
        <Space wrap>
          <div style={{ color: "transparent" }}>.</div>
          <InputPlusMinus
            min={0} max={100} key={`add_extra_${item?.id}_input_unit_price_percent_reduction`}
            label={<>{t("%reduction")}</>} initialValue={0} onChange={(e: number) => extraInputsRef?.current.setReduction(e)}
          />
        </Space>
      </>} */}
      {/* <div>
        <div style={{ color: "transparent" }}>.</div>
        <Button icon={<MoreOutlined />} onClick={() => setOptionIsShow(!optionIsShow)}>
        </Button>
      </div> */}
      <Form.Item>
        <Button icon={<DeleteOutlined />} style={{ backgroundColor: "red", color: "white", marginTop: '1.4rem' }} onClick={() => removeExtraToPush(item, Number(bookingroom?.id), room_id, bookingroomform_id)} />
      </Form.Item>

      <br></br>
    </Space>
  </>)

}

const ExtraInputs: React.FC<ExtraRowProps> = ({ item, bookingroom, room_id, bookingroomform_id, extrabookingroomform_id, removeExtraToPush }) => {
  const t = useTranslate();
  // const isDayUse = bookDates.days.length == 1 ? 0 : bookDates.days.length - 1;

  const getDaysArray = (start: any, end: any) => {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  }

  const bookDates = { start: bookingroom.start, end: bookingroom.end, days: getDaysArray(bookingroom.start, bookingroom.end) }
  const days = (bookDates.days.slice(0, bookDates.days.length)).map((d: any) => ({ day: d, qtt: 0 }))

  return (

    <Space wrap>

      {days.map(({ day, qtt }, index: number) => {
        return (
          <Form.Item
            name={["bookingrooms", bookingroomform_id, "extrabookingrooms", extrabookingroomform_id, "infos", "date_qtt", dayjs(day).format('YYYY-MM-DD')]}
            initialValue={0}
          >
            <InputPlusMinus
              buttons integerOnly={true} min={0}
              label={dayjs(day).locale(t("short_lang")).format('DD/MM')}
            />
          </Form.Item>
        )
      })}
      <div>
        <Form.Item name={["bookingrooms", bookingroomform_id, "extrabookingrooms", extrabookingroomform_id, "custom_unit_price"]}>
          <InputNumber controls={false} min={0} addonAfter={'€'} style={{ maxWidth: "7rem", marginTop: '1.4rem' }} />
        </Form.Item>

        <Form.Item
              name={["bookingrooms", bookingroomform_id, "extrabookingrooms", extrabookingroomform_id, "booking_start"]}
              initialValue={bookingroom.start}
              hidden
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={["bookingrooms", bookingroomform_id, "extrabookingrooms", extrabookingroomform_id, "booking_end"]}
              initialValue={bookingroom.end}
              hidden
            >
              <Input />
            </Form.Item>

      </div>
    </Space>

  )
}

