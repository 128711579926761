import { useEffect, useState } from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  Select,
  useForm,
  useSelect,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IAgenda, IPriceZone } from "interfaces";

export const AgendaEdit: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();

  const { form, formProps, saveButtonProps, queryResult: agendaQueryResult, id: agendaId } = useForm<IAgenda>();
  // const agenda = agendaQueryResult?.data?.data

  const { selectProps: pricezoneSelectProps } = useSelect<IPriceZone>({
    resource: "pricezones",
    optionLabel: "name",
  });


  const pricezones = (agendaQueryResult?.data?.data.pricezones)? agendaQueryResult?.data?.data.pricezones.map(obj => obj.id): [];

  useEffect(() => {  
    form.setFields([
      {name: 'pricezone_ids', value: pricezones},
    ])
  });

  const resource = agendaQueryResult?.data?.data as IAgenda

  return (
    <Edit title={`${resource?.name || ''}`} saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("agendas.name")}
          name="name"
          rules={[
            {
              required: true,
              max: 70,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("menu.pricezones")}
          name={"pricezone_ids"}
        >
          <Select
            mode="multiple"
            allowClear
            options={pricezoneSelectProps.options}
          >
          </Select>
        </Form.Item>
      </Form>
    </Edit>
  );
};
