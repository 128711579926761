import {
  useTranslate,
  IResourceComponentsProps,
  useShow,
} from "@pankod/refine-core";
import { Show, Typography, Tag } from "@pankod/refine-antd";

import { IRoom } from "interfaces";
import { RoomSensorsGrid } from "./components/roomsensorsgrid";
const { Title, Text } = Typography;


export const RoomShow: React.FC<IResourceComponentsProps> = () => {
  
  const t = useTranslate();

  const { queryResult } = useShow<IRoom>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t("posts.fields.title")}</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>{t("posts.fields.status.title")}</Title>
      <Text>
        <Tag>{record?.pricezone?.name}</Tag>
      </Text>

      {!isLoading && <RoomSensorsGrid room_id={record?.id}/>}

    </Show>
  );
};

