import { useModalForm, Modal, Form, Input, Typography, Space, InputNumber, DeleteButton } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { InputPlusMinus } from "components/inputs/inputplusminus";
import { IExtraBookingRoom } from "interfaces";
import { forwardRef, useEffect, useImperativeHandle } from "react";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
import React from "react";
dayjs.extend(localizedFormat)

const { Title, Text } = Typography;

interface EditExtrasModalProps {
  refetch: () => void;
}

export const EditExtrasModal = forwardRef<any, EditExtrasModalProps>(({refetch}, ref) => {
  const t = useTranslate();
  const { modalProps, formProps, form, queryResult, id, show, close, formLoading } = useModalForm<IExtraBookingRoom>({
    action: "edit",
    redirect: false,
    resource: "products/extrasbookingroom",
  });


  modalProps.title = <Title level={4} >{`${t("buttons.edit")} ${t("pages.booking.extras")}`}</Title>

  useImperativeHandle(ref, () => ({
    show(id: string) {
      show(id);
    },
  }));

  useEffect(() => {
    form.resetFields()
  }, [])

  const getDaysArray = (start: any, end: any) => {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  }

  const bookDates = { start: queryResult?.data?.data?.booking_start, end: queryResult?.data?.data?.booking_end, days: getDaysArray(queryResult?.data?.data?.booking_start, queryResult?.data?.data?.booking_end) }
  const days = (bookDates.days.slice(0, bookDates.days.length)).map((d: any) => ({ day: d, qtt: 0 }))

  return (
    <Form  {...formProps} >
      <Modal {...modalProps} afterClose={() => refetch()}>
        <React.Fragment>
          <div style={{ fontWeight: 700, paddingBottom: '1rem', paddingTop: '1rem' }}>{queryResult?.data?.data?.name}</div>
          <Space wrap>

            <Form.Item name={["extra_id"]} hidden >
              <Input />
            </Form.Item>

            {days.map(({ day, qtt }, index: number) => {
              return (
                <Form.Item
                  name={["infos", "date_qtt", dayjs(day).format('YYYY-MM-DD')]}
                  initialValue={0}
                >
                  <InputPlusMinus
                    buttons integerOnly={true} min={0}
                    label={dayjs(day).locale(t("short_lang")).format('DD/MM')}
                  />
                </Form.Item>
              )
            })}
            <div>
              <Form.Item name={["custom_unit_price"]}>
                <InputNumber controls={false} min={0} addonAfter={'€'} style={{ maxWidth: "7rem", marginTop: '1.4rem' }} />
              </Form.Item>
            </div>

            <Form.Item>
              <DeleteButton size="small" hideText style={{marginTop: '1.4rem'}} recordItemId={queryResult?.data?.data?.id} resourceNameOrRouteName="products/extrasbookingroom" onSuccess={() => close()} />
            </Form.Item>
            <br></br>
          </Space>
        </React.Fragment>
      </Modal>
    </Form>
  )
});
