import { useState } from 'react';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { authProvider } from "authProvider"
import { HttpError } from '@pankod/refine-core';
import { notification } from 'antd';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    config => {
        config.headers = {
            'Authorization': `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        }
        config.timeout = 300000 // 5 min like nginx
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {

        const originalRequest = error.config;
        if (error.response.status == 401) {
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                await authProvider.refresh({});
                return axiosInstance(originalRequest);
            } else {
                await authProvider.logout({})
            }
        }
        console.log('error.response?.status')
        console.log(error)
        const customError: HttpError = {
            ...error,
            message: error.response?.error,
            statusCode: error.response?.status,
        };
        return Promise.reject(customError);
    },
);
export const useAuthenticatedRequest = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const sendRequest = async ({
        url,
        method = 'get',
        data = {},
        params = {},
    }: {
        url: string;
        method?: Method;
        data?: object;
        params?: object;
    }) => {
        setIsLoading(true);
        setError(null);
        try {
            const config: AxiosRequestConfig = {
                url: buildFullUrl(url),
                method,
                data,
                params,
            };
            const response = await axiosInstance(config);
            setIsLoading(false);

            notification.success({
                message: 'Success',
                placement: 'topRight',
            });


            return response.data;
        } catch (err) {
            console.log('usseAuth')
            setIsLoading(false);
            let errorMessage = 'An error occurred';
            // Check if error is an AxiosError and has a response with a data message
            if (axios.isAxiosError(err) && err.response) {
                errorMessage = err.response.data.message || errorMessage;
            }
            setError(errorMessage);

            notification.error({
                message: 'Error',
                description: errorMessage,
                placement: 'topRight',
            });


            throw err;


        }
    };

    return { sendRequest, isLoading, error };
};

function buildFullUrl(url: string): string {
    const domain = process.env?.REACT_APP_API_URL || ''
    const normalizedDomain: string = domain.endsWith('/') ? domain.slice(0, -1) : domain;
  
    if (/^[a-zA-Z0-9][a-zA-Z0-9-]*\.[a-zA-Z]{2,}/.test(url.split('/')[0])) {
      return url;
    } else if (url.startsWith('/')) {
      return `${normalizedDomain}${url}`;
    } else {
      return `${normalizedDomain}/${url}`;
    }
  }
  