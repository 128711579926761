import React, { useState } from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import { Col, Form, Input, Select, Switch, Typography, useSelect, FormInstance, FormProps } from "@pankod/refine-antd";
import { IEquipmenttext, IEquipment, IRoom } from "interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Title, Text } = Typography;

interface FunctProps extends IResourceComponentsProps {
    formProps: FormProps<{}>;
    form: FormInstance<{}>;
    queryResult: any;
    formLoading: boolean;
}

export const EquipmentForm: React.FC<FunctProps> = ({ form, formLoading, formProps, queryResult }) => {
    const t = useTranslate();
    const col_center_half_xs = { span: 24, offset: 0 }
    const col_center_full_lg = { span: 12, offset: 6 }

    return (
        <Col xs={col_center_half_xs} lg={col_center_full_lg}>
            {!formLoading && <EquipmentLangTextForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />}
            {!formLoading && <EquipmentInfoForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />}
        </Col>
    )
}

const EquipmentInfoForm: React.FC<FunctProps> = (props) => {
    const t = useTranslate();
    const { formProps, queryResult, form } = props

    const { selectProps: roomSelectProps, queryResult: roomQueryResult } = useSelect<IRoom>({
        resource: 'rooms',
        optionValue: "id",
        optionLabel: "name",
        debounce: 100,
        fetchSize: 100,
    });

    if (roomSelectProps?.loading == true || roomQueryResult?.data?.data === undefined) {
        return (<></>)
    }

    const iiselected = queryResult?.data?.data?.rooms?.map((room: IRoom) => ({ value: room.id, label: undefined })) as unknown as { value: string; label: string; }

    return (
        <Form {...props.formProps} layout="vertical">
            <Form.Item
                label={t("label")}
                name="label"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label={t("fa_icon")}
                name="fa_icon"
                rules={[{ required: true }]}
            >
                <Input size="large" addonAfter={<FontAwesomeIcon size="2x" icon={queryResult?.data?.data?.fa_icon} />} />
            </Form.Item>

            <Form.Item
                label={t("icon")}
                name="icon"
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("is_in_room_preview")}
                name="is_in_room_preview"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label={t("is_in_filter")}
                name="is_in_filter"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label={t("room")}
                name={["rooms"]}
                getValueFromEvent={(values,) => {
                    return values?.map((id: number) => ({ id }));
                }}
                getValueProps={(values: any) => {
                    return values
                }}
            >
                <Select
                    {...roomSelectProps}
                    mode="multiple"
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    autoClearSearchValue
                    defaultValue={iiselected}
                />
            </Form.Item>

        </Form>
    );
};

const EquipmentLangTextForm: React.FC<FunctProps> = (props) => {
    const t = useTranslate();
    const { formProps, form, queryResult, formLoading } = props
    const texts: IEquipmenttext[] = queryResult?.data?.data?.texts;
    const defaultTexts: IEquipmenttext[] = [
        { "name": "", "lang": { "id": '1', 'short_name': 'FR' } },
        { "name": "", "lang": { "id": '2', 'short_name': 'EN' } }
    ];

    const equipmenttexts = (texts) ? texts : defaultTexts;
    console.log(equipmenttexts)

    return (
        <Form {...formProps} layout="vertical">
            {equipmenttexts?.map((equipmenttext, id) => <EquipmentTextForm id={id} equipmenttext={equipmenttext} key={`TextPartnerForm_${id}`} />)}
        </Form>
    )
}

interface EquipmentTextFormProps extends IResourceComponentsProps {
    equipmenttext: IEquipmenttext;
    id: any;
}


const EquipmentTextForm: React.FC<EquipmentTextFormProps> = (props) => {
    const t = useTranslate();
    const { equipmenttext, id } = props

    return (
        <React.Fragment>

            <Form.Item name={['texts', id, 'lang', 'id']} initialValue={equipmenttext.lang.id} hidden>
                <Input />
            </Form.Item>

            <Form.Item label={`${t('rooms.texts.app.translate')} ${equipmenttext?.lang?.short_name}`} name={['texts', id, 'name']} rules={[{ max: 45 }]} required>
                <Input />
            </Form.Item>

            <Form.Item hidden name={['texts', id, 'id']} />
        </React.Fragment>
    )
}
