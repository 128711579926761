import {
    useTranslate,
    IResourceComponentsProps,
    useOne,
    useShow,
    useTable,
    HttpError,
    useCustom,
    useApiUrl,
    useResource,
} from "@pankod/refine-core";
import { Show, Typography, Tag, MarkdownField, Card } from "@pankod/refine-antd";
import { BulbFilled, BulbOutlined, DashboardOutlined, FireOutlined, LockFilled, UnlockFilled, UserDeleteOutlined, UserOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { faTemperatureHigh, faDoorClosed, faDoorOpen } from '@fortawesome/free-solid-svg-icons'
// import { faframe } from '@fortawesome/free-regular-svg-icons'

interface RoomSensor {
    id: string;
    name: string;
    eqmnt_id: string;
    eqmnt_name: string;
    sensor_type: string;
    genericType: string;
    current: string;
  }
  
  
export const RoomSensorsGrid = (props: {room_id: string|undefined}) => {
    const apiUrl = useApiUrl();
    // const { resources } = useResource();
    // console.log(resources)
  
    const { data: sensorsData, isLoading: sensorsIsLoading } = useCustom<[RoomSensor]>({
      url: `${apiUrl}/rooms/${props.room_id}/get_sensorsstates`,
      method: "get",
    });

    if (props.room_id == undefined) return (null)
  
    const gridStyle: React.CSSProperties = {
      width: '33%',
      textAlign: 'center',
    };
    console.log(sensorsData)
    return (
      <Card>
        {sensorsData?.data.map((sensor, index) => {
          console.log(sensor.sensor_type)
          if (sensor.sensor_type == "LIGHT_STATE") {
            return (
  
              <Card.Grid style={gridStyle} key={`Card.Grid.domo.${index}`}>
                {sensor.current == '0'? <BulbOutlined style={{fontSize: '24px', color: 'black'}}/>: <BulbOutlined style={{fontSize: '24px', color: '#e6e600'}}/>}
                <br/>
                {sensor.eqmnt_name}
                {/* :{sensor.current} */}
  
              </Card.Grid>
              // <Card.Grid hoverable={false} style={gridStyle}>
              //   Content
              // </Card.Grid>
              // <Card.Grid style={gridStyle}>Content</Card.Grid>
              // <Card.Grid style={gridStyle}>Content</Card.Grid>
              // <Card.Grid style={gridStyle}>Content</Card.Grid>
              // <Card.Grid style={gridStyle}>Content</Card.Grid>
              // <Card.Grid style={gridStyle}>Content</Card.Grid>
          
            )
          }
          else if (sensor.sensor_type == "OPENING" && sensor.eqmnt_name.indexOf("door") != -1) {
            return (
  
              <Card.Grid style={gridStyle} hoverable={false} key={`Card.Grid.domo.${index}`}>
                {sensor.current == '0'? <FontAwesomeIcon icon={faDoorClosed} style={{fontSize: '24px', color: 'black'}}/>: <FontAwesomeIcon icon={faDoorOpen} style={{fontSize: '24px', color: 'red'}}/>}
                <br/>
                door
                {sensor.eqmnt_name}
                {/* :{sensor.current} */}
              </Card.Grid>
            )
          }
          else if (sensor.sensor_type == "OPENING") {
            return (
  
              <Card.Grid style={gridStyle} hoverable={false} key={`Card.Grid.domo.${index}`}>
                {sensor.current == '0'? <LockFilled style={{fontSize: '24px', color: 'black'}}/>: <UnlockFilled style={{fontSize: '24px', color: 'red'}}/>}
                <br/>
                {sensor.eqmnt_name}
                {/* :{sensor.current} */}
              </Card.Grid>
            )
          }
          else if (sensor.sensor_type == "PRESENCE") {
            return (
  
              <Card.Grid style={gridStyle} hoverable={false} key={`Card.Grid.domo.${index}`}>
                {sensor.current == '0'? <UserDeleteOutlined style={{fontSize: '24px', color: 'red'}}/>: <UserOutlined style={{fontSize: '24px', color: 'green'}}/>}
                <br/>
                {sensor.eqmnt_name}
                {/* :{sensor.current} */}
              </Card.Grid>
            )
          }
          else if (sensor.sensor_type == "SMOKE") {
            return (
              <Card.Grid style={gridStyle} hoverable={false} key={`Card.Grid.domo.${index}`}>
                {sensor.current == '0'? <FireOutlined style={{fontSize: '24px', color: 'black'}}/>: <FireOutlined style={{fontSize: '24px', color: 'red'}}/>}
                <br/>
                {sensor.eqmnt_name}
                {/* :{sensor.current} */}
              </Card.Grid>
            )
          }
          else if (sensor.sensor_type == "THERMOSTAT_SETPOINT") {
            return (
  
              <Card.Grid style={gridStyle} key={`Card.Grid.domo.${index}`}>
                <DashboardOutlined style={{fontSize: '24px', color: 'black'}}/>
                <br/>
                {sensor.eqmnt_name}: {sensor.current}°C
              </Card.Grid>
            )
          }
          else if (sensor.sensor_type == "THERMOSTAT_TEMPERATURE") {
            return (
  
              <Card.Grid style={gridStyle} hoverable={false} key={`Card.Grid.domo.${index}`}>
                <FontAwesomeIcon icon={faTemperatureHigh} style={{fontSize: '24px', color: 'black'}}/>
                <br/>
                {sensor.eqmnt_name}: {sensor.current}°C
              </Card.Grid>
            )
          }
          return 
        })}
        </Card>
      )
  }
  