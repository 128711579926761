import React, { useEffect, useState } from 'react';
import {
    useTranslate,
    IResourceComponentsProps,
} from "@pankod/refine-core";
import {
    List,
    Card,
    Typography,
	Space,
	Button,
	Col,
	Row
} from "@pankod/refine-antd";


export const TedeeEdit: React.FC<IResourceComponentsProps> = () => {

    return (
		<h1>Test</h1>
    );
}
