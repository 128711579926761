import {
  BaseKey,
  useNavigation as useNavigationRouterDom,
  useResource
} from "@pankod/refine-core";
import { useNavigate as useNavigateRouterDom } from 'react-router-dom';

export const useNavigation = () => {
  const { show, create, edit, list } = useNavigationRouterDom();
  const { resources } = useResource();

  const convertResource = (resource: string) => {
    return resources.find((e) => e.name == resource)?.route
  }

  const customShow = (resource: string, id: BaseKey, event?: any) => {
    if (event?.ctrlKey) {
      window.open(`/${convertResource(resource)}/show/${id}`, '_blank');
    } else {
      show(resource, id);
    }
  };

  const customCreate = (resource: string, event?: any) => {
    if (event?.ctrlKey) {
      window.open(`/${convertResource(resource)}/create`, '_blank');
    } else {
      create(resource);
    }
  };

  const customEdit = (resource: string, id: BaseKey, event?: any) => {
    if (event?.ctrlKey) {
      window.open(`/${convertResource(resource)}/edit/${id}`, '_blank');
    } else {
      edit(resource, id);
    }
  };

  const customList = (resource: string, event?: any) => {
    if (event?.ctrlKey) {
      window.open(`/${convertResource(resource)}/list`, '_blank');
    } else {
      list(resource);
    }
  };

  return { show: customShow, create: customCreate, edit: customEdit, list: customList };
};

export const useNavigate = () => {
  const navigate = useNavigateRouterDom();

  const customNavigate = (to: string, event?: any) => {
    console.log(event)
    console.log(event?.ctrlKey)
    if (event?.ctrlKey) {
      window.open(to, '_blank');
    } else {
      navigate(to);
    }
  };

  return customNavigate;
};
