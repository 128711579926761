  import {
    useTranslate,
    IResourceComponentsProps,
    HttpError,
    useNavigation
  } from "@pankod/refine-core";
  import {
    List,
    Table,
    TextField,
    useTable,
    getDefaultSortOrder,
    Form,
    Input,
    Icons,
    Switch,
  } from "@pankod/refine-antd";
  import { IRoom, IBookingRoom, IRoomTimelineGroup, IBookingRoomTimelineItem } from "interfaces";
import { useMemo, useState } from "react";
import { BookingRoomTimeline } from "./timeline";

  export const BookingRoomList: React.FC<IResourceComponentsProps> = () => {
    const { show, create } = useNavigation()
    const t = useTranslate();  
    const [ timelineView, setTimelineView ] = useState(true)

    const switchview = () => {
      // if (timelineView) {
        setTimelineView(!timelineView)
      // }
    }

    const isMobile = useMemo(() => {
      if (window.innerWidth <= 768)
        setTimelineView(false)
    }, [])
    //   const timelineView = useMemo(
    //   () => filterTodos(todos, tab),
    //   [todos, tab]
    // );
      if (timelineView) {
        return (
          <BookingRoomTimeline switchview={switchview}></BookingRoomTimeline>
        )
      }
    
      return (
        // <BookingRoomLList></BookingRoomLList>
        <BookingRoomLList switchview={switchview}></BookingRoomLList>
      )
    }  

  interface FunctProps extends IResourceComponentsProps {
    switchview(): void;
  }

  const BookingRoomLList: React.FC<FunctProps> = (props) => {
    const { show, create } = useNavigation()
    const t = useTranslate();

    const { tableProps: roomTableProps, sorter: roomSorter, searchFormProps: roomSearchFormProps, tableQueryResult: roomTableQueryResult } = useTable<IRoomTimelineGroup, HttpError>({
      resource: "rooms",
      initialCurrent: 1,
      initialPageSize: 1000,
      initialSorter: [{ field: "number", order: "asc" }],
    });


    const { sorter, tableQueryResult, tableProps } = useTable<IBookingRoomTimelineItem, HttpError>({
      resource: "bookingrooms/get_timeline",
      // initialCurrent: 1,
      // initialPageSize: 1000,
      metaData: {
        status: ['valid', 'block'],
      }
    });
    // console.log(tableQueryResult)
  
      return (
        <List
          title={`${t("menu.bookings")}`}
          pageHeaderProps={{
            extra: [
              // <Form
              // layout="horizontal"
              // onValuesChange={() => roomSearchFormProps.form?.submit()}
              // key="page_header_extra_3"
              // {...roomSearchFormProps}
              // >
              //   <Form.Item label="" name="name" style={{ marginBottom: 0 }}>
              //     <Input
              //       placeholder="TLID, Title, Content, etc."
              //       prefix={<Icons.SearchOutlined />}
              //     />
              //   </Form.Item>
              // </Form>,
              // <TextField key="page_header_extra_1" value={`${t("pages.booking.list_view")} : `}/>,
              <Switch key="page_header_extra_2" defaultChecked onChange={props.switchview} />,
            ],
          }}
        >
        <Table
          {...tableProps}
          rowKey="id"
        >
          <Table.Column
            dataIndex="booking"
            key="booking"
            title={t("pages.booking.client")}
            render={(value) => <TextField onClick={() => show("bookings", value.id)} value={value.client.fancy_name} />}
            // defaultSortOrder={getDefaultSortOrder("fancy_name", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="room_id"
            key="room_id"
            title={t("pages.booking.room")}
            render={(value) => <TextField value={`${roomTableQueryResult?.data?.data?.find(x => x.id == value)?.name}`} />}
            // defaultSortOrder={getDefaultSortOrder("fancy_name", sorter)}
            sorter
          />
  
          <Table.Column
            dataIndex="start"
            key="start"
            title={t("pages.booking.start")}
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("start", sorter)}
            sorter
          />
          <Table.Column
            dataIndex="end"
            key="end"
            title={t("pages.booking.end")}
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("end", sorter)}
            sorter
          />
  
        </Table>
      </List>
      )
    }  
  // };

  interface IBookingRoomFilterVariables {
    name: string;
    // createdAt: [Dayjs, Dayjs];
  }
