import axios, { AxiosInstance } from "axios";
import queryString, { stringify } from "query-string";
// import queryString from "query-string";
import {
    DataProvider,
    HttpError,
    CrudOperators,
    CrudFilters,
    CrudSorting,
} from "@pankod/refine-core";
import { authProvider } from "authProvider"
import { config } from "process";

const axiosInstance = axios.create();
// const axiosInstance = axios.create({ headers: { 'Content-Type': 'application/json' } });
// const axiosInstance = axios.create({ auth: { username: oauthParma.clientId,password: oauthParma.clientSecret } });

axiosInstance.interceptors.request.use(
    config => {
      config.headers = { 
        'Authorization': `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        'Accept': 'application/json; charset=utf-8',
        'Content-Type': 'application/json; charset=utf-8'
      }
      config.timeout = 300000 // 5 min like nginx
      return config;
    },
    error => {
      Promise.reject(error)
});
  
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {

        const originalRequest = error.config;
        if (error.response.status == 401) {
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                await authProvider.refresh({});            
                return axiosInstance(originalRequest);    
            } else {
                await authProvider.logout({})
            }
        }
        console.log('error.response?.status')
        console.log(error)
        const customError: HttpError = {
            ...error,
            message: error.response?.error,
            statusCode: error.response?.status,
        };
        return Promise.reject(customError);
    },
);

const mapOperator = (operator: CrudOperators): string => {
    switch (operator) {
        case "ne":
        case "in":
        case "gte":
        case "lte":
            return `__${operator}`;
        case "contains":
            return "__icontains";
        case "contains":
            return "__icontains";
        case "eq":
        default:
            return "";
    }
};

const generateSort = (sort?: CrudSorting) => {
    if (sort && sort.length > 0) {
        let ordering: string = "";

        sort.map((item) => {
            ordering = `${ordering}${(item.order==="desc")?"-":""}${item.field},`;
        });
        ordering = ordering.slice(0, -1)

        return ordering;
    }
    return "";
};

const generateFilter = (filters?: CrudFilters) => {
    const queryFilters: { [key: string]: string } = {};
    let strQueryFilters = ""
    if (filters) {
        filters.map((filter) => {
            if (filter.operator !== "or") {
                const { field, operator, value } = filter;

                if (field === "q") {
                    queryFilters[field] = value;
                    return;
                }

                const mappedOperator = mapOperator(operator);

                strQueryFilters +=`${field}${mappedOperator}=${value}&`
            }

        });
    }

    return strQueryFilters.slice(0, -1)
};

const JsonServer = (
    apiUrl: string,
    httpClient: AxiosInstance = axiosInstance,
): DataProvider => ({
    getList: async ({ resource, pagination, filters, sort, metaData }) => {
        const url = `${apiUrl}/${resource}`;

        const current = pagination?.current || 1;
        const pageSize = pagination?.pageSize || 10;

        const queryFilters = generateFilter(filters);

        const optionQuery = `${metaData ?  `&${stringify(metaData)}` : ""}${queryFilters === "" ? '' : `&${queryFilters}`}`

        const query: {
            offset: number;
            limit: number;
            ordering?: string;
        } = {
            offset: (current - 1) * pageSize,
            limit: pageSize,
            ordering: generateSort(sort),
        };

        let { data, headers } = await httpClient.get(
            `${url}?${stringify(query)}${optionQuery}`,
        );

        const total = +data['count'];
        data = data['results'];

        return {
            data,
            total,
        };
    },

    getMany: async ({ resource, ids }) => {
        const { data } = await httpClient.get(
            `${apiUrl}/${resource}/?${stringify({ id: ids })}`,
        );

        return {
            data,
        };
    },

    create: async ({ resource, variables }) => {
        const url = `${apiUrl}/${resource}/`;
        const { data } = await httpClient.post(url, variables);
        return {
            data,
        };
    },

    createMany: async ({ resource, variables }) => {
        const response = await Promise.all(
            variables.map(async (param) => {
                const { data } = await httpClient.post(
                    `${apiUrl}/${resource}/`,
                    param,
                );
                return data;
            }),
        );

        return { data: response };
    },

    update: async ({ resource, id, variables, metaData }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const optionQuery = `${metaData ?  `?${stringify(metaData)}` : ""}`

        const { data } = await httpClient.patch(`${url}${optionQuery}`, variables);

        return {
            data,
        };
    },

    updateMany: async ({ resource, ids, variables }) => {
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.patch(
                    `${apiUrl}/${resource}/${id}`,
                    variables,
                );
                return data;
            }),
        );

        return { data: response };
    },

    getOne: async ({ resource, id, metaData }) => {
        const queryStr = metaData ?  `?${stringify(metaData)}` : ''

        const url = `${apiUrl}/${resource}/${id}${queryStr}`;

        const { data } = await httpClient.get(url);

        return {
            data,
        };
    },

    deleteOne: async ({ resource, id }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const { data } = await httpClient.delete(url);

        return {
            data,
        };
    },

    deleteMany: async ({ resource, ids }) => {
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.delete(
                    `${apiUrl}/${resource}/${id}`,
                );
                return data;
            }),
        );
        return { data: response };
    },

    getApiUrl: () => {
        return apiUrl;
    },

    custom: async ({ url, method, filters, sort, payload, query, headers }) => {
        let requestUrl = `${url}?`;

        if (sort) {
            const generatedSort = generateSort(sort);
            if (generatedSort) {
                const sortQuery = {
                    ordering: generatedSort,
                };
                requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
            }
        }

        if (filters) {
            const filterQuery = generateFilter(filters);
            requestUrl = `${requestUrl}&${filterQuery}`;
        }

        if (query) {
            requestUrl = `${requestUrl}&${stringify(query)}`;
        }

        if (headers) {
            httpClient.defaults.headers = {
                ...httpClient.defaults.headers,
                ...headers,
            };
        }

        let axiosResponse;
        switch (method) {
            case "put":
            case "post":
            case "patch":
                axiosResponse = await httpClient[method](url, payload);
                break;
            case "delete":
                axiosResponse = await httpClient.delete(url);
                break;
            default:
                if (headers && "content_dl" in headers)
                    axiosResponse = await httpClient.get(requestUrl, { responseType: 'blob' });
                else
                    axiosResponse = await httpClient.get(requestUrl);
                break;
        }

        let { data } = axiosResponse;

        return Promise.resolve({ data });
    },
});

export default JsonServer;
