import {
  useTranslate,
  IResourceComponentsProps,
  useOne,
  useShow,
  useList,
  useTable,
  useCustom,
} from "@pankod/refine-core";
import { Show, Typography, Tag, MarkdownField, Card, List as RList, Row, Col } from "@pankod/refine-antd";
import { Avatar, List, Menu, Dropdown, Table as ATable } from 'antd';

import { IExtra, IBooking, IBookingRoom, IStripePaymentMethods, IStripeCard } from "interfaces";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { ReactChild, ReactFragment, ReactPortal } from "react";
dayjs.extend(localizedFormat)

const { Title, Text } = Typography;

export const ExtraShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<IExtra>({
    resource: "products/extras"
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  // const { data: categoryData } = useOne<ICategory>({
  //   resource: "categories",
  //   id: record?.category.id ?? "",
  //   queryOptions: {
  //     enabled: !!record?.category.id,
  //   },
  // });

  // const {tableQueryResult: bookingListQueryResult} = useTable<IBookingRoom>({
  //   resource: "bookingrooms",
  //   metaData: {
  //     booking__client_id: record?.id
  //   }
  // });
  // const bookingrooms = bookingListQueryResult?.data?.data
  // // console.log(record?true:false)
  // const {data: cardsListQueryResult} = useCustom<IStripePaymentMethods>({
  //   url: `${process.env?.REACT_APP_API_URL || ''}/clients/${record?.id}/getcards`,
  //   method: 'get',
  //   queryOptions: {
  //     enabled: record?true:false,
  //   },
  // });
  // const cards = cardsListQueryResult?.data?.data

  // const { isLoading: isBLoading } = bookingListQueryResult
  // console.log(isBLoading)
  // console.log(bookingListQueryResult)
  // console.log(cardsListQueryResult)
  // console.log(cards)
  // console.log(bookingListQueryResult)

  

  return (
    <Show isLoading={isLoading}>

      <Row>
        <Col xs={24} sm={24} md={24} lg={12} xl={10}>

          <Card key={"card_01_client"} title={record?.name} style={{ backgroundColor:"#EFEFEF", width: "100%", maxWidth:"500px", padding: '10px', margin: "0px auto 10px auto"}}>

            <List.Item>
              <Title level={5}>{`${t("products.price")}:`}</Title>
              <Text>{record?.price}</Text>
            </List.Item>

            <List.Item>
              <Title level={5}>{`${t("products.tva")}:`}</Title>
              <Text>{record?.tva}</Text>
            </List.Item>

            <List.Item>
              <Title level={5}>{`${t("products.prestataire")}:`}</Title>
              <Text>{record?.prestataire}</Text>
            </List.Item>

            {/* <List.Item>
              <Title level={5}>{`${t("products.A réserver minimum avant")}:`}</Title>
              <Text>{record?.lang?.long_name}</Text>
            </List.Item> */}

            <List.Item>
              <Title level={5}>{`${t("products.Actif")}:`}</Title>
              <Text>{record?.status}</Text>
            </List.Item>

            {/* <List.Item>
              <Title level={5}>{`${t("products.birth_date")}:`}</Title>
              <Text>{record?.birth}</Text>
            </List.Item>

            <List.Item>
              <Title level={5}>{`${t("pages.booking.custom_request")}:`}</Title>
              <Text>{record?.comments}</Text>
            </List.Item>

            <List.Item>
              <Title level={5}>{`${t("pages.booking.stripe_id")}:`}</Title>
              <Text>{record?.stripe_id}</Text>
            </List.Item>

            <List.Item>
              <Title level={5}>{`${t("activated")}:`}</Title>
              <Text>{record?.is_deleted? <CloseOutlined style={{color: "red"}}/> : <CheckOutlined style={{color: "green"}}/> }</Text>
            </List.Item> */}

          </Card>
          
        </Col>

        {/* <Col xs={24} sm={24} md={24} lg={12} xl={14}>
          <Card key={"card_02_cards_stripe"} title={t("cards")} style={{ backgroundColor:"#EFEFEF", width: "100%", maxWidth:"500px", padding: '10px', margin: "0px auto 10px auto"}}>
            <ATable 
                columns={columnsCards}
                dataSource={cards as any}
                rowKey="id"
                pagination={false}
            />
          </Card>
        </Col> */}

      </Row>
      
      {/* <Card key={"card_02_bookings"} title={t("menu.bookings")} style={{ backgroundColor:"#EFEFEF", width: "100%", padding: '10px', margin: "0px auto 10px auto"}}>
        <ATable 
          // key={"card_02_bookings"}
          columns={columnsBookings}
          dataSource={bookingrooms}
          rowKey="id"
        />
      </Card> */}
      
    </Show>
  );
};
