import {
  useTranslate,
  IResourceComponentsProps,
  useApiUrl,
  useCustom,
} from "@pankod/refine-core";
import {
  List,
  Form,
  Button,
  DatePicker,
  useSelect,
  Select,
} from "@pankod/refine-antd";
import { IRoom, IPriceZone } from "interfaces";
import { useState } from "react";

import { Form as AtdForm, Switch } from 'antd';

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
dayjs.extend(localizedFormat)

export const ExcelStandardExtract: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const [form] = Form.useForm()

  const [pricezone_id, setPricezone_id] = useState<string>("")
  const [download, setDownload] = useState(false)

  const { selectProps: pricezoneSelectProps, queryResult: pricezoneQueryResult } = useSelect<IPriceZone>({
    resource: "pricezones",
    optionValue: "id",
    optionLabel: "name",
    debounce: 50,
    fetchSize: 100,
  });

  const handleSubmit = (e: any) => {
    form.validateFields().then((formValues) => {
      setDownload(true)
    });

  };


  return (
    <Form
      form={form}
    >
      <AtdForm.Item
        label={t("menu.extracts")}
        name={"yieldnotcompta"}
        valuePropName="checked"
        initialValue
      >
        <Switch defaultChecked checkedChildren={t('menu.yield')} unCheckedChildren={t('menu.compta')} />
      </AtdForm.Item>
      <AtdForm.Item
        label={t("pages.booking.dates")}
        name={["dates"]}
        rules={[{ required: true }]}
      >
        <DatePicker.RangePicker allowClear={false} format={t("short_date")} onChange={() => null} monthCellRender={(curr) => dayjs(curr).locale("fr").format("MMM")} />
      </AtdForm.Item>
      <AtdForm.Item label={t("menu.pricezones")} name="pricezone_filter">
        <Select
          {...pricezoneSelectProps}
          mode="multiple"
          allowClear
          placeholder={t("menu.pricezones")}
          onChange={(v) => { setPricezone_id(`${v}`) }}
          style={{ minWidth: '175px' }}
        />
      </AtdForm.Item>
      <RoomSelect pricezone_id={pricezone_id} form={form} />

      {true && <Button
        onClick={(e) => handleSubmit(e)
        }
      >
        Download
      </Button>}
      {download && <XlsDlComp
        room_ids={form?.getFieldsValue()?.room?.id?.flatMap((e: any) => e)?.join()}
        yieldnotcompta={form?.getFieldsValue()?.yieldnotcompta}
        start={form?.getFieldsValue()?.dates?.[0].toISOString().split('T')[0]}
        end={form?.getFieldsValue()?.dates?.[1].toISOString().split('T')[0]}
        form={form}
        setDownload={setDownload}
      />}

    </Form>
  )
}

const RoomSelect = (props: any) => {
  const { pricezone_id, form } = props

  const { selectProps: roomSelectProps, queryResult: roomQueryResult } = useSelect<IRoom>({
    resource: 'rooms',
    optionValue: "id",
    optionLabel: "name",
    fetchSize: 100,
    filters: [{ field: 'pricezone_id', operator: "in", value: pricezone_id }],
  });
  if (roomQueryResult?.isFetching) {
    form.resetFields(["room", "id"])
    return (<></>)
  }
  else

    return (
      <AtdForm.Item
        label="Suite"
        name={["room", "id"]}
        initialValue={roomSelectProps?.options?.flatMap((e: any) => e.value)}
      >
        <Select
          onChange={() => { form.validateFields() }}
          mode="multiple"
          {...roomSelectProps}
        />
      </AtdForm.Item>
    )

}


const XlsDlComp = (props: any) => {
  const { room_ids, yieldnotcompta, start, end, form, setDownload } = props

  const apiUrl = useApiUrl();
  const { data, isFetched, isFetching } = useCustom({
    url: `${apiUrl}/tables/xls`,
    method: "get",
    config: {

      headers: {
        "content_type": "application/vnd.ms-excel",
        "content_dl": "True",
      },
      query: {
        room_id__in: room_ids,
        yieldnotcompta: yieldnotcompta,
        start: start,
        end: end,
      },
    },
  })
  if (!isFetching && isFetched && data?.data) {

    const objUrl = URL.createObjectURL(new Blob([data?.data as BlobPart]))
    const link = document.createElement('a')
    link.href = objUrl
    link.setAttribute('download', 'File.xlsx')
    document.body.append(link)
    link.click()

    document.body.removeChild(link)
    setDownload(false)
  }

  return (<></>)
}