import {
  useTranslate,
  IResourceComponentsProps,
  useNavigation,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  DeleteButton,
  Button,
  Form,
  Input,
  Icons,
} from "@pankod/refine-antd";
import { IPartner, IPartnerCategory } from "interfaces";
import { useState } from "react";

export const PartnerList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { create, list } = useNavigation()

  const [ inputValue, setInputValue ] = useState("")

  const meta = inputValue == '' ? {} : { search: inputValue }

  const { tableProps, sorter, searchFormProps } = useTable<IPartner>({
    initialPageSize: 20,
    metaData: meta,
    initialSorter: [
      {
        field: "texts__name",
        order: "asc",
      },
    ],
  });

  return (
    <List
      title={t("menu.partners")}
      pageHeaderProps={{
        extra: [
          <Button key="page_header_extra_2" onClick={() => list("partnercategories")}>{`${t('posts.fields.category.title')}s`}</Button>,
          <Button key="page_header_extra_1" onClick={() => create(t("menu.partners"))}>+</Button>,
        ],
      }}

    >
      <Form 
        onValuesChange={() => searchFormProps.form?.submit()}
        key="form"
        {...searchFormProps}
      >
        <Form.Item label="" name="search_name">
          <Input
              autoComplete="off"
              placeholder="ID, Title, Content, etc."
              prefix={<Icons.SearchOutlined />}
              onChange={e => setInputValue(e.target.value)}
          />
        </Form.Item>
      </Form>

      <Table {...tableProps} 
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
        rowKey="id"
        >
        <Table.Column
          dataIndex="default_name"
          key="texts__name"
          title={t("customers.lname")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("default_name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="phone"
          key="phone"
          title={t("partner.phone")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("phone", sorter)}
          sorter
        />
        <Table.Column<IPartner>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export const PartnerCategoryList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { create } = useNavigation()

  const [ inputValue, setInputValue ] = useState("")

  const meta = inputValue == '' ? {} : { search: inputValue }

  const { tableProps, sorter } = useTable<IPartnerCategory>({
    initialPageSize: 20,
    metaData: meta,
    initialSorter: [
      {
        field: "default_name",
        order: "asc",
      },
    ],
  });

  return (
    <List
      title={`${t("posts.fields.category.title")}s ${t("menu.partners")}`}
      pageHeaderProps={{
        extra: [
          <Button key="page_header_extra_1" onClick={() => create("partnercategories")}>+</Button>,
        ],
      }}
    >
      <Table {...tableProps} 
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
        rowKey="id"
        >
        <Table.Column
          dataIndex="default_name"
          key="default_name"
          title={t("customers.lname")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("default_name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="display_order"
          key="display_order"
          title={t("display_order")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("display_order", sorter)}
          sorter
        />
        <Table.Column<IPartner>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
