import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
  // useNavigation
} from "@pankod/refine-core";
import { useNavigate, useNavigation } from '../../core/customNavigation';
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  Form,
  Input,
  Icons,
  Button,
  Tooltip,
  Avatar,
} from "@pankod/refine-antd";
import { ICustomer, IClient } from "interfaces";
import { useRef, useState } from "react";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
import { CreditCardOutlined, PlusOutlined } from "@ant-design/icons";
import { AddCardModal } from "./modals/addcard";
dayjs.extend(localizedFormat)

export const CustomerList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { show, create } = useNavigation()
  const navigate = useNavigate();
  const [ inputValue, setInputValue ] = useState("")
  const [ sCustomerId, setSCustomerId ] = useState<undefined|any>(undefined)
  const [ cardRecord, setcardRecord ] = useState<undefined|any>(undefined)

  const meta = inputValue == '' ? {} : { search: inputValue }

  const { tableProps, sorter, searchFormProps } = useTable<ICustomer, HttpError>({
    initialPageSize: 20,
    metaData: meta,
    initialSorter: [
      {
        field: "created",
        order: "desc",
      },
    ],
  });

  const AddCardModalRef = useRef<any>();

  const feedCardModal = (sCustomerId:any, record:any) => {
    setSCustomerId(sCustomerId)
    setcardRecord(record)
    AddCardModalRef?.current?.show()
  }

  return (
    <List
      pageHeaderProps={{
        extra: [
          <Button key="extra_00" size="small" onClick={(e:any) => create("clients", e)}>+{` ${t("buttons.create")}`}</Button>
        ]
      }}
    >
      <AddCardModal ref={AddCardModalRef} sCustomerId={sCustomerId} record={cardRecord} />      
      <Form 
        onValuesChange={() => searchFormProps.form?.submit()}
        {...searchFormProps}
      >
        <Form.Item label="" name="search_name">
          <Input
              autoComplete="off"
              placeholder="ID, Title, Content, etc."
              prefix={<Icons.SearchOutlined />}
              onChange={e => setInputValue(e.target.value)}
          />
        </Form.Item>
      </Form>

      <Table
        {...tableProps} 
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
        rowKey="id"
      >
        <Table.Column
          dataIndex="lname"
          key="lname"
          title={t("customers.lname")}
          render={(value, record: IClient) => <TextField underline style={{cursor:"grab"}} value={value} onClick={(e:any) => {show("clients", record?.id, e)}}/>}
          defaultSortOrder={getDefaultSortOrder("lname", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="fname"
          key="fname"
          title={t("customers.fname")}
          render={(value, record: IClient) => <TextField underline style={{cursor:"grab"}} value={value} onClick={(e:any) => {show("clients", record?.id, e)}}/>}
          defaultSortOrder={getDefaultSortOrder("fname", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="email"
          key="email"
          title={t("pages.login.mail")}
          render={(value, record: IClient) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("fname", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="phone"
          key="phone"
          title={t("customers.mobilephone")}
          render={(value, record: IClient) => <TextField value={value} onClick={(e:any) => {show("clients", record?.id, e)}}/>}
        />
        <Table.Column
          dataIndex="created"
          key="created"
          title={t("customers.subscribtion")}
          render={(value, record: IClient) => <TextField value={dayjs(value).locale(t("short_lang")).format(t('short_date'))}/>}
          defaultSortOrder={getDefaultSortOrder("created", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="langs__short_name"
          key="langs__short_name"
          title={''}
          render={(_, record: IClient) => <Avatar size={18} src={`/images/lang_img/${record.lang.id}_flag.svg`} />}
        />
        <Table.Column<IClient>
          title={t("table.actions")}
          key="actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <Tooltip title={t('pages.booking.newcard')}>
                <Button icon={<CreditCardOutlined/>} size='small' key={'div_extra_01'} onClick={() => feedCardModal(record?.stripe_id, record)} className="antd-btn-success"/>
              </Tooltip>
              <Tooltip title={t('pages.booking.newbooking')}>
                {/* <Button icon={<PlusOutlined />} size="small" onClick={(e:any) => create('bookings', e)} */}
                <Button icon={<PlusOutlined />} size="small" onClick={(e:any) => navigate(`/${t('menu.bookings')}/create?${t('menu.customers')}=${record?.id}`, e)}
                 className="antd-btn-info" />
              </Tooltip>
              <EditButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
      </List>
    
  );
};
