import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import { Create, useForm } from "@pankod/refine-antd";
import { IClient } from "interfaces";
import { CustomerForm } from "./form";

export const CustomerCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, queryResult, form, formLoading, onFinish } = useForm<IClient>({'redirect': 'show'});

  return (
    <Create saveButtonProps={saveButtonProps}>
      {!formLoading && 
      <CustomerForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} onFinish={onFinish}/>}
    </Create>
  );
};
