import {
  useTranslate,
  useApiUrl,
  useCustom,
} from "@pankod/refine-core";
import {
  List,
  Form,
  Button,
  DatePicker,
  useSelect,
  Select,
  InputNumber,
} from "@pankod/refine-antd";
import { useState } from "react";

import { Form as AtdForm, Switch } from 'antd';

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
dayjs.extend(localizedFormat)

export const BookingExtractFormComponent: React.FC = () => {
  const t = useTranslate();
  const [form] = Form.useForm();
  const [download, setDownload] = useState(false)

  const handleSubmit = (e: any) => {
    form.validateFields().then((formValues) => {
      setDownload(true)
    });

  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <AtdForm.Item
        label={t("pages.booking.dates")}
        name={["dates"]}
        rules={[{ required: true }]}
      >
        <DatePicker.RangePicker allowClear={false} format={t("short_date")} onChange={() => null} monthCellRender={(curr) => dayjs(curr).locale("fr").format("MMM")} />
      </AtdForm.Item>
      <AtdForm.Item
        label={`${t("extracts.book_date")} / ${t("extracts.book_date_create")}` }
        name={"bybookdate"}
        valuePropName="checked"
        initialValue={false}
      >
        <Switch />
      </AtdForm.Item>
      <AtdForm.Item
        label={`${t("extracts.fullvalid")} / ${t("extracts.paid_canceled")}`}
        name={"canceledbook"}
        valuePropName="checked"
        initialValue={false}
      >
        <Switch />
      </AtdForm.Item>

      {true && <Button
        onClick={(e) => handleSubmit(e)
        }
      >
        Download
      </Button>}
      {download && <CsvDlComp
        queryData={{
          'start': form?.getFieldsValue()?.dates?.[0].toISOString().split('T')[0],
          'end': form?.getFieldsValue()?.dates?.[1].toISOString().split('T')[0],
          'bybookdate': form?.getFieldsValue()?.bybookdate ? true: false,
          'canceledbook': form?.getFieldsValue()?.canceledbook ? true: false,
        }}
        url={'tables/bookinglists'}
        filename={`Bookings_${form?.getFieldsValue()?.dates?.[0].toISOString().split('T')[0]}_${form?.getFieldsValue()?.dates?.[1].toISOString().split('T')[0]}`}
        setDownload={setDownload}
      />}

    </Form>
  );
};

export const ClientFidelsFormComponent: React.FC = () => {
  const t = useTranslate();
  const [form] = Form.useForm();
  const apiUrl = useApiUrl();
  const [download, setDownload] = useState(false)

  const { selectProps: selectLangProps } = useSelect<{ id: string, long_name: string }>({
    resource: "langs",
    optionLabel: "long_name",
    optionValue: "id",
    fetchSize: 200,
    onSearch: (value) => [
        { field: "search", operator: "eq", value, }
    ],
  });

  const handleSubmit = (e: any) => {
    form.validateFields().then((formValues) => {
      setDownload(true)
    });

  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <AtdForm.Item
        label={t("pages.booking.dates")}
        name={["dates"]}
        rules={[{ required: true }]}
      >
        <DatePicker.RangePicker allowClear={false} format={t("short_date")} onChange={() => null} monthCellRender={(curr) => dayjs(curr).locale("fr").format("MMM")} />
      </AtdForm.Item>

      <AtdForm.Item
        label="Number of Valid Bookings"
        name="number_of_valid_bookings"
        rules={[{ required: true, type: "number", min: 1, message: "Must be a positive integer!" }]}
      >
        <InputNumber min={1} />
      </AtdForm.Item>
      <Form.Item
          label={t("customers.usual_lang")}
          name={["lang_id"]}
      >
          <Select {...selectLangProps} />

      </Form.Item>

      {true && <Button

        onClick={(e) => handleSubmit(e)
        }
      >
        Download
      </Button>}

      {download && <CsvDlComp
        queryData={{
          'start': form?.getFieldsValue()?.dates?.[0].toISOString().split('T')[0],
          'end': form?.getFieldsValue()?.dates?.[1].toISOString().split('T')[0],
          'number_of_valid_bookings': form?.getFieldsValue()?.number_of_valid_bookings,
          'lang_id': form?.getFieldsValue()?.lang_id,
        }}
        url={'tables/loyalcli'}
        filename={`Loyals_Client_${form?.getFieldsValue()?.dates?.[0].toISOString().split('T')[0]}_${form?.getFieldsValue()?.dates?.[1].toISOString().split('T')[0]}`}
        setDownload={setDownload}
      />}

    </Form>
  );
};

const CsvDlComp = (props: any) => {
  const apiUrl = useApiUrl();
  const { queryData, setDownload, url, filename } = props

  const { data, isFetched, isFetching } = useCustom({
    url: `${apiUrl}/${url}`,
    method: "get",
    config: {
      headers: {
        "content_type": "text/csv",
        "content_dl": "True",
      },
      query: queryData,
    },
  });

  if (!isFetching && isFetched && data?.data) {

    const objUrl = URL.createObjectURL(new Blob([data?.data as BlobPart]))
    const link = document.createElement('a')
    link.href = objUrl
    link.setAttribute('download', `${filename}.csv`)
    document.body.append(link)
    link.click()

    document.body.removeChild(link)
    setDownload(false)
  } else {
    setDownload(true)
  }

  return (<></>)
} 
