import {
  useTranslate,
  IResourceComponentsProps,
  useMany,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  // usePagination,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useSelect,
  TagField,
  FilterDropdown,
  Select,
  ShowButton,
} from "@pankod/refine-antd";
import { IYieldAnalysis } from "interfaces";
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'

dayjs.extend(localizedFormat)

export const YieldAnalysisList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  // console.log(t)

  const { tableProps, sorter } = useTable<IYieldAnalysis>({
    // resource: "yieldanalysis",
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  // console.log(tableProps)
  return (
    <List>
      <Table {...tableProps} rowKey="id">
        {/* <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        /> */}
        <Table.Column
          dataIndex="dateref"
          key="dateref"
          title={t("yieldanalysis.dateref")}
          render={(value) => <TextField value={dayjs(value).locale(t("short_lang")).format('LL')} />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="pricezone"
          key="pricezone"
          title={t("rooms.pricezone")}
          render={(value) => <TextField value={value.name}/>}
          defaultSortOrder={getDefaultSortOrder("pricezone", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="start"
          key="start"
          title={t("yieldanalysis.start")}
          render={(value) => <TextField value={dayjs(value).locale(t("short_lang")).format('LL')} />}
          defaultSortOrder={getDefaultSortOrder("start", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="end"
          key="end"
          title={t("yieldanalysis.end")}
          render={(value) => <TextField value={dayjs(value).locale(t("short_lang")).format('LL')} />}
          defaultSortOrder={getDefaultSortOrder("end", sorter)}
          sorter
        />
        <Table.Column<IYieldAnalysis>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton value={'junior'} size="small" recordItemId={record.id} />
              {/* <ShowButton hideText size="small" recordItemId={record.id} /> */}
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
