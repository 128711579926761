import { useModalForm, Modal, Form, Typography, Select, useSelect, Space, Input } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { IBooking } from "interfaces";
import { forwardRef, useImperativeHandle, useState } from "react";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
dayjs.extend(localizedFormat)

const { Title, Text } = Typography;


export
  const ChangeRoomsModal = forwardRef((props, ref) => {
    const t = useTranslate();
    const [pricezone_id, setPricezone_id] = useState<string>("")
    const [roomtype_id, setroomType_id] = useState<string>("")
    const [equipments_id, setEquipments_id] = useState<string>("")
    const [capacity_max, setCapacity_max] = useState<string>("")
    const { modalProps, formProps, queryResult, show, close, form } = useModalForm<IBooking>({
      action: "edit",
      redirect: false,
    });
    useImperativeHandle(ref, () => ({
      show(id: string) {
        show(id);
      }
    }));

    let filters: any[] = []
    if (pricezone_id != 'undefined' && pricezone_id != '') {
      filters = filters.concat({ field: 'pricezone_id', operator: "in", value: pricezone_id })
    }
    if (capacity_max != 'undefined' && capacity_max != '') {
      filters = filters.concat({ field: 'capacity_max', operator: "gte", value: capacity_max })
    }
    if (roomtype_id != 'undefined' && roomtype_id != '') {
      filters = filters.concat({ field: 'roomtypes__id', operator: "in", value: roomtype_id })
    }
    if (equipments_id != 'undefined' && equipments_id) {
      filters = filters.concat(equipments_id.split(',').map((id) => { return { field: 'equipments', operator: "eq", value: id } }))
    }


    const { selectProps: roomSelectProps, queryResult: roomQueryResult } = useSelect<IBooking>({
      resource: "rooms",
      optionValue: "id",
      optionLabel: "name",
      fetchSize: 100,
      filters: filters,
      onSearch: (value) => [
        {
          field: "name",
          operator: "contains",
          value,
        },
      ],
    });

    const { selectProps: pricezoneSelectProps, queryResult: pricezoneQueryResult } = useSelect<IBooking>({
      resource: "pricezones",
      optionValue: "id",
      optionLabel: "name",
      fetchSize: 100,
    });

    const { selectProps: equipmentSelectProps, queryResult: equipmentQueryResult } = useSelect<IBooking>({
      resource: "equipments",
      optionValue: "id",
      optionLabel: "pName",
      fetchSize: 100,
      filters: [{
        field: 'is_in_filter',
        operator: 'eq',
        value: true
      }]
    });

    const { selectProps: roomtypeSelectProps, queryResult: roomtypeQueryResult } = useSelect<IBooking>({
      resource: "roomtypes",
      optionValue: "id",
      optionLabel: "name",
      fetchSize: 100,
    });

    const bookingrooms = formProps.form?.getFieldValue("bookingrooms")
    modalProps.title = <><Title level={4} >{`${t("pages.booking.relocate")} ${t("pages.booking.title")}`}</Title><Text>{`${roomQueryResult?.data?.total} ${t('yieldanalysis.availablerooms')}`}</Text></>
    if (bookingrooms == undefined) return (<></>)
    return (
      <Modal onOk={close} {...modalProps}>

        <Form
          {...pricezoneSelectProps.value}
          layout="vertical"
        >
          <Space wrap>

            <Form.Item label={t("menu.roomtypes")} name="roomtype_filter">
              <Select
                {...roomtypeSelectProps}
                mode="multiple"
                allowClear
                placeholder={t("menu.roomtypes")}
                onChange={(v) => { setroomType_id(`${v}`) }}
                style={{ minWidth: '175px' }}
              />
            </Form.Item>
            <Form.Item label={t("menu.equipments")} name="equipment_filter">
              <Select
                {...equipmentSelectProps}
                mode="multiple"
                allowClear
                placeholder={t("menu.equipments")}
                onChange={(v) => { setEquipments_id(`${v}`) }}
                style={{ minWidth: '175px' }}
              />
            </Form.Item>
            <Form.Item label={t("menu.pricezones")} name="pricezone_filter">
              <Select
                {...pricezoneSelectProps}
                mode="multiple"
                allowClear
                placeholder={t("menu.pricezones")}
                onChange={(v) => { setPricezone_id(`${v}`) }}
                style={{ minWidth: '175px' }}
              />
            </Form.Item>
            <Form.Item label={t("pages.booking.nb_guests")} name="capacity_max_filter">
              <Select
                allowClear
                placeholder={t("pages.booking.nb_guests")}
                onChange={(v) => { setCapacity_max(`${v}`) }}
                defaultValue={capacity_max}
              >
                <Select.Option value={1} label={1} >1</Select.Option>
                <Select.Option value={2} label={2} >2</Select.Option>
                <Select.Option value={3} label={3} >3</Select.Option>
                <Select.Option value={4} label={4} >4</Select.Option>
                <Select.Option value={5} label={5} >5</Select.Option>
              </Select>

            </Form.Item>
          </Space>
        </Form>

        <Form {...formProps}>
          <Form.Item
            name="context"
            initialValue="update_room"
            hidden
          ><Input /></Form.Item>

          {bookingrooms.map((bookingroom: { id: any, room: { name: any; }; }, id: string | number) => {
            return (
              <div
                key={`ChangeRoomsModal_input_div${id}`}
              >
                <Title level={5}>{`${t("pages.booking.relocate_from")}: ${bookingroom.room.name} `}</Title>
                <Form.Item
                  name={["bookingrooms", id, "id"]}
                  initialValue={bookingroom?.id}
                  hidden
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t("pages.booking.room")}
                  name={["bookingrooms", id, "room"]}
                >
                  <Select {...roomSelectProps} />
                </Form.Item>
              </div>
            )
          })}
        </Form>
      </Modal>
    )
  })
