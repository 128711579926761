import { Button, Input } from "@pankod/refine-antd"
import { FC, ReactNode, useEffect, useState } from "react"

type inputPlusMinusProps = {
  label?: JSX.Element|string|undefined,
  min?: number|undefined,
  max?: number|undefined,
  initialValue?: number,
  value?: number,
  buttons?: boolean,
  integerOnly?: boolean,
  onChange?: any,
  addonBefore?: ReactNode,
  addonAfter?: ReactNode,
}
  
export const InputPlusMinus: FC<inputPlusMinusProps> = (props) => {
  const { label, min, max, initialValue, value, buttons, integerOnly, onChange, addonBefore, addonAfter } = props
  const [nb, setNb] = useState<any>(initialValue || value)

  useEffect(() => {
    setNb(value as number)
  }, [value])


  const checkNumber = (strNb:any) => {
    if (nb==0&&strNb=="") {
      setNb(initialValue)
      onChange(initialValue)

      return true
    } else if (strNb=="") {
      setNb(min||0)
      onChange(min||0)
      return true
    }
    const regexInteger = /^[0-9]+[0-9]*$/;
    const regexReal = /^[\d|\.|\,]+$/;
    const str = (`${strNb}`.replace(',','.')).replace(/^0+/, '')
    
    
    const result = integerOnly? regexInteger.test(str) : regexReal.test(str);
    console.log(result)
    if (result) {
      const newNb = Number(str)
      if (min != undefined && newNb <= min) {
          setNb(min)
          onChange(min)
        return true
        } else if (max != undefined && newNb >= max) {
          setNb(max)
          onChange(max)
        return true
        }
        setNb(str)
        onChange(newNb)
        return true
      } else {
        return false
      }
    }

    const btnPlus = !buttons? (<></>): <Button size="small" onClick={() => checkNumber(Number(nb)+1)} style={{border: "none"}}>+</Button>
    const btnMinus = !buttons? (<></>): <Button size="small" onClick={() => checkNumber(Number(nb)-1)} style={{border: "none"}}>-</Button>
    return (
        <>
          <div>{label}</div>
          <Input 
            style={{maxWidth: "7rem"}}
            prefix={btnPlus}
            suffix={btnMinus}
            onChange={(e:any) => {
              checkNumber(e.target.value)
            }}
            value={nb}
            addonAfter={addonAfter}
            addonBefore={addonBefore}
            />
        </>
    )
}

InputPlusMinus.defaultProps = {
  initialValue: 0,
  value: 0,
  label: "",
  min: undefined,
  max: undefined,
  buttons: false,
  integerOnly: false,
  onChange: (e:any) => void(null),
}
