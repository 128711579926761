import {
  useTranslate,
} from "@pankod/refine-core";
import { Typography, Modal, useModalForm, Form } from "@pankod/refine-antd";

import { IClient } from "interfaces";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { forwardRef, useImperativeHandle } from "react";
import { InputEditor } from "components/inputs/inputeditor";
dayjs.extend(localizedFormat)


const { Title, Text } = Typography;


export const ChangeHoursModal = forwardRef((props, ref) => {
  const t = useTranslate();
  const { modalProps, formProps, queryResult, show, close, form } = useModalForm<IClient>({
    action: "edit",
    redirect: false,
  });
  useImperativeHandle(ref, () => ({
    show(id: string) {
      show(id);
    }
  }));

  
  return (
    <Modal onOk={close} {...modalProps} title={<Title level={4} >{`${t("buttons.edit")} ${t("pages.booking.custom_request")}`}</Title>} okButtonProps={{onClick: () => form.submit()}}>
      <Form {...formProps}>
        <Form.Item
          name={["comments"]}
        >
          <InputEditor />
        </Form.Item>
      </Form>
    </Modal>
  )
})