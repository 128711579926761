import { useModalForm, Modal, Form, Input, Typography, InputNumber } from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { IBooking, IBookingRoom } from "interfaces";
import { forwardRef, useImperativeHandle, useState } from "react";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
import React from "react";
dayjs.extend(localizedFormat)

const { Title, Text } = Typography;

export const ChangeHoursModal = forwardRef((props, ref) => {
  const t = useTranslate();
  const { modalProps, formProps, queryResult, show, close, form } = useModalForm<IBooking>({
    action: "edit",
    redirect: false,
  });
  useImperativeHandle(ref, () => ({
    show(id: string) {
      show(id);
    }
  }));

  const formSubmit = () => {
    bookingrooms?.map((_br: IBookingRoom, iBr: number) => {
      const start = form.getFieldValue(["bookingrooms", iBr, "start"])
      if (start) {
        form.setFields([{
          name: ["bookingrooms", iBr, "start"],
          value: dayjs(start).format('YYYY-MM-DDTHH:mm:ss'),
        }]);
      }
      const end = form.getFieldValue(["bookingrooms", iBr, "end"])
      if (end) {
        form.setFields([{
          name: ["bookingrooms", iBr, "end"],
          value: dayjs(end).format('YYYY-MM-DDTHH:mm:ss'),
        }]);
      }
    })
    form.submit()
  }


  const bookingrooms = formProps.form?.getFieldValue("bookingrooms")
  modalProps.title = <Title level={4} >{`${t("buttons.edit")} ${t("pages.booking.title")}`}</Title>
  if (bookingrooms == undefined) return (<></>)
  return (
    <Modal onOk={close} {...modalProps} okButtonProps={{ onClick: () => formSubmit() }}>
      <Form {...formProps}>
        <Form.Item
          name="context"
          initialValue="update_hour"
          hidden
        ><Input /></Form.Item>

        {bookingrooms.map((bookingroom: any, id: string | number) => {
          return (
            <div
              key={`ChangeDatesModal_input_div${id}`}
            >
              <Form.Item
                name={["bookingrooms", id, "id"]}
                initialValue={bookingroom?.id}
                hidden
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("pages.booking.start")}
                name={["bookingrooms", id, "start"]}
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimeInput />
              </Form.Item>
              <Form.Item
                label={t("pages.booking.end")}
                name={["bookingrooms", id, "end"]}
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TimeInput />
              </Form.Item>
            </div>
          )
        })}
      </Form>
    </Modal>


  )
})

interface TimeInputProps extends IResourceComponentsProps {
  value?: any;
  onChange?: { (e: any): any };
}


const TimeInput: React.FC<TimeInputProps> = (props) => {
  const { value, onChange } = props
  const [datetime, setDateTime] = useState<dayjs.Dayjs>(value)

  const changeHour = (e: any) => {
    const tmp = dayjs(datetime).set('hour', e)
    if (onChange)
      onChange(dayjs(tmp).format())
    setDateTime(tmp)
  }

  const changeMinutes = (e: any) => {
    const tmp = dayjs(datetime).set('minute', e)
    if (onChange)
      onChange(dayjs(tmp).format())
    setDateTime(tmp)
  }

  return (
    <React.Fragment>
      <InputNumber min={0} max={23} onChange={changeHour} value={dayjs(datetime).get('hour')} />h&nbsp;:&nbsp;
      <InputNumber min={0} max={59} onChange={changeMinutes} value={dayjs(datetime).get('minute')} />m
    </React.Fragment>
  )
}


