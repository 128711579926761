import {
	useTranslate,
	IResourceComponentsProps,
	CrudFilters,
	HttpError,
	useNavigation,
} from "@pankod/refine-core";
import {
	List,
	Table,
	TextField,
	useTable,
	getDefaultSortOrder,
	Space,
	EditButton,
	Form,
	Input,
	Icons,
	Button,
	ShowButton,
	CreateButton,
	RefreshButton,
} from "@pankod/refine-antd";
import React, { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { ITedee } from "interfaces";
import { getTedeeBatteryAndStateData } from "./service/tedeeService";

export const TedeeList: React.FC<IResourceComponentsProps> = () => {
	const t = useTranslate();
	const { show, list } = useNavigation();
	const [tedeeData, setTedeeData] = useState<Record<string, { batteryLevel: number, state: string, color: string }>>({});

	const { tableProps, sorter, searchFormProps } = useTable<ITedee, HttpError>({
		initialSorter: [
			{
				field: "name",
				order: "asc",
			},
		],
	});

	const handleRefresh = () => {
		window.location.reload();
	};

	useEffect(() => {
		const getData = async () => {
			const batteryAndStateData = await getTedeeBatteryAndStateData();
			setTedeeData(batteryAndStateData);
		};
		getData();
	}, []);

	return (
		<List
			pageHeaderProps={{
				title: t("menu.tedee"),
				extra: [
					<RefreshButton key="refresh" onClick={handleRefresh}>{t("tedee.refresh")}</RefreshButton>,
					<CreateButton> {t("tedee.createcode")}</CreateButton>,
				],
			}}
		>
			<Table
				{...tableProps}
				pagination={{
					...tableProps.pagination,
					showSizeChanger: true,
				}}
				rowKey="id"
			>
				<Table.Column
					dataIndex="name"
					key="name"
					title={t("tedee.name")}
					render={(value, record: ITedee) => <TextField value={value} onClick={() => { show("tedee", record?.id); }} />}
					defaultSortOrder={getDefaultSortOrder("name", sorter)}
					sorter
				/>
				<Table.Column
					dataIndex="battery"
					key="battery"
					title={t("tedee.battery")}
					render={(_, record: ITedee) => (
						<TextField value={tedeeData[record.lock_id] !== undefined ? `${tedeeData[record.lock_id].batteryLevel}%` : 'N/A'} />
					)}
				/>
				<Table.Column
					dataIndex="state"
					key="state"
					title={t("tedee.state")}
					render={(_, record: ITedee) => (
						<TextField value={tedeeData[record.lock_id] !== undefined ? `${tedeeData[record.lock_id].state}` : 'N/A'} style={{ color: tedeeData[record.lock_id]?.color || 'black' }} />
					)}
				/>
				<Table.Column
					dataIndex="actions"
					key="actions"
					title={t("tedee.action")}
					render={(_, record: ITedee) => (
						<Space>
							<ShowButton hideText size="small" recordItemId={record.id} />
						</Space>
					)}
				/>
			</Table>
		</List>
	);
};
