import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError,
  useNavigation
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  Form,
  Input,
  Icons,
  Avatar,
} from "@pankod/refine-antd";
import { IRoom, ICategory, ITemplate } from "interfaces";
import { useState } from "react";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
dayjs.extend(localizedFormat)

export const TemplateList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { show } = useNavigation()
  const [ inputValue, setInputValue ] = useState("")

  const meta = inputValue == '' ? {} : { search: inputValue }

  // console.log(meta)

  const { tableProps, sorter, searchFormProps } = useTable<ITemplate, HttpError, ITemplateFilterVariables>({
    initialPageSize: 20,
    
    metaData: meta,
    initialSorter: [
      {
        field: "created",
        order: "desc",
      },
      // {
      //   field: "fname",
      //   order: "asc",
      // },
    ],
  });


  const isLoading = false;

  return (
    <List
      pageHeaderProps={{
        extra: [
          <Form 
            layout="horizontal"
            onValuesChange={() => searchFormProps.form?.submit()}
            key="page_header_extra_1"
            // size="small"
            {...searchFormProps}
          >
            <Form.Item label="" name="name" style={{ marginBottom: 0 }}>
              <Input
                  onChange={e => setInputValue(e.target.value)}
                  placeholder="ID, Title, Content, etc."
                  prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
          </Form>
        ],
        // subTitle:`${t("yieldanalysis.yieldanalysis")} ${resource?.dateref ? ' - ' + dayjs(resource.dateref).locale(t("short_lang")).format('ddd D/M') : ''} ${resource?.modified ? ` (${t('last_modified')} ` + dayjs(resource.modified).locale(t("short_lang")).format('D/M, HH:mm') + ')' : ''}`
    }}>
      <Table
        {...tableProps} 
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
        rowKey="id"
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: event => {show("templates", record?.id)},
        //   };
        // }}

      >
        {/* <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        /> */}
        <Table.Column
          dataIndex="type"
          key="type"
          title={t("templates.template")}
          render={(value, record: any) => <TextField value={value} onClick={() => {show("templates", record?.id)}}/>}
          defaultSortOrder={getDefaultSortOrder("type", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="lang_id"
          key="lang_id"
          title={''}
          render={(value, record: any) => <Avatar size={18} src={`/images/lang_img/${value}_flag.svg`} />}
        />
        <Table.Column<ITemplate>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              {/* <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} /> */}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

interface ITemplateFilterVariables {
  type: string;
  // createdAt: [Dayjs, Dayjs];
}
