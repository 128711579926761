import {
  useTranslate,
  IResourceComponentsProps,
  useNavigation,
  CrudFilters,
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useSelect,
  Select,
  Button,
  Form,
  Icons,
  Input,
} from "@pankod/refine-antd";
import { IAgenda, IEvent } from "interfaces";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
dayjs.extend(localizedFormat)

export const EventList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { create } = useNavigation()

  const { selectProps: agendasSelectProps, queryResult: agendasQueryResult } = useSelect<IAgenda>({
    resource: "agendas",
    optionValue: "id",
    optionLabel: "name",
    fetchSize: 100,
  });

  const { tableProps, sorter, searchFormProps} = useTable<IEvent>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    onSearch: (params: any) => {
      const filters: CrudFilters = [];
      const { eventagendas__id, name } = params;

      filters.push(
        {
          field: "name",
          operator: "contains",
          value: name ? name : undefined,
        },
        {
          field: "eventagendas__id",
          operator: "in",
          value: eventagendas__id ? eventagendas__id : undefined,
        },
      );

      return filters;
  },

  });
  
  return (
    <List
      title={t("menu.events")}
      pageHeaderProps={{
        extra: [
          <Form
            {...searchFormProps}
            size="small"
            layout="inline"
            onValuesChange={() => {searchFormProps.form?.submit()}}
            key="page_header_extra_1"
            >
            <Form.Item label="" name="name" style={{ marginBottom: 0 }}>
              <Input
                  placeholder={t("menu.events")}
                  prefix={<Icons.SearchOutlined />}
                  autoComplete="off"
              />
            </Form.Item>
            <Form.Item name="eventagendas__id">
              <Select
                mode="multiple"
                allowClear
                options={agendasSelectProps.options}
                placeholder={t("menu.agendas")}
                style={{minWidth:'175px'}}
              />
            </Form.Item>
            <Form.Item >
              <Button onClick={() => create(t("menu.events"))}>+</Button>
            </Form.Item>
          </Form>,
        ],
      }}

    >
      <Table
        {...tableProps}
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
        rowKey="id"
      >
      <Table.Column
          dataIndex="name"
          key="name"
          title={t("events.event")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="grade"
          key="grade"
          title={t("events.grade")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("grade", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="start"
          key="start"
          title={t("events.start")}
          render={(value) => <TextField value={dayjs(value).locale(t("short_lang")).format('LL')} />}
          defaultSortOrder={getDefaultSortOrder("start", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="end"
          key="end"
          title={t("events.end")}
          render={(value) => <TextField value={dayjs(value).locale(t("short_lang")).format('LL')} />}
          defaultSortOrder={getDefaultSortOrder("end", sorter)}
          sorter
        />
        <Table.Column
          dataIndex="eventagendas"
          key="eventagendas"
          title={t("menu.agendas")}
          render={(value) => 
            <>
              {value.map((evagenda:any) => <div key={`evagenda${evagenda.id}`}>{evagenda.name}</div>)}
            </>
          }
        />
        <Table.Column<IEvent>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
