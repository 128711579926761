import { ClipboardEvent, DragEvent, useEffect, useState } from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Col,
  Edit,
  Form,
  Input,
  Row,
  Select,
  useForm,
  useSelect,
  Typography,
  Tag
} from "@pankod/refine-antd";


import "react-mde/lib/styles/css/react-mde-all.css";

import { ITemplate } from "interfaces";

const { TextArea } = Input;
const { Title, Text } = Typography;

export const TemplateEdit: React.FC<IResourceComponentsProps> = () => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult, mutationResult } = useForm<ITemplate>(
    {
          
          onMutationError: (data: any, variables: any, context: any) => {
              console.log({ data, variables, context });
          },
          // liveMode: 'off',
          warnWhenUnsavedChanges: true,

          // mutationMode: "optimistic",

  }


  );

  // const onFinish = (e:any) => {
  //   console.log(e)
  // }
  // onFinish()
  // console.log(onFinish())

  // setHtmlTemplate(queryResult?.data?.data?.html)
  // const { selectProps: categorySelectProps } = useSelect<ITemplate>({
  //   resource: "categories",
  //   defaultValue: queryResult?.data?.data.category.id,
  // });
  const [htmlTemplate, setHtmlTemplate] = useState<string>('');

  const htmlChanged = (e: { target: { value: any; }; }) => {
    console.log(e?.target?.value)
    setHtmlTemplate(`${e?.target?.value}`)

  } 

  useEffect(() => {
    setHtmlTemplate(`${queryResult?.data?.data?.html}`)
  },[queryResult?.dataUpdatedAt, queryResult?.data?.data?.html])

  return (
    <Edit saveButtonProps={saveButtonProps} title={queryResult?.data?.data?.type}>

      <Form {...formProps} layout="vertical">
      {/* <Form.Item
              label={t("templates.type")}
              name="type"
              
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
              </Form.Item> */}
        {/* <Row >

            <Title level={5}>{t("posts.fields.title")}</Title>
            <Text>{queryResult?.data?.data?.type}</Text>
        </Row> */}
        <Row >
         <Col span={12}>
            <Form.Item
              label={t("posts.fields.content")}
              name="html"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea rows={25} onChange={htmlChanged} />
            </Form.Item>

          </Col>
          <Col span={12}>
            <div dangerouslySetInnerHTML={{ __html: htmlTemplate  }} />
          </Col>
        </Row>

        {/* <Form.Item
          label={t("posts.fields.status.title")}
          name="status"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            options={[
              {
                label: t("posts.fields.status.published"),
                value: "published",
              },
              {
                label: t("posts.fields.status.draft"),
                value: "draft",
              },
              {
                label: t("posts.fields.status.rejected"),
                value: "rejected",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          label={t("posts.fields.category.title")}
          name={["category", "id"]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...categorySelectProps} />
        </Form.Item>
        */}
      </Form>
    </Edit>
  );
};
