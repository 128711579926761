import React from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {  Create, useForm } from "@pankod/refine-antd";
import { IThematic } from "interfaces";
import { ThematicForm } from "./form";

export const ThematicCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, queryResult, form, formLoading, onFinish, mutationResult } = useForm<IThematic>({ 'redirect': 'edit' });

  return (
    <Create saveButtonProps={saveButtonProps} title={`${t('buttons.create')} ${t('menu.partners')}`}>
      <ThematicForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />
    </Create>
  );
};

