import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {  Edit, useForm } from "@pankod/refine-antd";

import { IClient } from "interfaces";
import { CustomerForm } from './form'

export const CustomerEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult, form, formLoading, onFinish } = useForm<IClient>({'redirect': 'edit'});

  return (
    <Edit saveButtonProps={saveButtonProps}>
      {!formLoading && 
      <CustomerForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} onFinish={onFinish}/>}
    </Edit>
  );
};
