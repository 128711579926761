import { useModalForm, Modal, DatePicker, Form, Input, Typography, Select, Space, Button, useSelect, FormInstance, Icon, NumberField, InputNumber } from "@pankod/refine-antd";
import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { InputPlusMinus } from "components/inputs/inputplusminus";
import { IBooking, IBookingRoom, IConso } from "interfaces";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
import { DeleteOutlined } from "@ant-design/icons";
import React from "react";
dayjs.extend(localizedFormat)

const { confirm } = Modal;

const { Title, Text } = Typography;

interface ItemObjProps {
  id: string;
  name: string;
  number: number;
  price: number;

  room_id: number;
  bookingroom_id: number;
  bookingroomform_id: number;
}

export const AddConsosModal = forwardRef((props, ref) => {
  const t = useTranslate();
  const { modalProps, formProps, form, queryResult, id, show, close, formLoading, } = useModalForm<IBooking>({
    action: "edit",
    redirect: false,
  });

  const bookingrooms: [IBookingRoom] = formProps.form?.getFieldValue("bookingrooms")
  modalProps.title = <Title level={4} >{`${t("buttons.add")} ${t("pages.booking.consos")}`}</Title>

  const [itemData, setItemData] = useState<ItemObjProps[]>([])

  useImperativeHandle(ref, () => ({
    show(id: string) {
      if (itemData.length) {
        form.resetFields()
        setItemData([])
      }
      show(id);
    }
  }));

  const addConsoToPush = (conso: IConso, bookingroom_id: number, room_id: number, bookingroomform_id: number) => {
    const conso_push = { id: conso?.id, bookingroom_id: bookingroom_id, name: conso?.name, number: 1, custom_unit_price: Number(conso.price), price: Number(conso.price), bookingroomform_id: bookingroomform_id, room_id: room_id }
    setItemData([...itemData, conso_push]);

    let tmp = form.getFieldValue(`bookingrooms`)
    tmp[bookingroomform_id].consobookingrooms[itemData.filter((item: any) => room_id == item?.room_id).length] = { custom_unit_price: Number(conso.price), number: 1, conso_id: conso?.id, bookingroom_id: bookingroom_id }
    form.setFieldsValue([{ 'bookingrooms': tmp }]);
  }

  const removeConsoToPush = (item: ItemObjProps, bookingroom_id: number, room_id: number, bookingroomform_id: number) => {
    const filteredItemData = itemData.filter((_item: ItemObjProps) => (_item.id != item.id || _item.room_id != room_id || _item.bookingroom_id != bookingroom_id));
    setItemData(filteredItemData);

    let tmp = form.getFieldValue('bookingrooms');
    const consobookingrooms = tmp[bookingroomform_id].consobookingrooms.filter((consoItem: any) => (consoItem.conso_id != item.id));
    tmp[bookingroomform_id].consobookingrooms = consobookingrooms;
    form.setFieldsValue({ 'bookingrooms': tmp });
  }

  const formSubmit = () => {
    form.submit()
    setItemData([])
  }

  useEffect(() => {
    form.resetFields()
  }, [])

  return (
    <Form  {...formProps}>
      <Modal {...modalProps} okButtonProps={{ onClick: () => formSubmit() }}>

        <Form.Item
          name="context"
          initialValue="add_product"
          hidden
        ><Input /></Form.Item>

        {bookingrooms?.map((bookingroom: IBookingRoom, id: number) => {

          return (<div key={`AddConsosModal_bookingrooms_${id}`}>
            <Form.Item
              name={["bookingrooms", id, "id"]}
              initialValue={bookingroom.id}
              hidden
            >
              <Input />
            </Form.Item>

            <AddRoomConsoForm bookingroom={bookingroom} bookingroomform_id={id} form={form} itemData={itemData} addConsoToPush={addConsoToPush} removeConsoToPush={removeConsoToPush} key={`AddConsosForm_bookingrooms_${bookingroom.room?.id}`} />
          </div>)
        })}
      </Modal>
    </Form>
  )
});

interface IAddRoomConsoFormProps extends IResourceComponentsProps {
  bookingroom: IBookingRoom;
  itemData: ItemObjProps[];
  bookingroomform_id: number;
  form: FormInstance<{}>;
  addConsoToPush: (conso: IConso, bookingroom_id: number, room_id: number, bookingroomform_id: number) => void;
  removeConsoToPush: (item: ItemObjProps, bookingroom_id: number, room_id: number, bookingroomform_id: number) => void;

}

const AddRoomConsoForm: React.FC<IAddRoomConsoFormProps> = (props) => {
  const { bookingroom, bookingroomform_id, itemData, form, addConsoToPush, removeConsoToPush } = props
  const t = useTranslate();

  const { selectProps: consoSelectProps, queryResult: consoQueryResult } = useSelect<IConso>({
    resource: "products/consos",
    optionValue: "id",
    optionLabel: "name",
    debounce: 50,
    fetchSize: 100,
    filters: [
      { field: "rooms", operator: "eq", value: bookingroom?.room.id },
      { field: "status_internal", operator: "eq", value: 1 }
    ],
    onSearch: (value) => [
      { field: "search", operator: "eq", value, }
    ],
  });

  const checkConso = (id: number) => {
    const conso = consoQueryResult?.data?.data.find((e: IConso) => id == Number(e?.id))
    if (conso) {
      addConsoToPush(conso, Number(bookingroom?.id), Number(bookingroom?.room_id), bookingroomform_id)
    }
  }

  return (
    <div>
      <Title level={4}>{`${bookingroom?.room?.name}`}</Title>

      <div className="ant-row ant-form-item">
        <div className="ant-col ant-form-item-label">
          <Text>{t("Ajouter conso:")}</Text>
        </div>
        <div className="ant-col ant-form-item-control">
          <Select allowClear onChange={(_, option: any) => checkConso(option?.value)} {...consoSelectProps} />
        </div>
      </div>
      <>
        {itemData.filter((item: ItemObjProps) => Number(bookingroom?.room?.id) == item?.room_id).map((item: ItemObjProps, id: number) => {
          return (
            <ConsoRow item={item} bookingroomform_id={bookingroomform_id} consobookingroomform_id={id} bookingroom={bookingroom} room_id={bookingroom?.room?.id} key={`conso_consobookings_${bookingroomform_id}_${id}`} removeConsoToPush={removeConsoToPush} />)
        })}
      </>
    </div>
  )
}


interface ConsoRowProps extends IResourceComponentsProps {
  item: ItemObjProps;
  room_id: any;
  bookingroomform_id: any;
  consobookingroomform_id: number;
  bookingroom: IBookingRoom;
  removeConsoToPush: (item: ItemObjProps, bookingroom_id: number, room_id: number, bookingroomform_id: number) => void;
}


const ConsoRow: React.FC<ConsoRowProps> = ({ item, bookingroom, room_id, bookingroomform_id, consobookingroomform_id, removeConsoToPush }) => {

  return (<React.Fragment>
    <div style={{ fontWeight: 700, paddingBottom: '1rem', paddingTop: '1rem' }}>{item?.name}</div>
    <Space wrap>

      <Form.Item name={["bookingrooms", bookingroomform_id, "consobookingrooms", consobookingroomform_id, "conso_id"]} hidden >
        <Input />
      </Form.Item>

      <Form.Item name={["bookingrooms", bookingroomform_id, "consobookingrooms", consobookingroomform_id, "number"]} >
        <InputPlusMinus buttons integerOnly={true} min={1} />
      </Form.Item>

      <Form.Item name={["bookingrooms", bookingroomform_id, "consobookingrooms", consobookingroomform_id, "custom_unit_price"]} >
        <InputNumber controls={false} min={0} addonAfter={'€'} style={{ maxWidth: "7rem" }} />
      </Form.Item>

      <Form.Item>
        <Button icon={<DeleteOutlined />} style={{ backgroundColor: "red", color: "white" }} onClick={() => removeConsoToPush(item, Number(bookingroom?.id), room_id, bookingroomform_id)} />
      </Form.Item>

      <br></br>
    </Space>
  </React.Fragment>)
}
