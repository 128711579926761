import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  AntdLayout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  notificationProvider,
  Space,
  useForm,
  Progress,
} from "@pankod/refine-antd";
// import "./styles.css";

import { useLogin, useNotification, useTranslate } from "@pankod/refine-core";

import { CSSProperties } from "react";
import { LockOutlined, SendOutlined } from "@ant-design/icons";

import { authProvider } from "../../authProvider"

const { Text, Title } = Typography;

interface IOtpForm {
  username: string;
  password: string;
}

interface ILoginForm extends IOtpForm {
  // username: string;
  // password: string;
  otpcode: string;
}



export const LoginPage: React.FC = () => {

  const t = useTranslate();
  const graceTimeOtp = 120

  const [formOtp] = Form.useForm<ILoginForm>();
  const [formLogin] = Form.useForm<ILoginForm>();
  const { mutate: login } = useLogin<ILoginForm>();

  const [otpIsSending, setotpIsSending] = useState(false)
  const [timeOtpIsSended, setTimeOtpIsSended] = useState(0);
  const [isSend, setIsSend] = useState(false)
  const { open } = useNotification();


  const CardTitle = (
    <Title level={5} className="title">
      {t("pages.login.title", "title")}
    </Title>
  );

  const sendOtpRequest = useCallback(async () => {
    if (otpIsSending) return
    const username = formOtp.getFieldValue('username')
    const password = formOtp.getFieldValue('password')
    if (username==undefined||password==undefined||username==''||password=='') {
      formLogin.validateFields()
      return
    }
    setotpIsSending(true)
    try {
      const response = await authProvider.otp({
        username: username,
        password: password
      })
      setTimeOtpIsSended(graceTimeOtp)
      setIsSend(true)
      setotpIsSending(false)
    console.log(timeOtpIsSended)

      open?.({
        message: t("pages.login.successOtpMessage", "successOtpMessage"),
        description: t("pages.login.successOtpDescription", "successOtpDescription"),
        type: "success", key: "uid",
      });
    
    } catch (error) {
      setIsSend(false)
      setotpIsSending(false)
      open?.({
        message: t("pages.login.failedMessage", "failedMessage"),
        description: t("pages.login.failedDescription", "failedDescription"),
        type: "error", key: "uid",
      });
    }
  }, [otpIsSending, isSend])

  useEffect(() => {
    let interval: any = null;
    if (isSend) {
      if (timeOtpIsSended !== 0) {
        interval = setInterval(() => {
          setTimeOtpIsSended(timeOtpIsSended => timeOtpIsSended - 1);
        }, 1000);
      } else {
        setIsSend(false)
        setotpIsSending(false)
        setTimeOtpIsSended(graceTimeOtp);
        clearInterval(interval);
      }
    }
    return () => clearInterval(interval);
  }, [!isSend, timeOtpIsSended]);

  const percentBar = () => timeOtpIsSended*100/graceTimeOtp
  const statusBar = () => {
    const percent = percentBar()
    if (percent > 70) {
      return "success"
    } else if (percent > 30) {
      return "normal"
    } else {
      return "exception"
    }
  }



  return (
    <AntdLayout className="layout" style={{backgroundColor: '#626262'}}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh"
        }}
      >
        <Col lg={7} md={10} sm={14} xs={22} style={{paddingBottom: '88px'}}>
          <div className="container">
            <div className="ant-row ant-row-center" style={{marginBottom: '24px'}}>
              <img src="./mihotel.png" alt="MiHotel Logo" />
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }} style={{padding: 10}}>
              <Form
                form={isSend? formLogin: formOtp}
                layout="vertical"
                onFinish={(values) => { isSend? login(values): sendOtpRequest() }}
                requiredMark={false}
                initialValues={{ remember: false }}
                validateMessages={{ required: "" }}
              >
                  <Form.Item
                    name="username"
                    label={t("pages.login.useraccount", "useraccount")}
                    rules={[{ required: true }]}
                  >
                    <Input autoFocus placeholder={t("pages.login.mail", "mail")} disabled={isSend} autoComplete="off" size="large"/>
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label={t("pages.login.password", "password")}
                    rules={[{ required: true }]}
                  >
                    <Input type="password" placeholder="●●●●●●●●" disabled={isSend} autoComplete="off" size="large" />
                  </Form.Item>
                  {isSend &&
                    <Form.Item
                      name="otpcode"
                      label={t("pages.login.otpcode", "otpcode")}
                      rules={[{ required: true }]}
                      style={{ marginBottom: "36px" }}
                      hidden={!isSend}
                    >
                      <Input autoFocus size="large" placeholder="●●●●●●" autoComplete="off"/>
                    </Form.Item>
                  }

                  {isSend && <Progress percent={percentBar()} status={statusBar()} format={() => <Text style={{fontSize: '80%', lineHeight: 2}}>{timeOtpIsSended}s</Text>} style={{ marginBottom: "12px" }}/>}
                  {isSend && <Button type="primary" size="large" htmlType="submit" block>{t("pages.login.signin", "signin")}</Button>}
                  {!isSend && <Button type="primary" size="large" htmlType="submit" loading={otpIsSending} icon={<LockOutlined />} block>{t("pages.login.sendotpcode", "sendotpcode")}</Button>}
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
