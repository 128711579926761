import React, { useState } from "react";
import { useTranslate, IResourceComponentsProps, file2Base64 } from "@pankod/refine-core";
import { EuroCircleOutlined, PlusOutlined, DeleteOutlined, CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Col, Form, Input, Row, Upload, getValueFromEvent, Select, Switch, Typography, useSelect, Rate, Button, FormInstance, FormProps, TimePicker, Tabs, UploadFile, Image, Space } from "@pankod/refine-antd";
import { IPartnerCategory, IPartnerCategorytext, IPartnerSchedule, IPartnertext, IRoom, IThematictext } from "interfaces";
import IntlTelInput from "react-intl-tel-input";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Title, Text } = Typography;

interface FunctProps extends IResourceComponentsProps {
    formProps: FormProps<{}>;
    form: FormInstance<{}>;
    queryResult: any;
    formLoading: boolean;
}


export const ThematicForm: React.FC<FunctProps> = ({ form, formLoading, formProps, queryResult }) => {
    const t = useTranslate();
    const col_center_half_xs = { span: 24, offset: 0 }
    const col_center_full_lg = { span: 12, offset: 6 }

    return (

        <Tabs defaultActiveKey="1" centered>
            <Tabs.TabPane forceRender tab={`${t('partner.infos')}`} key="1">
                <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                    {!formLoading && <ThematicInfoForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />}
                </Col>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender tab={`${t('rooms.texts.app.translate')}`} key="2">
                <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                    {!formLoading && <LangTextThematicForm form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />}
                </Col>
            </Tabs.TabPane>
            {/* <Tabs.TabPane forceRender tab={`${t('partner.picture')}s`} key="3">
                <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                    {!formLoading && <PartnerPictureForms form={form} queryResult={queryResult} formLoading={formLoading} formProps={formProps} />}
                </Col>
            </Tabs.TabPane>
            <Tabs.TabPane forceRender tab={`${t('partner.opening_hours')}s`} key="4">
                <Col xs={col_center_half_xs} lg={col_center_full_lg} >
                    {!formLoading && <PartnerSchedulesForm form={form} queryResult={queryResult} formProps={formProps} />}
                </Col>
            </Tabs.TabPane> */}
        </Tabs>
    );
}

export const ThematicInfoForm: React.FC<FunctProps> = (props) => {
    const [phoneError, setPhoneError] = useState<boolean>(false)

    const t = useTranslate();
    const { formProps, queryResult } = props

    const { selectProps: roomSelectProps, queryResult: roomQueryResult } = useSelect<IRoom>({
        resource: 'rooms',
        optionValue: "id",
        optionLabel: "name",
        debounce: 100,
        fetchSize: 100,
    });

    if (roomSelectProps?.loading == true || roomQueryResult?.data?.data === undefined) {
        return (<></>)
    }

    const iiselected = queryResult?.data?.data?.rooms?.map((room: IRoom) => ({ value: room.id, label: undefined })) as unknown as { value: string; label: string; }

    return (
        <Form {...props.formProps} layout="vertical">
            <Form.Item
                label={t("rooms.name_short")}
                name={"name"}
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("fa_icon")}
                name="fa_icon"
                rules={[{ required: true }]}
            >
                <Input addonAfter={<FontAwesomeIcon icon={queryResult?.data?.data?.fa_icon} />} />
            </Form.Item>
            <Form.Item
                label={t("room")}
                name={["rooms"]}
                getValueFromEvent={(values,) => {
                    return values?.map((id: number) => ({ id }));
                }}
                getValueProps={(values: any) => {
                    return values
                }}
            >
                <Select
                    {...roomSelectProps}
                    mode="multiple"
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    autoClearSearchValue
                    defaultValue={iiselected}
                />
            </Form.Item>
        </Form>
    );
};

export const LangTextThematicForm: React.FC<FunctProps> = (props) => {
    const t = useTranslate();
    const { formProps, form, queryResult, formLoading } = props
    const texts: IPartnertext[] = queryResult?.data?.data?.texts;
    const defaultTexts: IPartnertext[] = [
        { "name": "", "description": "", "lang": { "id": '1', 'short_name': 'FR' } },
        { "name": "", "description": "", "lang": { "id": '2', 'short_name': 'EN' } }
    ];

    const entitytexts = (texts) ? texts : defaultTexts;

    return (
        <Form {...formProps} layout="vertical">
            {entitytexts?.map((entitytexts, id) => <TextPartnerForm id={id} entitytexts={entitytexts} key={`TextPartnerForm_${id}`} />)}
        </Form>
    )
}

interface TextPartnerFormProps extends IResourceComponentsProps {
    entitytexts: IThematictext;
    id: any;
}


const TextPartnerForm: React.FC<TextPartnerFormProps> = (props) => {
    const t = useTranslate();
    const { entitytexts, id } = props

    return (
        <React.Fragment>
            <Title level={4}>{`${t('rooms.texts.app.translate')} ${entitytexts?.lang?.short_name}`}</Title>

            <Form.Item name={['texts', id, 'lang', 'id']} initialValue={entitytexts.lang.id} hidden>
                <Input />
            </Form.Item>

            <Form.Item label={t("partner.name")} name={['texts', id, 'name']} rules={[{ max: 50 }]} required>
                <Input />
            </Form.Item>

            <Form.Item hidden name={['texts', id, 'id']} />
        </React.Fragment>
    )
}

