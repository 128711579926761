import { Create, Form, Input, Select, useSelect, DatePicker, Switch, useStepsForm, useForm, Button, SaveButton, Steps, Space, Row, Col, Card, Collapse, Tooltip, Typography, Tag } from "@pankod/refine-antd";
import { DeleteOutlined, ExclamationCircleFilled, MoreOutlined, PercentageOutlined, SyncOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslate, IResourceComponentsProps, useShow, useOne, useCustom, useApiUrl, CrudFilters } from "@pankod/refine-core";
import { useSearchParams } from 'react-router-dom';
import { IBooking, IBookingRoomPrice, IClient, IExtra, IRoom, ITaxeSejour } from "interfaces";
import { InputPlusMinus } from "components/inputs/inputplusminus";
import { useEffect, useState } from "react";
import { List, Modal } from 'antd'
import dayjs from "dayjs";
import React from "react";
import { useAuthenticatedRequest } from "core/useAuthenticatedRequest";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

const { Text, Link } = Typography;
const { TextArea } = Input;

interface IAddRoomExtraFormProps {
  room: any;
  bookDates: any;
  itemData: any;
  setItemData: (value: any) => void;
}

interface IAddRoomFormProps {
  bookDates: any;
  itemData: any;
  is_cancelinsurrance: boolean;
  ignore_block: boolean;
  setItemData: (value: any) => void;
}

export const BookingCreate: React.FC<IResourceComponentsProps> = (props) => {
  const [searchParams] = useSearchParams();
  const bookingForm = !searchParams.get('block');

  if (bookingForm) {
    return <BookCreate />
  }
  return <BlockCreate />
}

const BlockCreate: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();

  const { formProps, saveButtonProps, form } = useForm<IBooking>();
  const { sendRequest } = useAuthenticatedRequest();
  const [bookDates, setBookDates] = useState<any>({ start: '', end: '', days: [] })
  const [isNight, setIsNight] = useState<boolean>(true)
  const [defaultHours, setDefaultHours] = useState<any>([dayjs('17:00', 'HH:mm'), dayjs('12:00', 'HH:mm').add(1, 'day')])

  const switchNightDay = (checked: boolean) => {
    if (checked) {
      setDefaultHours([dayjs('17:00', 'HH:mm'), dayjs('12:00', 'HH:mm')])
      form.setFieldsValue({ 'dates': [dayjs('17:00', 'HH:mm'), dayjs('12:00', 'HH:mm').add(1, 'day')] });
      setIsNight(true)
    } else {
      setDefaultHours([dayjs('12:10', 'HH:mm'), dayjs('16:50', 'HH:mm')])
      form.setFieldsValue({ 'dates': [dayjs('12:10', 'HH:mm'), dayjs('16:50', 'HH:mm')] });
      setIsNight(false)
    }
  }

  const rangeChange = (a: any) => {
    setBookDates({ start: a[0], end: a[1], days: getDaysArray(a[0], a[1]) })
  }

  return (
    <Create saveButtonProps={saveButtonProps}>

      <Form {...formProps} layout="vertical">
        <Form.Item label={t("pages.booking.shortlist")} >
          <Switch
            checkedChildren={t("pages.booking.night")}
            unCheckedChildren={t("pages.booking.day")}
            defaultChecked
            onChange={switchNightDay}
          />
        </Form.Item>

        <DtRange defaultHours={defaultHours} />

        <SelectRoom bookDates={bookDates} />

        <Form.Item name="custom_request" label={t('comment')}>
          <TextArea rows={4} />
        </Form.Item>

        {/* {(bookDates.start != '' && bookDates.end != '' ) && <SelectRoom bookDates={bookDates}/>} */}
      </Form>
    </Create>
  )
}

interface IDtRangeProps extends IResourceComponentsProps {
  defaultHours: any;
}

const DtRange: React.FC<IDtRangeProps> = (props) => {
  const t = useTranslate();
  const { defaultHours } = props

  return (
    <Form.Item
      label={t("pages.booking.dates")}
      name={"dates"}
      rules={[{ required: true }]}
      initialValue={defaultHours}
    >
      <DatePicker.RangePicker
        showTime={{
          defaultValue: defaultHours,
          hideDisabledOptions: true,
        }}
        format="DD/MM/YYYY HH:mm"
        allowClear={false}
        monthCellRender={(curr) => dayjs(curr).locale("fr").format("MMM")} />
    </Form.Item>
  )
}
interface ISelectRoomProps extends IResourceComponentsProps {
  bookDates: any;
}

const SelectRoom: React.FC<ISelectRoomProps> = (props) => {
  const t = useTranslate();

  const { bookDates } = props
  let filters: any[] = []
  // if (bookDates?.start != '' && bookDates?.end != '') {
  //   if (dayjs(bookDates?.start).set('hour', 0).set('minute', 0).set('second', 0).format() == dayjs(bookDates?.end).set('hour', 0).set('minute', 0).set('second', 0).format()) {
  //     filters = filters.concat([{ field: 'date_start', operator: "eq", value: dayjs(bookDates?.start).format('YYYY-MM-DDT13:00:00') }])
  //     filters = filters.concat([{ field: 'date_end', operator: "eq", value: dayjs(bookDates?.end).format('YYYY-MM-DDT16:00:00') }])
  //   } else {
  //     filters = filters.concat([{ field: 'date_start', operator: "eq", value: dayjs(bookDates?.start).format('YYYY-MM-DDT18:00:00') }])
  //     filters = filters.concat([{ field: 'date_end', operator: "eq", value: dayjs(bookDates?.end).format('YYYY-MM-DDT10:00:00') }])
  //   }
  // }

  const { selectProps: roomSelectProps, queryResult: roomQueryResult } = useSelect<IRoom>({
    resource: 'rooms',
    optionValue: "id",
    optionLabel: "name",
    debounce: 100,
    fetchSize: 100,
    // filters: filters,
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value,
      },
    ],
  });

  const options = groupByAddress(roomQueryResult?.data?.data);

  return (
    <Form.Item
      label={`${t('menu.rooms')}: ${options?.reduce((cCount, row) => cCount + row?.options.reduce((c: any) => c + 1, 0), 0)}`}
      name={["room", "id"]}
      rules={[{required: true}]}
    >
      <Select
        listHeight={1000}
        {...roomSelectProps}
        options={options}
        // allowClear
        
      />
    </Form.Item>

  )
}

export const BookCreate: React.FC<IResourceComponentsProps> = (props) => {
  const t = useTranslate();

  const { current, gotoStep, stepsProps, formProps, saveButtonProps, form } = useStepsForm<IBooking>();
  const { sendRequest } = useAuthenticatedRequest();
  const [bookDates, setBookDates] = useState<any>({ start: '', end: '', days: [] })
  const [is_cancelinsurrance, setIs_cancelinsurrance] = useState<boolean>(false)
  const [ignore_block, setIgnore_block] = useState<boolean>(false)

  const [searchParams] = useSearchParams();
  const client_id = searchParams.get(t('menu.customers'));

  const [pay_method, setPay_method] = useState<string>("")
  const [is_exigent, setIs_exigent] = useState<boolean>(false)
  const [camefrom, setCamefrom] = useState<string>("")

  const client_preselect = client_id ? ([
    {
      field: 'id',
      operator: 'eq',
      value: client_id
    }
  ]) as CrudFilters : undefined

  const { selectProps: customerSelectProps, queryResult: customerQueryResult } = useSelect<IClient>({
    resource: "clients",
    optionValue: "id",
    optionLabel: "quick_desc",
    debounce: 50,
    fetchSize: 100,
    filters: client_preselect,
    onSearch: (value) => [
      {
        field: "search",
        operator: "eq",
        value,
      },
    ],
  });

  const rangeChange = (a: any) => {
    setBookDates({ start: a[0], end: a[1], days: getDaysArray(a[0], a[1]) })
    setItemData([])
  }

  const [itemData, setItemData] = useState<any>([])

  console.log(itemData)
  const submit = async () => {
    await sendRequest({
      url: `bookings/`,
      method: 'post',
      data: itemData,
    });
    // THEN
    // window.open(landing_pay_url, "_blank", 'noopener,noreferrer');
  };

  const removeRoom = (id: any) => {
    confirm({
      title: t("modal.delete_confirmation"),
      icon: <ExclamationCircleFilled />,
      content: (itemData.find((room: any) => room.id === id))?.name,
      onOk() {
        const itemList = itemData.filter((e: any) => {
          return e.id != id;
        })
        setItemData([...itemList]);
      },
      onCancel() {
        return
      },
    });
  }

  const removeExtra = (roomId: number, id: any) => {
    const room = itemData.find((room: any) => room.id != id)
    confirm({
      title: t("modal.delete_confirmation"),
      icon: <ExclamationCircleFilled />,
      content: (room.extras?.find((extra: any) => extra.id === id))?.name,
      onOk() {
        const newState = itemData.map((room: any) => {
          return (room.id == roomId) ? { ...room, extras: room.extras?.filter((extra: any) => extra.id != id) } : room;
        })
        setItemData(newState);
      },
      onCancel() {
        return
      },
    });
  }
  // console.log(itemData)

  const formList = [
    <>
      <Form.Item
        label={t("pages.booking.dates_sejour")}
        name={["dates"]}
        rules={[{ required: true }]}
      >
        <DatePicker.RangePicker allowClear={false} format={t("short_date")} onChange={rangeChange} monthCellRender={(curr) => dayjs(curr).locale("fr").format("MMM")} />
      </Form.Item>
      {/* <Form.Item
        label={t("pages.booking.day_use")}
        name={"is_dayuse"}
        initialValue={false}
        valuePropName='checked'

      >
        <Switch />
      </Form.Item> */}
    </>,
    <>
      <Form.Item
        label={t("pages.booking.is_insurance")}
        name={"is_cancelinsurrance"}
        initialValue={false}
        valuePropName='checked'

      >
        <Switch onChange={setIs_cancelinsurrance} />
      </Form.Item>
      <Form.Item
        label={t("pages.booking.with_blocked_room")}
        name={"blocked"}
        initialValue={false}
        valuePropName='checked'

      >
        <Switch onChange={setIgnore_block} />
      </Form.Item>

      {bookDates.start && bookDates.end && <AddRoomForm bookDates={bookDates} itemData={itemData} setItemData={setItemData} is_cancelinsurrance={is_cancelinsurrance} ignore_block={ignore_block} />}

    </>,
    <>
      <div>

      </div>
      {itemData.map((item: any) => {
        return (<AddRoomExtraForm room={item} bookDates={bookDates} itemData={itemData} setItemData={setItemData} key={`AddRoomExtraForm_${item?.id}`} />)
      })}
    </>,
    <>
      <Form.Item
        label="Client"
        name={["client", "id"]}
        rules={[{ required: true }]}
        initialValue={client_preselect ? Number(client_preselect?.[0].value) : undefined}
      >
        <Select allowClear
          {...customerSelectProps} />

      </Form.Item>
      <Form.Item
        label={t("priocontrol")}
        name={"is_priocontrol"}
        initialValue={false}
        valuePropName='checked'
      >
        <Switch onChange={(v) => { setIs_exigent(v) }} />
      </Form.Item>
      <Form.Item label={t("camefrom")} name="camefrom" required>
        <Select
          placeholder={t("camefrom")}
          onChange={(v) => { setCamefrom(`${v}`) }}
        >
          <Select.Option value={"BOOKING"} label={"BOOKING"} >BOOKING</Select.Option>
          <Select.Option value={"DIRECT_TEL"} label={"DIRECT_TEL"} >DIRECT_TEL</Select.Option>
          <Select.Option value={"EXPEDIA"} label={"EXPEDIA"} >EXPEDIA</Select.Option>
          <Select.Option value={"OTHER"} label={"OTHER"} >OTHER</Select.Option>
        </Select>
      </Form.Item>
      {/* <Form.Item label={t("raison_sejour")} name="raison_sejour" required>
        <Select
          placeholder={"LOISIR/BUSINESS"}
          onChange={(v) => { setRaison_sejour(`${v}`) }}
        >
          <Select.Option value={"LOISIR"} label={"LOISIR"} >LOISIR</Select.Option>
          <Select.Option value={"BUSINESS"} label={"BUSINESS"} >BUSINESS</Select.Option>
        </Select>
      </Form.Item> */}
      <Form.Item label={t("pay_method")} name="pay_method" required>
        <Select
          placeholder={"CB/SEPA/Etc"}

          onChange={(v) => { setPay_method(`${v}`) }}
        >
          <Select.Option value={"CB"} label={"CB"} >CB</Select.Option>
          <Select.Option value={"Espece"} label={"Espece"} >Espece</Select.Option>
          <Select.Option value={"Cheque"} label={"Cheque"} >Cheque</Select.Option>
          <Select.Option value={"Virement"} label={"Virement"} >Virement</Select.Option>
        </Select>
      </Form.Item>

    </>,
  ];

  let gndTot = itemData?.reduce((acc: number, room: any) => acc + room?.hebPrices?.reduce((acc: number, hebp: any) => acc + hebp?.hostingPrice + hebp?.touristTax, 0), 0)
  gndTot += itemData?.reduce((acc: number, room: any) => acc + room?.extras?.reduce((acc: number, extra: any) => acc + extra.unit_price * extra.days?.reduce(((count: number, day: { qtt: number; }) => count + Number(day.qtt)), 0), 0), 0)


  return (
    <Create
      actionButtons={
        <>
          {current > 0 && (
            <Button onClick={() => { gotoStep(current - 1) }} >
              Previous
            </Button>
          )}
          {current < formList.length - 1 && (
            <Button onClick={() => { gotoStep(current + 1) }} >
              Next
            </Button>
          )}
          {current === formList.length - 1 && (

            <SaveButton onClick={() => {
              form.validateFields().then((value) => {

                console.log(`value: `, value)
                submit().then((value) => {
                  console.log(`value submit: `, value)
                }).catch((err: any) => {
                  console.log(`err submit: `, err)
                })

              }).catch((err: any) => {
                console.log(`err: `, err)
              })
              // form.validateFields
              // console.log(ttb)
            }} />
          )}
        </>
      }
    >
      <Steps {...stepsProps}>
        <Steps.Step title={t("pages.booking.dates_sejour")} />
        <Steps.Step title={t("menu.rooms")} />
        <Steps.Step title={t("menu.extras")} />
        <Steps.Step title={t("pages.booking.register")} />
      </Steps>
      <Row>
        <Col xs={24} sm={24} md={24} lg={18} xl={14}>
          <Form {...formProps} form={form} layout="vertical" style={{ marginTop: 30 }}>
            {formList[current]}
          </Form>
        </Col>
        <Col xs={24} sm={24} md={24} lg={1} xl={2}></Col>
        <Col xs={24} sm={24} md={24} lg={5} xl={6}>
          <br />
          {/* <Card key={"card_01_booking"} title={t("pages.booking.title")} style={{ backgroundColor: "#EFEFEF", width: "max-content", maxWidth: "500px", minWidth: "400px", padding: '10px', margin: "0px auto 10px auto" }}>
            {bookDates.start && <>{dayjs(bookDates.start).locale(t("short_lang")).format('LL')}</>} - {bookDates.end && <>{dayjs(bookDates.end).locale(t("short_lang")).format('LL')}</>}<br /><br />
            {itemData.map((item: any) =>
              <div key={`room_${item.id}`}>
                {'\xa0'.repeat(0)}- {item.name} {item.guests_no} <UserOutlined style={{ color: "green" }} /> {item?.hebPrices?.reduce((acc:number, hebp:any) => acc + hebp?.hostingPrice + hebp?.touristTax, 0).toFixed(2)}€ <DeleteOutlined style={{ color: "red" }} onClick={() => removeRoom(item.id)} />
                {item.extras?.map((extra: any) => 
                  <div key={`extra_room_${item.id}_${extra.id}`}>
                    {'\xa0'.repeat(5)}- {`${extra.days?.reduce(((count: number, day: { qtt: number; }) => count + Number(day.qtt)), 0)}x ${extra.name} ${(extra.unit_price*extra.days?.reduce(((count: number, day: { qtt: number; }) => count + Number(day.qtt)), 0)).toFixed(2)}€`} <DeleteOutlined style={{ color: "red" }} onClick={() => removeExtra(item.id, extra.id)} />
                    {extra?.days?.map((days:any) => {
                      if (days?.qtt) {
                        return (<div key={`${days?.day}-${days?.qtt}`}>
                          {'\xa0'.repeat(10)}- {`${dayjs(days?.day).locale(t("short_lang")).format('DD/MM')}: ${days?.qtt}`}
                        </div>)
                      }
                      return (<></>)
                    })
                    }
                  </div>)}
              </div>)}
              <br />
              <div style={{fontWeight: "bold"}}>{`${gndTot.toFixed(2)}€`}</div>
          </Card> */}
          <RecapCard bookDates={bookDates} itemData={itemData} removeRoom={removeRoom} removeExtra={removeExtra} />
        </Col>
      </Row>
    </Create>
  )
};

const AddRoomExtraForm = (props: IAddRoomExtraFormProps) => {
  const { room, bookDates, itemData, setItemData } = props

  const t = useTranslate();
  const [optionIsShow, setOptionIsShow] = useState<boolean>(false)
  const extraInputsRef = React.useRef<any>();

  const addExtraToPush = (id: any, item: any, roomId: any) => {
    const itemRoom = itemData.find((e: any) => e.id == roomId)
    console.log(item)
    if (!item) {
      itemRoom.extra_push = undefined
      setItemData([
        ...itemData,
      ]);
      return
    }
    const extra = extraQueryResult?.data?.data.find((e: any) => item.value == e.id)
    if (extra) {
      itemRoom.extra_push = { id: extra?.id, name: extra?.name, nb: 1, unit_price: Number(extra?.price) }
      setItemData([
        ...itemData,
      ]);
    }
  }

  const addExtra = (newExtra: any, roomId: any) => {
    let itemRoom = itemData.find((room: any) => room.id == roomId)

    if (!newExtra) {
      return
    }
    if (newExtra.days?.reduce(((count: number, day: { qtt: number; }) => count + Number(day.qtt)), 0) === 0) {
      return
    }
    if (itemRoom.extras.find((extra: any) => extra.id === newExtra.id)) {
      return
    }
    itemRoom.extras = [...itemRoom.extras, newExtra]
    setItemData([
      ...itemData,
    ]);
  }

  const { selectProps: extraSelectProps, queryResult: extraQueryResult } = useSelect<IExtra>({
    resource: "products/extras",
    optionValue: "id",
    optionLabel: "full_desc",
    debounce: 50,
    fetchSize: 100,
    filters: [
      { field: "rooms", operator: "eq", value: room.id },
      { field: "status_internal", operator: "eq", value: 1 }
    ],
    onSearch: (value) => [
      { field: "search", operator: "eq", value, }
    ],
  });

  return (
    <div key={`room_id_${room.id}_extra`}>
      <div style={{ fontWeight: 700, paddingBottom: '1rem', paddingTop: '1rem' }}>{room?.name}</div>
      <Form.Item
        label={<div style={{ fontWeight: 400 }}>{t("Ajouter extra:")}</div>}
        name={["room", "id", room.id, "extra", "id"]}
      >
        <Select allowClear onChange={(value: any, option: any) => addExtraToPush(value, option, room.id)} {...extraSelectProps} />
      </Form.Item>
      {room.extra_push &&
        <Space wrap>
          <ExtraInputs key={`room_id_${room.id}_extra_${room.extra_push.id}`} bookDates={bookDates} item={room} t={t} ref={extraInputsRef} />
          {optionIsShow && <>
            <Space wrap>
              <div style={{ color: "transparent" }}>.</div>
              <InputPlusMinus
                min={0} max={100} key={`room_id_${room.id}_extra_push_${room.extra_push.id}_input_unit_price_percent_reduction`}
                label={<>{t("%reduction")}</>} initialValue={0} onChange={(e: number) => extraInputsRef?.current.setReduction(e)}
              />
            </Space>
          </>}
          <div>
            <div style={{ color: "transparent" }}>.</div>
            <Button icon={<MoreOutlined />} onClick={() => setOptionIsShow(!optionIsShow)}>
            </Button>
          </div>
          <div>
            <div style={{ color: "transparent" }}>.</div>
            <Button style={{ backgroundColor: "green", color: "white" }} onClick={() => { addExtra(extraInputsRef?.current?.addExtra(), room.id) }}>
              +
            </Button>
          </div>

        </Space>}

    </div>
  )
}

const AddRoomForm = (props: IAddRoomFormProps) => {
  const { bookDates, itemData, is_cancelinsurrance, ignore_block, setItemData } = props

  const t = useTranslate();
  const [room_id, setRoom_id] = useState<string>("")
  const [pricezone_id, setPricezone_id] = useState<string>("")
  const [roomtype_id, setroomType_id] = useState<string>("")
  const [capacity_max, setCapacity_max] = useState<string>("2")
  const [equipments_id, setEquipments_id] = useState<string>("")

  let filters: any[] = []
  if (pricezone_id != 'undefined' && pricezone_id != '') {
    filters = filters.concat({ field: 'pricezone_id', operator: "in", value: pricezone_id })
  }
  if (is_cancelinsurrance) {
    filters = filters.concat({ field: 'is_cancelinsurrance', operator: "eq", value: is_cancelinsurrance })
  }
  if (ignore_block) {
    filters = filters.concat({ field: 'ignore_block', operator: "eq", value: ignore_block })
  }
  if (capacity_max != 'undefined' && capacity_max != '') {
    filters = filters.concat({ field: 'capacity_max', operator: "gte", value: capacity_max })
  }
  if (roomtype_id != 'undefined' && roomtype_id != '') {
    filters = filters.concat({ field: 'roomtypes__id', operator: "in", value: roomtype_id })
  }
  if (equipments_id != 'undefined' && equipments_id) {
    filters = filters.concat(equipments_id.split(',').map((id) => { return { field: 'equipments', operator: "eq", value: id } }))
  }
  if (bookDates?.start != '' && bookDates?.end != '') {
    if (dayjs(bookDates?.start).set('hour', 0).set('minute', 0).set('second', 0).format() == dayjs(bookDates?.end).set('hour', 0).set('minute', 0).set('second', 0).format()) {
      filters = filters.concat([{ field: 'date_start', operator: "eq", value: dayjs(bookDates?.start).format('YYYY-MM-DDT13:00:00') }])
      filters = filters.concat([{ field: 'date_end', operator: "eq", value: dayjs(bookDates?.end).format('YYYY-MM-DDT16:00:00') }])
    } else {
      filters = filters.concat([{ field: 'date_start', operator: "eq", value: dayjs(bookDates?.start).format('YYYY-MM-DDT18:00:00') }])
      filters = filters.concat([{ field: 'date_end', operator: "eq", value: dayjs(bookDates?.end).format('YYYY-MM-DDT10:00:00') }])
    }
  }



  const { selectProps: pricezoneSelectProps, queryResult: pricezoneQueryResult } = useSelect<IBooking>({
    resource: "pricezones",
    optionValue: "id",
    optionLabel: "name",
    debounce: 50,
    fetchSize: 100,
  });

  const { selectProps: equipmentSelectProps, queryResult: equipmentQueryResult } = useSelect<IBooking>({
    resource: "equipments",
    optionValue: "id",
    optionLabel: "label",
    debounce: 50,
    fetchSize: 100,
    filters: [{
      field: 'is_in_filter',
      operator: 'eq',
      value: true
    }]
  });

  const { selectProps: roomtypeSelectProps, queryResult: roomtypeQueryResult } = useSelect<IBooking>({
    resource: "roomtypes",
    optionValue: "id",
    optionLabel: "name",
    debounce: 50,
    fetchSize: 100,
  });

  const { selectProps: roomSelectProps, queryResult: roomQueryResult } = useSelect<IRoom>({
    resource: 'rooms/availablilities',
    optionValue: "id",
    optionLabel: "name",
    debounce: 100,
    fetchSize: 100,
    filters: filters,
    onSearch: (value) => [
      {
        field: "name",
        operator: "contains",
        value,
      },
    ],
  });

  const options = groupByAddress(roomQueryResult?.data?.data);


  const { data: roomData } = useOne<IRoom>({
    id: room_id || "",
    resource: "rooms",
    queryOptions: {
      enabled: room_id != "",
    },

  });

  const addRoom = (id: any, item: any) => {
    setRoom_id(item.value)
  }
  useEffect(() => {
    if (roomData != undefined && !itemData.find((e: any) => e.id == roomData?.data.id)) {
      setItemData([
        ...itemData,
        { id: roomData?.data.id, value: roomData?.data.id, name: roomData?.data?.name_short, label: roomData?.data?.name_short, capacity_max: roomData?.data.capacity_max, guests_no: capacity_max, extra_push: undefined, extras: [], hebPrices: [] }
      ]);
    }
  }, [roomData]);

  const roomUpdateGuestsNo = (roomId: number, guests_no: any) => {
    const newState = itemData.map((room: any) => {
      room.guests_no = (room.id == roomId) ? guests_no : room.guests_no
      return room
    })
    setItemData(newState);
  }


  return (
    <>
      <div>
        <Space wrap>
          <Form.Item label={t("menu.roomtypes")} name="roomtype_filter">
            <Select
              {...roomtypeSelectProps}
              mode="multiple"
              allowClear

              placeholder={t("menu.roomtypes")}
              onChange={(v) => { setroomType_id(`${v}`) }}
              style={{ minWidth: '175px' }}
            />
          </Form.Item>
          <Form.Item label={t("menu.equipments")} name="equipment_filter">
            <Select
              {...equipmentSelectProps}
              mode="multiple"
              allowClear
              placeholder={t("menu.equipments")}
              onChange={(v) => { setEquipments_id(`${v}`) }}
              style={{ minWidth: '175px' }}
            />
          </Form.Item>
          <Form.Item label={t("menu.pricezones")} name="pricezone_filter">
            <Select
              {...pricezoneSelectProps}
              mode="multiple"
              allowClear
              placeholder={t("menu.pricezones")}
              onChange={(v) => { setPricezone_id(`${v}`) }}
              style={{ minWidth: '175px' }}
            />
          </Form.Item>
          <Form.Item initialValue={2} label={t("pages.booking.nb_guests")} name="capacity_max_filter">
            <Select
              allowClear
              placeholder={t("pages.booking.nb_guests")}
              onChange={(v) => { setCapacity_max(`${v}`) }}
            >
              <Select.Option value={1} label={1} >1</Select.Option>
              <Select.Option value={2} label={2} >2</Select.Option>
              <Select.Option value={3} label={3} >3</Select.Option>
              <Select.Option value={4} label={4} >4</Select.Option>
              <Select.Option value={5} label={5} >5</Select.Option>
            </Select>
          </Form.Item>
        </Space>
      </div>
      <Form.Item
        label={`${t('pages.booking.availablerooms')}: ${options?.reduce((cCount, row) => cCount + row?.options.reduce((c: any) => c + 1, 0), 0)}`}
        name={["room", "id"]}
      >
        <Select
          listHeight={1000}
          onChange={addRoom}

          {...roomSelectProps}
          options={options}
          allowClear
        />
      </Form.Item>
      <List
        size="large"
        bordered
        dataSource={itemData}
        renderItem={(item: any, index: number) => {
          // console.log(itemData.find((room: any) => room.id == item.id? true: false)?.guests_no)

          return (
            <List.Item
              actions={[
                <InputPlusMinus buttons value={itemData.find((room: any) => room.id == item.id ? true : false)?.guests_no} label={t("pages.booking.nb_guests")} min={1} max={item.capacity_max} key={`room_id_${item.id}_guest_nb__`} onChange={(e: any) => { roomUpdateGuestsNo(item.id, e); console.log(e) }} />
              ]}
            >
              <List.Item.Meta
                title={<>{item.label}</>}
                description={
                  <Collapse onChange={() => null} ghost>
                    <Collapse.Panel forceRender={true} header={t('pages.booking.daily_price')} key="1">
                      <HebPriceFrom room_id={`${item.id}`} bookDates={bookDates} guests_no={itemData.find((room: any) => room.id == item.id ? true : false)?.guests_no} itemData={itemData} setItemData={setItemData} is_cancelinsurrance={is_cancelinsurrance} />
                    </Collapse.Panel>
                  </Collapse>
                }
              />


            </List.Item>
          )
        }}
      />
    </>
  )
}

interface IHebPriceFormProps {
  room_id: string;
  bookDates: any;
  guests_no: string;
  itemData: any;
  is_cancelinsurrance: boolean;
  setItemData: (value: any) => void;
}

interface IDailyPrice {
  daily_prices: {
    bar: {
      dates: [IBookingRoomPrice],
      pricettc: number;
    },
    nanr: {
      dates: [IBookingRoomPrice],
      pricettc: number;
    }
  }
  taxe_sejour: ITaxeSejour;
}

const getTouristTax = (hostingPriceTtc: number, nbGuest: number, taxeSejour: ITaxeSejour | undefined): number => {
  const max = taxeSejour ? Number(taxeSejour?.data?.max) : 5;
  const percent = taxeSejour ? Number(taxeSejour?.data?.percent) : 0.055;

  const hostingPriceHt = hostingPriceTtc / 1.1;
  const touristTaxUnit =
    (hostingPriceHt / (nbGuest === 0 ? 1 : nbGuest)) * percent;
  const calculatedTaxUnit = Math.min(max, touristTaxUnit);
  console.log(touristTaxUnit)
  const roundedValue = +(
    nbGuest * calculatedTaxUnit
  ).toFixed(2);

  return roundedValue;
};

const HebPriceFrom = (props: IHebPriceFormProps) => {
  const { room_id, bookDates, is_cancelinsurrance, guests_no, itemData, setItemData } = props
  const apiUrl = useApiUrl();
  const t = useTranslate()

  const [dailyPrices, setDailyPrices] = useState<[IBookingRoomPrice] | undefined>(undefined)
  const [initDailyPrices, setInitDailyPrices] = useState<[IBookingRoomPrice] | undefined>(undefined)
  const [optionIsShow, setOptionIsShow] = useState<boolean>(false)
  const { data, isLoading, refetch, isRefetching, isFetching, isFetchedAfterMount } = useCustom<IDailyPrice>({
    url: `${apiUrl}/rooms/${room_id}/daily_prices`,
    method: "get",
    config: {
      query: {
        nb_guest: guests_no,
        date_start: dayjs(bookDates?.start).format('YYYY-MM-DDTHH:mm:ss'),
        date_end: dayjs(bookDates?.end).format('YYYY-MM-DDTHH:mm:ss'),
      }
    },
  });
  const usedDailyPrice = is_cancelinsurrance ? data?.data.daily_prices.bar : data?.data.daily_prices.nanr
  const record = data?.data
  // console.log(record)
  useEffect(() => {
    setDailyPrices(usedDailyPrice?.dates)
    setOptionIsShow(false)
  }, [isLoading, isFetching])

  useEffect(() => {
    if (isFetchedAfterMount) {
      setInitDailyPrices(JSON.parse(JSON.stringify(usedDailyPrice?.dates)))
    }
  }, [isFetchedAfterMount])

  useEffect(() => {
    if (dailyPrices != undefined) {
      const newState = itemData.map((room: any) => {
        room.hebPrices = (room.id == room_id) ? dailyPrices : room.hebPrices
        return room
      })
      setItemData(newState);
    }
  }, [dailyPrices])

  const onChangeHostingPrice = (price: number, dt: any) => {
    const newDailyPrices = dailyPrices?.map((dailyPrice: IBookingRoomPrice) => {
      dailyPrice.hostingPrice = (dailyPrice.date == dt) ? price : dailyPrice.hostingPrice
      dailyPrice.touristTax = getTouristTax(dailyPrice.hostingPrice, Number(guests_no), record?.taxe_sejour)
      return dailyPrice
    }) as [IBookingRoomPrice]
    setDailyPrices(newDailyPrices);
  }

  const onChangeHostingReduction = (reduction: number, dt: any) => {
    const initPrice = initDailyPrices?.find((dailyPrice: IBookingRoomPrice) => { return dailyPrice.date == dt })?.hostingPrice || 0
    const reducedPrice = initPrice - (initPrice * (reduction / 100))
    const newDailyPrices = dailyPrices?.map((dailyPrice: IBookingRoomPrice) => {
      dailyPrice.hostingPrice = (dailyPrice.date == dt) ? (Math.round(reducedPrice * 100) / 100) : dailyPrice.hostingPrice
      dailyPrice.touristTax = getTouristTax(dailyPrice.hostingPrice, Number(guests_no), record?.taxe_sejour)
      return dailyPrice
    }) as [IBookingRoomPrice]
    setDailyPrices(newDailyPrices);
  }

  const onChangeHostingGlobalReduction = (reduction: number) => {
    const newDailyPrices = dailyPrices?.map((dailyPrice: IBookingRoomPrice) => {
      const initPrice = initDailyPrices?.find((dp: IBookingRoomPrice) => { return dp.date == dailyPrice.date })?.hostingPrice || 0
      const reducedPrice = initPrice - (initPrice * (reduction / 100))
      dailyPrice.hostingPrice = (Math.round(reducedPrice * 100) / 100)
      dailyPrice.touristTax = getTouristTax(dailyPrice.hostingPrice, Number(guests_no), record?.taxe_sejour)
      return dailyPrice
    }) as [IBookingRoomPrice]
    setDailyPrices(newDailyPrices);
  }

  if (!dailyPrices) return (<></>)
  return (
    <>
      <Space wrap>
        <Space wrap>
          <Space>
            {optionIsShow && <InputPlusMinus
              min={0} max={100} key={`room_id_${room_id}_daily_prices_input_hosting_price_percent_reduction`}
              label={<>{t("pages.booking.discount_percent")}</>} value={0} onChange={(e: number) => onChangeHostingGlobalReduction(e)}
            />}
          </Space>
          <div><div style={{ color: "transparent" }}>.</div><Tooltip title={t('buttons.undo')}><Button icon={<SyncOutlined ></SyncOutlined>} onClick={() => { refetch(); setOptionIsShow(false) }}></Button></Tooltip></div>
          {!optionIsShow && <div>
            <div style={{ color: "transparent" }}>.</div>
            <Button icon={<PercentageOutlined />} onClick={() => setOptionIsShow(!optionIsShow)}>
            </Button>
          </div>}
        </Space>
      </Space>

      <br /><br />

      <Space direction="vertical" wrap>
        {dailyPrices.map((dailyPrice: IBookingRoomPrice, index: number) => {
          return (
            <Space wrap key={`room_id_${room_id}_daily_prices_${index}_space_wrap`}>
              <Space>

                <InputPlusMinus
                  min={0} key={`room_id_${room_id}_daily_prices_${index}_input_hosting_price`}
                  label={`${dayjs(dailyPrice.date).format('YYYY-MM-DD')}`} value={dailyPrice.hostingPrice} onChange={(e: number) => onChangeHostingPrice(e, dailyPrice.date)}
                />
                {optionIsShow && <InputPlusMinus
                  min={0} max={100} key={`room_id_${room_id}_daily_prices_${index}_input_hosting_price_percent_reduction`}
                  label={t("pages.booking.discount_percent")} value={0} onChange={(e: number) => onChangeHostingReduction(e, dailyPrice.date)}
                />}

                <div>
                  <div>{t('pages.booking.tourist_tax')}</div>
                  <Input
                    style={{ maxWidth: "7rem" }}
                    value={dailyPrice.touristTax}
                    disabled
                  />
                </div>

              </Space>
            </Space>
          )
        })}
      </Space>
    </>
  )
}

interface IResourceRecapProps extends IResourceComponentsProps {
  bookDates: any;
  itemData: any;
  // gndTot: any;
  removeRoom: (id: any) => void;
  removeExtra: (roomId: number, id: any) => void;
}

const RecapCard: React.FC<IResourceRecapProps> = (props) => {
  const t = useTranslate();
  const { bookDates, itemData, removeRoom, removeExtra } = props

  let gndTot = itemData?.reduce((acc: number, room: any) => acc + room?.hebPrices?.reduce((acc: number, hebp: any) => acc + hebp?.hostingPrice + hebp?.touristTax, 0), 0)
  gndTot += itemData?.reduce((acc: number, room: any) => acc + room?.extras?.reduce((acc: number, extra: any) => acc + extra.unit_price * extra.days?.reduce(((count: number, day: { qtt: number; }) => count + Number(day.qtt)), 0), 0), 0)

  return (
    <Card key={"card_01_booking"} title={t("pages.booking.title")} style={{ backgroundColor: "#EFEFEF", width: "max-content", maxWidth: "500px", minWidth: "400px", padding: '10px', margin: "0px auto 10px auto" }}>
      {bookDates.start && <>{dayjs(bookDates.start).locale(t("short_lang")).format('LL')}</>} - {bookDates.end && <>{dayjs(bookDates.end).locale(t("short_lang")).format('LL')}</>}<br /><br />
      {itemData.map((item: any) =>
        <div key={`room_${item.id}`}>
          {'\xa0'.repeat(0)}- {item?.name}{'\xa0'.repeat(1)}<Tag>{item.guests_no} <UserOutlined style={{ color: "green" }} /></Tag><Tag>{item?.hebPrices?.reduce((acc: number, hebp: any) => acc + hebp?.hostingPrice + hebp?.touristTax, 0).toFixed(2)}€</Tag> <DeleteOutlined style={{ color: "red" }} onClick={() => removeRoom(item.id)} />
          {item.extras?.map((extra: any) =>
            <div key={`extra_room_${item.id}_${extra.id}`}>
              {'\xa0'.repeat(5)}- {`${extra.days?.reduce(((count: number, day: { qtt: number; }) => count + Number(day.qtt)), 0)}x ${extra.name}`} <Tag>{`${(extra.unit_price * extra.days?.reduce(((count: number, day: { qtt: number; }) => count + Number(day.qtt)), 0)).toFixed(2)}€`}</Tag> <DeleteOutlined style={{ color: "red" }} onClick={() => removeExtra(item.id, extra.id)} />
              {extra?.days?.map((days: any) => {
                if (days?.qtt) {
                  return (<div key={`${days?.day}-${days?.qtt}`}>
                    {'\xa0'.repeat(10)}- {`${dayjs(days?.day).locale(t("short_lang")).format('DD/MM')}: ${days?.qtt}`}
                  </div>)
                }
                return (<></>)
              })
              }
            </div>)}
        </div>)}
      <br />
      <div style={{ fontWeight: "bold" }}>{`${gndTot.toFixed(2)}€`}</div>
    </Card>
  )
}


type extraInputsProps = {
  bookDates: { days: [any] },
  item: any,
  t: any,
}
const ExtraInputs = React.forwardRef((props: extraInputsProps, ref) => {
  const { bookDates, item, t } = props
  const isDayUse = bookDates.days.length == 1 ? 0 : bookDates.days.length - 1;

  const [newExtra, setNewExtra] = useState({
    id: item.extra_push.id,
    name: item.extra_push.name,
    days: (bookDates.days.slice(0, isDayUse)).map((d: any) => ({ day: d, qtt: 0 })),
    unit_price: item.extra_push.unit_price,
  })

  React.useImperativeHandle(ref, () => ({
    addExtra: () => {
      return newExtra
    },
    setReduction: (percent: number) => {
      setNewExtra({ ...newExtra, unit_price: Math.round((item.extra_push.unit_price - (item.extra_push.unit_price * (percent / 100))) * 100) / 100 })
    }
  }));

  return (

    <Space wrap>
      {newExtra.days.map(({ day, qtt }, index: number) => {
        return (
          <InputPlusMinus
            onChange={(e: any) => {
              setNewExtra({
                ...newExtra, days: newExtra.days.map((dday: any) => {
                  dday.qtt = (dday.day == day) ? e : dday.qtt
                  return dday
                })
              })
            }}
            buttons integerOnly={true} min={0} key={`room_id_${item.id}_extra_push_${item.extra_push.id}_${index}_input`}
            label={dayjs(day).locale(t("short_lang")).format('DD/MM')}
          />
        )
      })}
      <div>
        {<InputPlusMinus
          min={0} key={`room_id_${item.id}_extra_push_${item.extra_push.id}_input_unit_price`}
          label={<>{t("unitPrice")}</>} value={newExtra.unit_price} initialValue={newExtra.unit_price} onChange={(nb: number) => setNewExtra({ ...newExtra, unit_price: nb })}
        />}
      </div>
    </Space>

  )
})


// FUNCTIONS
const groupByAddress = (data: any[] | undefined) => {
  const map = new Map();
  data?.forEach((item: any) => {
    const key = `${item.address.toLowerCase()} ${item.city.toLowerCase()} ${item.zip}`;
    const array = map.get(key);
    const priceLabel = (item?.daily_prices?.nanr?.pricettc && item?.daily_prices?.bar?.pricettc) ? `(NANR ${item?.daily_prices?.nanr?.pricettc}€ / BAR ${item?.daily_prices?.bar?.pricettc}€)` : ''
    if (!array) {
      map.set(key, [{
        label: `${item?.name_short} - ${item.roomtype?.name || ''} ${priceLabel}`,
        value: item.id,
        roomtype: item.roomtype?.name || '',
      }]);
    } else {
      array.push({
        label: `${item?.name_short} - ${item.roomtype?.name || ''} ${priceLabel}`,
        value: item.id,
        roomtype: item.roomtype?.name || '',
      });
    }
  });

  const opt = Array.from(map, ([label, options]) => ({ label, options }));
  opt?.forEach((item: any) => {
    item?.options?.sort((a: any, b: any) => {
      let nameA = a.roomtype;
      let nameB = b.roomtype;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

  })

  return opt
};

const getDaysArray = (start: any, end: any) => {
  for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
}
