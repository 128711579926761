import { AuthProvider } from "@pankod/refine-core";

import axios, { AxiosInstance } from "axios";
import queryString, { stringify } from "query-string";
// import queryString from "query-string";
import {
    DataProvider,
    HttpError,
    CrudOperators,
    CrudFilters,
    CrudSorting,
} from "@pankod/refine-core";

export const TOKEN_KEY = "ACCESS_TOKEN";


const oauthParma = {
  clientId: process.env?.REACT_APP_OAUTH_CLIENTID || '',
  accessTokenUri: `${process.env?.REACT_APP_API_URL || ''}/oauth/token`,
  otpRequestUri: `${process.env?.REACT_APP_API_URL || ''}/oauth/otp`,
  authorizationUri: `${process.env?.REACT_APP_API_URL || ''}/oauth/authorize`,
  // redirectUri: `${process.env?.REACT_APP_API_URL || ''}/callback`,
  scopes: ['read']
};

const httpClient = axios.create();

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status == 400 || error.response.status == 401) {
        localStorage.clear();
        window.location.reload();
    }
    
    const customError: HttpError = {
        ...error,
        message: error.response?.data?.message,
        statusCode: error.response?.status,
    };
    return Promise.reject(customError);
  },
);

class IAuthProvider implements AuthProvider {
  [key: string]: any;
  login!: (params: any) => Promise<string | false | void>;
  otp!: (params: any) => Promise<string | false | void>;
  refresh!: (params: any) => Promise<string | false | void>;
  logout!: (params: any) => Promise<string | false | void>;
  checkAuth!: (params?: any) => Promise<void>;
  checkError!: (error: any) => Promise<void>;
  getPermissions!: (params?: any) => Promise<any>;
  getUserIdentity?: (() => Promise<any>) | undefined;
  isProvided?: boolean | undefined;
  isAuthenticated?: boolean | undefined;
}

interface ITokenData {
  access_token: string,
  refresh_token: string,
  expires_in: string,
  scope: string,
};

const updateTokenStorage = (token: ITokenData) => {
  localStorage.setItem('ACCESS_TOKEN', token.access_token);
  localStorage.setItem('REFRESH_TOKEN', token.refresh_token);
  localStorage.setItem('EXPIRESDATE_TOKEN', `${Date.now() + Number(token.expires_in) * 1000}`);
  localStorage.setItem('SCOPE_TOKEN', token.scope);
}


export const authProvider: IAuthProvider = {
  login: async ({ username, password, otpcode }) => {
    const { data , headers, status } = await httpClient.post(
        `${oauthParma.accessTokenUri}`,
        stringify(
          {
            grant_type: 'password',
            client_id: oauthParma.clientId,
            username: username, 
            password: password, 
            otpcode: otpcode
          }
        ),
        // { auth: { username: oauthParma.clientId,password: oauthParma.clientSecret } },
    );

    // const tokenData: ITokenData = data

    updateTokenStorage(data as ITokenData)
    // localStorage.setItem('ACCESS_TOKEN', tokenData.access_token);
    // localStorage.setItem('REFRESH_TOKEN', tokenData.refresh_token);
    // localStorage.setItem('EXPIRESDATE_TOKEN', `${Date.now() + Number(tokenData.expires_in) * 1000}`);
    // localStorage.setItem('SCOPE_TOKEN', tokenData.scope);
    return Promise.resolve();

  },
  otp: async (props: { username: any; password: any; }) => {
    const { username, password } = props
    const { data , headers, status } = await httpClient.post(
        `${oauthParma.otpRequestUri}`,
        stringify(
          {
            grant_type: 'password',
            client_id: oauthParma.clientId,
            username: username, 
            password: password, 
          }
        ),
    );
    return Promise.resolve();
  },
  refresh: async () => {
    const { data , headers, status } = await httpClient.post(
        `${oauthParma.accessTokenUri}`,
        stringify(
          {
            grant_type: 'refresh_token',
            client_id: oauthParma.clientId,
            refresh_token: localStorage.getItem("REFRESH_TOKEN"),
          }
        ),
    );
    updateTokenStorage(data as ITokenData)
    return Promise.resolve();
  },
  logout: () => {
    localStorage.clear();
    window.location.reload();
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve({
      id: 1,
    });
  },
};

// export const authProvider: AuthProvider = {
//   login: async ({ username, password }) => {
//     if (username === "admin" && password === "admin") {
//       localStorage.setItem(TOKEN_KEY, username);
//       return Promise.resolve();
//     }
//     return Promise.reject(new Error("username: admin, password: admin"));
//   },
//   logout: () => {
//     localStorage.removeItem(TOKEN_KEY);
//     return Promise.resolve();
//   },
//   checkError: () => Promise.resolve(),
//   checkAuth: () => {
//     const token = localStorage.getItem(TOKEN_KEY);
//     if (token) {
//       return Promise.resolve();
//     }

//     return Promise.reject();
//   },
//   getPermissions: () => Promise.resolve(),
//   getUserIdentity: async () => {
//     const token = localStorage.getItem(TOKEN_KEY);
//     if (!token) {
//       return Promise.reject();
//     }

//     return Promise.resolve({
//       id: 1,
//     });
//   },
// };
