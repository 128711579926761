import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Edit,
  Form,
  Input,
  Select,
  useForm,
  Table,
  TextField,
  Tag,
  Tooltip,
  Popover,
  Switch,
  Dropdown,
  Menu,
  Checkbox,
  DatePicker,
} from "@pankod/refine-antd";
import { ConfettiCanvas } from "react-raining-confetti";
import { CaretDownOutlined, CaretUpOutlined, PauseCircleOutlined } from "@ant-design/icons"

import "react-mde/lib/styles/css/react-mde-all.css";
import { VList, scrollTo } from "virtuallist-antd";

import { IYieldADay, IYieldAnalysis } from "interfaces";
import dayjs, { Dayjs } from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)
dayjs.extend(localizedFormat)

interface IRecord extends IYieldADay {
  index: number, 
  key: number, 
  ispassed: boolean, 
  keyForm: boolean|number,
  // arrowG: string,
}

function VirtualTable(props: any) {
  const { columns, scroll, oldDepth, form } = props;

  const vc = useMemo(() => {
    return VList({ 
      height: scroll!.y as number,

    });
  }, []);

  return (
      <Table
        {...props}
        // sticky={true}
        rowClassName={(record: any, index) => {
          if (record.rowClass != "") {
            return record.rowClass
          }
          if (record.keyForm !== false) {
            return 'table-row-dark-grey ';
            return ((record.index / (oldDepth+1)) % 2 === 0 ? 'table-row-light-grey ' :  'table-row-dark-grey ');
          }
          return ((record.index % (oldDepth+1)) % 2 === 0 ? 'table-row-light-blue ' : 'table-row-light ');
        }}
  
        size="small"
        // expandIconColumnIndex={oldDepth === 0 ? (agendaIsShow? 4: 1) : columns.length - 2}
        expandIconColumnIndex={oldDepth === 0 ? columns.length-1 : columns.length-2}
        // expandIconColumnIndex={oldDepth === 0 ? 1 : 6 /* +4 */}
        expandable={{
          indentSize: 0,
          expandIcon: (props) => {
            let { expanded, onExpand, record } = props
            const r: IRecord = record as IRecord
            if (r.keyForm === false || r.ispassed === true) {
              return (
                <Form.Item   
                  style={{margin: 0, width: "fit-content()"}} 
                  >
                  <Input value={record.currG} disabled size="small" style={{textAlign: "right"}}></Input>
                </Form.Item>
              )
            }
            return (
              // <table key={`_${i}`}>
              //   <tbody>


              <Popover
                // content={record.tooltip.map((item: any, i: number) => (<p key={i} style={{textAlign: 'right'}}><TextField style={{fontWeight: 'bold'}} value={`${item}`} /></p>))}
                content={
                  <table>
                    <tbody>
                      {record?.tooltip?.map((item: any, i: number) => (
                        <tr key={i} style={{textAlign: 'center'}}>
                          <td style={{textAlign: 'left', paddingRight: '10px'}}>
                            <TextField style={{fontWeight: 'bold'}} value={`${item.name}:`} />
                          </td>
                          <td style={{width: '25px', paddingRight: '5px'}}>
                            <TextField value={`${item.availablerooms}`} />
                          </td>
                          <td style={{width: '25px', paddingLeft: '5px', paddingRight: '5px'}}>
                            {item.forecast!==null? <Tag color={`#${item.color_forecast}`}>{`${item.forecast}`}</Tag>: <Tag >{'-'}</Tag>}
                          </td>
                          <td style={{width: '25px', paddingRight: '5px'}}>
                            <TextField style={{fontWeight: 'bold'}} value={`${item.currG}`} />
                          </td>
                          <td style={{width: '25px', paddingRight: '5px'}}>
                            {item.trendG == '+' ? <CaretUpOutlined style={{ fontSize: '1rem', color: 'green' }}/> : item.trendG == '-' ? <CaretDownOutlined style={{ fontSize: '1rem', color: 'red' }}/> : <PauseCircleOutlined rotate={90} style={{ fontSize: '1rem' }}/>}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                }
                placement="topRight" 
                visible={oldDepth > 0 && expanded}
              >
                <Form.Item   
                name={[`${r.keyForm}`, "currG"]}
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{margin: 0, width: "fit-content()"}} 
                >
                  <GSelect
                    record={record}
                    onFocus={(e: React.MouseEvent<HTMLElement, MouseEvent>) => !expanded ? onExpand(record, e) : null}
                    onBlur={(e: React.MouseEvent<HTMLElement, MouseEvent>) => expanded ? onExpand(record, e) : null}
                    form={form}
                    key={`${r.keyForm}`}
                  />
                </Form.Item>
              </Popover>

            );
          }
        }}
        pagination={false}
        components={{
          ...vc,
        }}
      />
  );
}

const eventBasicRender = (record: IRecord, columnId: number) => {
  // console.log(record.events)
  let event = record.events?.find((ev:any) => ev.place == columnId)
  // let event = null
  // if (record.events?.length > columnId) {
  if (event) {
    const endD = new Date(event.end)
    const startD = new Date(event.start)
    const nowD = new Date(record.date)
    const diffStartNow = Math.ceil((nowD.getTime()-startD.getTime()) / (1000 * 3600 * 24))
    const diffStartEnd = Math.ceil((endD.getTime()-startD.getTime()) / (1000 * 3600 * 24))
    // console.log(record)
    // console.log(record)
    // console.log(record.index)
    // console.log(columnId)
    
    // console.log(document.querySelector(`tr[data-row-key="${record.key}"]`))
    // console.log(document.querySelector(`tr`))
    // console.log(document.querySelector(`#yieldadays_${record.keyForm as number + 5}_currG`))

    // if (!document.querySelector(`#yieldadays_${record.key}_currG`)) {

    // // if (!document.querySelector(`tr[data-row-key="${record.index}"]`)) {
    //   return <></>

    //   // scrollTo({ row: formElId + 2}) // scroll next input on top (+2?)
    // } 
    return <Popover title={`${event.name}`} content={<p>{event.comment}</p>} overlayStyle={{width: "20vw"}}>
        <Dropdown 
          getPopupContainer={trigger => trigger.parentNode as HTMLElement}  
          align={{points: ['tc', 'tc'],
          offset: [0, -3],
          overflow: { adjustX: false, adjustY: false }}}
          visible
          overlay={(diffStartNow < 1 && record.keyForm !== false) ?
            <div>
              <Popover title={`${event.name}`} content={<p>{event.comment}</p>} overlayStyle={{width: "20vw"}}>
                <div style={{writingMode: "vertical-rl"}}>
                  {`${event.name.slice(0,3+(4*(diffStartEnd-1)))}`}
                </div>
              </Popover>
            </div>
          : <></>}
        >
          <div style={{color: "transparent", writingMode: "vertical-lr"}}>OO</div>
        </Dropdown>       
      </Popover> 
  }
  //   return <>
  //     {record.events?.length > columnId ? 
  //     <Popover title={`${record.events?.[columnId]?.name}`} content={<p>{record.events?.[columnId]?.comment}</p>} overlayStyle={{width: "20vw"}}>
  //       <Dropdown 
  //         getPopupContainer={trigger => trigger.parentNode as HTMLElement}  
  //         align={{points: ['tc', 'tc'],
  //         offset: [0, -3],
  //         overflow: { adjustX: false, adjustY: false }}}
  //         visible
  //         overlay={(diffStartNow < 1 && record.keyForm !== false) ?
  //           <div>
  //             <Popover title={`${record.events?.[columnId]?.name}`} content={<p>{record.events?.[columnId]?.comment}</p>} overlayStyle={{width: "20vw"}}>
  //               <div style={{writingMode: "vertical-rl"}}>
  //                 {`${record.events?.[columnId]?.name.slice(0,3+(4*(diffStartEnd-1)))}`}
  //               </div>
  //             </Popover>
  //           </div>
  //         : <></>}
  //       >
  //         <div style={{color: "transparent", writingMode: "vertical-lr"}}>OO</div>
  //       </Dropdown>       
  //     </Popover> 
  //     : null}
  //   </>
  // }
  return <></>
}

const eventCellBasicRender = (record: IRecord, rowIndex: number, olddepthInput: number, columnId: number) => {
  let event = record.events?.find((ev:any) => ev.place == columnId)
  // let event = null
  // if (record.events?.length > columnId) {
  if (event) {

  // if (record.events?.length > columnId) {
    return {
      style: {
        background: ["hsl(",((1-((event.grade)/10))*120).toString(10),",100%,50%)"].join(""), 
        borderColor: 'transparent', 
        paddingLeft: '0px', 
        paddingRight: '0px', 
      }
    }
  }

  return {}
}

const GSelect = ({...props}) => {
  const { record, form, key } = props;
  const [inputValue, setinputValue] = useState(props.value);

  return (
      <Input
        {...props}
        size="small"
        step={1}
        onKeyDown={(e) => {
          e.preventDefault();
          let el = (e.target as HTMLInputElement)
          let formValues = form.getFieldValue(['yieldadays'])
          const formElId = formValues.findIndex((x: { id: number; }) => x.id === record.key)
          if (e.key === "ArrowUp") {
            const newVal = inputValue - 1 < 0 ? 0 : inputValue - 1
            setinputValue(newVal)
            formValues[formElId].currG = newVal
          }
          if (e.key === "ArrowDown") {
            // const newVal = inputValue + 1 > 15 ? 15 : inputValue + 1 
            const newVal = inputValue + 1 
            setinputValue(newVal)
            formValues[formElId].currG = newVal
          }
          if (e.key === "Enter") {
            let nextInput = document.querySelector(
              `#yieldadays_${parseInt(el.id.match(/\d+/)![0], 10) + 1}_currG`
            ) as HTMLElement | null;
            // TRICK TO KNOW IF WE ARE AT END OF LIST (IF END INPUT + 3 IS NULL)
            if (!document.querySelector(`#yieldadays_${parseInt(el.id.match(/\d+/)![0], 10) + 3}_currG`)) {
              scrollTo({ row: formElId + 2}) // scroll next input on top (+2?)
            } 
            nextInput?.focus()
          }        
        }}
        autoComplete="off"
        value={inputValue}
        style={{textAlign: "right"}}
      >
    </Input>
  )
   return(
    <Select
      {...props}
      size="small"
      showArrow={false}
      dropdownRender={(menu: React.ReactElement) => menu}
      dropdownAlign={{
        points: ['cr', 'cr'],
        // offset: [10, 20],
        // targetOffset: ['-50%','40%'],
        overflow: { adjustX: true, adjustY: true },
      }}
    >
      <Select.Option value={0} ref={0}>0</Select.Option>
      <Select.Option value={1} ref={1}>1</Select.Option>
      <Select.Option value={2} ref={2}>2</Select.Option>
      <Select.Option value={3} ref={3}>3</Select.Option>
      <Select.Option value={4} ref={4}>4</Select.Option>
      <Select.Option value={5} ref={5}>5</Select.Option>
      <Select.Option value={6} ref={6}>6</Select.Option>
      <Select.Option value={7} ref={7}>7</Select.Option>
      <Select.Option value={8} ref={8}>8</Select.Option>
      <Select.Option value={9} ref={9}>9</Select.Option>
      <Select.Option value={10} ref={10}>10</Select.Option>
      <Select.Option value={11} ref={11}>11</Select.Option>
      <Select.Option value={12} ref={12}>12</Select.Option>
      <Select.Option value={13} ref={13}>13</Select.Option>
      <Select.Option value={14} ref={14}>14</Select.Option>
      <Select.Option value={15} ref={15}>15</Select.Option>
    </Select>
  )
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export const YieldanalysisEdit: React.FC<IResourceComponentsProps> = () => {
  const [olddepthInput, setOlddepthInput] = useState<number>(5);
  const [updateForce, setUpdateForce] = useState<boolean>(false);
  const [agendaIsShow, setAgendaIsShow] = useState<boolean>(true);
  const [historyStart, setHistoryStart] = useState<string|undefined>(undefined);
  const { height, width } = useWindowDimensions();

  const t = useTranslate();
  
  // const { formProps, saveButtonProps, queryResult, form, formLoading } = useForm<IYieldAnalysis>({
  const { formProps, saveButtonProps, queryResult, form, formLoading } = useForm<IYieldAnalysis>({
    redirect: false,
    metaData: {
      depth: olddepthInput,
      load_since: historyStart,
    },
  });

  

  // saveButtonProps
  const resource = queryResult?.data?.data as IYieldAnalysis

  const confettiShow = resource?.yieldadays.filter((elem) => elem.forecast == elem.remainingdays && new Date(elem.date) >= new Date(resource.start)).length > 1 ? (
    <ConfettiCanvas active={true} fadingMode="LIGHT" stopAfterMs={6000} />
  ) : (<></>)

  const defaultsColumns = 
  [
    { 
      title: t("yieldanalysis.yielddate"),
      ellipsis: { showTitle: false, },
      onCell: (record: IRecord) => record.keyForm !== false? {} : ({style: {textAlign: "right"}}),
      render:(record: IRecord) => record.keyForm !== false?
        <TextField style={{fontVariant: "all-petite-caps", fontSize: 16}} value={dayjs(record.date).locale(t("short_lang")).format('ddd D/M')}/>
        :<TextField style={{fontVariant: "all-petite-caps", fontSize: 16}} value={dayjs(record.dateref).locale(t("short_lang")).format('D/M')}/>
    },
    { 
      title: t("yieldanalysis.diffOldAvailableroom"),
      ellipsis: { showTitle: false },
      render: (record: IRecord) => {return(<TextField value={record.diffOldAvailableroom}/>)},
    },
    { 
      title: t("yieldanalysis.daysincelast"),
      ellipsis: { showTitle: false },
      render: (record: IRecord) => <Tooltip placement="topLeft" title={record.daysincelast}>{record.daysincelast}</Tooltip>
    },
    { 
      title: t("yieldanalysis.availablerooms"),
      ellipsis: { showTitle: false },
      render: (record: IRecord) => <TextField value={record.availablerooms}/>,
    },
    { 
      title: t("yieldanalysis.remainingdays"),
      ellipsis: { showTitle: false },
      render: (record: IRecord) => <TextField value={record.remainingdays}/>,
    },
    { 
      title: t("yieldanalysis.prev"),
      ellipsis: { showTitle: false },
      render: (record: IRecord) => record.forecast!==null? <Tag color={`#${record.color_forecast}`}>{`${record.forecast}`}</Tag>: <TextField value="-"/>,
    },
    { 
      title: t("yieldanalysis.currG"),
      width: width < 992 ? 54 : 'auto',
      onCell: () => ({style: {textAlign: "right"}}),
      render: (record: IRecord) => <TextField value={""}/> 
    },
    { 
      title: '',
      onCell: () => ({style: {textAlign: "right"}}),
      render: (record: IRecord) => {
        return <>
          {record.fullsince !== null ? <TextField value={`FULL J-${record.fullsince}  `}/> : null} 
          {record.trendG == '+' ? <CaretUpOutlined style={{ fontSize: '20px', color: 'green' }}/> : record.trendG == '-' ? <CaretDownOutlined style={{ fontSize: '20px', color: 'red' }}/> : <PauseCircleOutlined rotate={90} style={{ fontSize: '20px' }}/>}
        </>
      }
    },
  ]

  const agendaColumns = [
    {
      title: '',
      key: 'ev0',
      width: 20,
      onCell: (record: IRecord, rowIndex: number) => {
        return (eventCellBasicRender(record, rowIndex, olddepthInput, 0))
      },
      render: (record: IRecord) => {
        return (eventBasicRender(record, 0))
      }
    },
    {
      title: '',
      key: 'ev1',
      width: 20,
      onCell: (record: IRecord, rowIndex: number) => {
        return (eventCellBasicRender(record, rowIndex, olddepthInput, 1))
      },
      render: (record: IRecord) => {
        return (eventBasicRender(record, 1))
      }    
    },
    {
      title: '',
      key: 'ev2',
      width: 20,
      onCell: (record: IRecord, rowIndex: number) => {
        return (eventCellBasicRender(record, rowIndex, olddepthInput, 2))
      },
      render: (record: IRecord) => {
        return (eventBasicRender(record, 2))
      }    
    },
    {
      title: '',
      key: 'ev3',
      width: 20,
      onCell: (record: IRecord, rowIndex: number) => {
        return (eventCellBasicRender(record, rowIndex, olddepthInput, 3))
      },
      render: (record: IRecord) => {
        return (eventBasicRender(record, 3))
      }    
    },
    {
      title: '',
      key: 'ev4',
      width: 20,
      // onCell: (record: IRecord, rowIndex: number) => {
      //   if (record.events?.length > 3) {
      //     return {}
      //   }
      //   return (eventCellBasicRender(record, rowIndex, olddepthInput, 3))
      // },
      render: (record: IRecord) => {
        if (record.events?.length > 4) {
          const content = record.events?.map((event: any) => (
            <p key={event.id}>{event.name}</p>
          ))
          return (
            <Popover content={<>{content}</>} overlayStyle={{width: "20vw"}}>
              <div>+</div>
            </Popover>
          )
        }
        // return (eventBasicRender(record, 3))
      }    
    },
    // {
    //   title: '',
    //   key: 'ev4',
    //   width: 20,
    //   // onCell: (record: IRecord, rowIndex: number) => {
    //   //   return (eventCellBasicRender(record, rowIndex, olddepthInput, 3))
    //   // },
    //   render: (record: IRecord) => record.events?.length > 4 ? <>+</>: null
    //   // return (eventBasicRender(record, 3))
    // },
  ]

  const onMenuClick = (e: any) => {
    form.setFieldsValue({full_update: true})
    form.submit()
  };
  

  const menuSave = (
    <Menu
    onClick={onMenuClick}
    >
      <Menu.Item key="updateAll">{t("yieldanalysis.save_force")}</Menu.Item>
    </Menu>
  );  

  const usedColumns = olddepthInput == 0 ? [defaultsColumns[0], defaultsColumns[defaultsColumns.length-2]] : defaultsColumns

  const columns = agendaIsShow ? [...agendaColumns, ...usedColumns]: [...usedColumns]

  return (
    <Edit
      isLoading={formLoading}
      actionButtons={
        <>
        <Tooltip placement="bottom" title={t('yieldanalysis.warning_save_force')}>
        <Dropdown.Button 
            overlay={menuSave}
            onClick={form.submit}
            type="primary"
            >
            {t("yieldanalysis.save_updated")}
          </Dropdown.Button>
      </Tooltip>
        </>
      }
      title={`${resource?.pricezone?.name || ''}`}
      pageHeaderProps={{
        extra: [
          <TextField key="page_header_extra_1" value={`${t("yieldanalysis.history")} : `}/>,
          <Select key="page_header_extra_2" onChange={setOlddepthInput} defaultValue={olddepthInput}>
            <Select.Option value={0}>0</Select.Option>
            <Select.Option value={1}>1</Select.Option>
            <Select.Option value={2}>2</Select.Option>
            <Select.Option value={3}>3</Select.Option>
            <Select.Option value={4}>4</Select.Option>
            <Select.Option value={5}>5</Select.Option>
          </Select>,
          <DatePicker key="page_header_extra_3" onChange={(e) => setHistoryStart(e?.format('YYYY-MM-DDTHH:mm:ss'))}  format={t("short_date")} monthCellRender={(curr) => dayjs(curr).locale("fr").format("MMM")}/>,
          <TextField key="page_header_extra_4" value={`${t("agendas.agenda")} : `}/>,
          <Switch key="page_header_extra_5" defaultChecked={agendaIsShow} onChange={setAgendaIsShow} />,
        ],
        subTitle:`${t("yieldanalysis.yieldanalysis")} ${resource?.dateref ? ' - ' + dayjs(resource.dateref).locale(t("short_lang")).format('ddd D/M') : ''} ${resource?.modified ? ` (${t('last_modified')} ` + dayjs(resource.modified).locale(t("short_lang")).format('D/M, HH:mm') + ')' : ''}`
      }}
      resource="yieldanalysis"
    >
      <Form {...formProps} layout="vertical" style={{padding: '0px 0px'}} >
      <Form.Item
          name="comment"
        >
          <Input/>
        </Form.Item>
        <Form.Item
          name="full_update"
          valuePropName="checked"
          hidden
        >
          <Checkbox/>
        </Form.Item>

        <Form.List name="yieldadays">
          {(yieldadays, { add, remove }) => {
            let index = 0;
            let prevG = null
            const yieldadaysValues = form?.getFieldValue(["yieldadays"])?.flatMap((v: IYieldADay, key: any) => {
              let len = v.prev_yieldadays.length + index + 1;
              index = len

              const nextGList: any[] = v.prev_yieldadays?.flatMap((v: IYieldADay) => {
                return [v.currG]
              })
              const yieldadays: any[] = v.prev_yieldadays?.flatMap((v: IYieldADay, index) => {
                const prevG = nextGList.length - 1 > index ? nextGList[index + 1] : null
                const data = {
                  key: v.id,
                  index: --len,
                  date: v.date,
                  dateref: v.dateref,
                  daysincelast: v.daysincelast,
                  availablerooms: v.availablerooms,
                  remainingdays: v.remainingdays,
                  currG: v.currG,
                  oldG: v.oldG,
                  newG: v.newG,
                  diffOldAvailableroom: v.diffOldAvailableroom,
                  forecast: v.forecast,
                  trendG: v.trendG,
                  color_forecast: v.color_forecast + '75', //transparency at 75%
                  fullsince: v.fullsince,
                  prev_yieldadays: v.prev_yieldadays,
                  rowClass: "",
                  depth: olddepthInput,
                  children: [],
                  keyForm: false,
                  ispassed: false,
                  tooltip: [],
                  events: v.events,
                }
                return [data,]
              })
              const { prev_yieldadays,  ...yieldadaylight } = v
              const day = dayjs(v.date).get('day')
              const data = {
                key: v.id,
                index: --len,
                date: v.date,
                dateref: v.dateref,
                daysincelast: v.daysincelast,
                availablerooms: v.availablerooms,
                remainingdays: v.remainingdays,
                currG: v.currG,
                oldG: v.oldG,
                newG: v.newG,
                diffOldAvailableroom: v.diffOldAvailableroom,
                forecast: v.forecast,
                trendG: v.trendG,
                color_forecast: v.color_forecast,
                fullsince: v.fullsince,
                prev_yieldadays: v.prev_yieldadays,
                rowClass: day == 0 || day == 6 ? "table-row-light-green " : '',
                depth: olddepthInput,
                children: yieldadays,
                keyForm: key,
                // keyForm: false : key,
                ispassed: resource.id !== v.yieldanalysis_id ? true: false,
                tooltip: v.compare_pricezone_g,
                events: v.events,
              }
              prevG = null;

              return [data]
            });

            return <VirtualTable columns={columns} dataSource={yieldadaysValues} scroll={{ y: height - 368 }} oldDepth={olddepthInput} form={form}/>
            
          }}
          
        </Form.List>
      </Form>
      {confettiShow}
    </Edit>
  );
};

