import {
    useTranslate,
    useCustom,
} from "@pankod/refine-core";
import { Typography, Button, Modal, useModal, Input } from "@pankod/refine-antd";

import { IClient } from "interfaces";

import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import {ElementsConsumer, PaymentElement} from '@stripe/react-stripe-js';

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import React, { forwardRef, ReactNode, useImperativeHandle, useState } from "react";
// import { useNavigation } from "@pankod/refine-core";
dayjs.extend(localizedFormat)

const { Title, Text } = Typography;

  
interface Props {
    children?: ReactNode;
    sCustomerId: string | undefined;
    record: IClient | undefined;
}

const stripePromise = loadStripe(`${process.env?.REACT_APP_STRIPE_PUB_KEY}`);

export const AddCardModal = forwardRef<any, Props>((props, ref) => {
  const t = useTranslate();
  const { modalProps, show, close } = useModal();
  let record = props.record
  useImperativeHandle(ref, () => ({
    show() {
      show();
    },
  }));
  interface SetupIntent {
    client_secret: string;
    id: string;
  }
  const {data: cardsListQueryResult} = useCustom<SetupIntent>({
    url: `${process.env?.REACT_APP_API_URL || ''}/clients/${record?.id}/getsetupintent`,
    method: 'get',
    queryOptions: {
      enabled: record?true:false,
    },
  });
  const setupintent = cardsListQueryResult?.data

  const Wrapper = (props:any) => (
    <Elements stripe={stripePromise}>
      <MyComponent {...props} />
    </Elements>
  );

  const MyComponent = (props:any) => {
    const stripe = useStripe();
    const elements = useElements();
    const [pmId, setPmId] = useState("");
    const [ errorMsg, setErrorMsg ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);
    const {data: attachpmQueryResult} = useCustom<any>({
      url: `${process.env?.REACT_APP_API_URL || ''}/clients/${record?.id}/attachpm`,
      method: 'post',
      queryOptions: {
        enabled: pmId?true:false,
      },
      config: {
        payload: {paymentMethodId: pmId}
      },
    });
    const attachpm = attachpmQueryResult?.data
    if (attachpm == true) {
      window.location.reload();
    }
  
    const handleSubmit = async () => {
      setIsLoading(true)
      const elem = elements?.getElement(CardElement)
      if (!stripe || !elements || !elem || !setupintent || !record) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        setIsLoading(false)
        return;
      }

      const {setupIntent, error} = await stripe.confirmCardSetup(
        setupintent.client_secret,
        {
          payment_method: {
            card: elem,
            billing_details: {
              name: record.fancy_name,
            },
          },
        },
      );
      if (error) {
        setErrorMsg(`${error.message}`)
        setIsLoading(false)
      } else {
        setPmId(`${setupIntent.payment_method}`)
      }
    };

    return (
    
    <React.Fragment>
      <Input name="name" placeholder="name" defaultValue={record?.fancy_name}></Input><br/><br/>
      {errorMsg&&
      <><Text style={{color: 'red'}}>{errorMsg}</Text><br/><br/></>}
      <CardElement id="card-element"/><br/>
      <Button loading={isLoading} type="primary" disabled={!stripe} onClick={handleSubmit}>{t('buttons.save')}</Button>
    </React.Fragment>)
  };

  return (
    <Modal okButtonProps={{hidden: true}} title={<div><Title>{`${t("customers.newcard")}`}</Title>{record?.fancy_name}</div>} {...modalProps}>
      <Wrapper close={close}/>
    </Modal>
    )
})