import React, { useState, useEffect } from 'react';
import {
    useTranslate,
    IResourceComponentsProps,
    useNavigation,
    CrudFilters,
    HttpError,
} from "@pankod/refine-core";
import {
    Card,
    Typography,
    Button,
    Form,
    Input,
    DatePicker,
    Select,
    Row,
    Col,
    message,
    Switch,
    TimePicker,
    useTable,
	Create
} from "@pankod/refine-antd";
import { createTedeePin } from "./service/tedeeService"; // Importer les fonctions nécessaires
import { ITedee } from "interfaces"; // Assurez-vous d'importer l'interface appropriée
import moment from 'moment';

const { Title } = Typography;
const { RangePicker } = DatePicker;

export const TedeeCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { list } = useNavigation();
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isTemporary, setIsTemporary] = useState<boolean>(false);
    const [hasTimeRange, setHasTimeRange] = useState<boolean>(false);

    const { tableProps, sorter, searchFormProps } = useTable<ITedee, HttpError>({
        initialSorter: [
            {
                field: "name",
                order: "asc",
            },
        ],
    });

    const handleFinish = async (values: any) => {
        setIsSubmitting(true);
        try {
            const selectedLockId = values.lockId;
            let pinData: any = {
                alias: `${values.aliasType} : ${values.alias}`,
                pin: values.pin,
            };

            if (isTemporary && values.dateRange) {
                pinData.startDate = values.dateRange[0].subtract(2, 'hours').set({ second: 0 }).format("YYYY-MM-DDTHH:mm:ss");
                pinData.endDate = values.dateRange[1].subtract(2, 'hours').set({ second: 0 }).format("YYYY-MM-DDTHH:mm:ss");
            } else if (!isTemporary && hasTimeRange && values.startTime && values.endTime) {
                const tomorrow = moment().add(1, 'days').startOf('day');
                pinData.dayStartTime = moment(tomorrow).set({ hour: values.startTime.hour(), minute: values.startTime.minute(), second: 0 }).subtract(2, 'hours').format("YYYY-MM-DDTHH:mm:ss");
                pinData.dayEndTime = moment(tomorrow).set({ hour: values.endTime.hour(), minute: values.endTime.minute(), second: 0 }).subtract(2, 'hours').format("YYYY-MM-DDTHH:mm:ss");
            }

            if (selectedLockId === "global") {
                // If 'global' is selected, send the pin to all lock IDs
                for (const tedee of tableProps.dataSource || []) {
                    await createTedeePin(tedee.lock_id, pinData);
                }
            } else {
                // Send the pin to the selected lock ID
                await createTedeePin(selectedLockId, pinData);
            }

            message.success("PIN created successfully");
            list("tedee");
        } catch (error) {
            message.error(`Failed to create PIN:`);
            console.error('Error creating PIN:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
		<Create saveButtonProps={{ style: { display: 'none' } }} pageHeaderProps={{
            title: t("tedee.pincreate"),
            extra: [
            ]
        }}>
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Card>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleFinish}
                    >
                        <Form.Item
                            label={t("tedee.name")}
                            name="lockId"
                            rules={[{ required: true, message: 'Please select a suite' }]}
                        >
                            <Select>
                                <Select.Option value="global">Global</Select.Option>
                                {tableProps.dataSource?.map(tedee => (
                                    <Select.Option key={tedee.lock_id} value={tedee.lock_id}>
                                        {tedee.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={t("tedee.type")}
                            name="aliasType"
                            rules={[{ required: true, message: 'Please select a type' }]}
                        >
                            <Select>
                                <Select.Option value="Guest">Guest</Select.Option>
                                <Select.Option value="Staff">Staff</Select.Option>
                                <Select.Option value="Other">Other</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label={t("tedee.pinalias")}
                            name="alias"
                            rules={[{ required: true, message: 'Please enter alias' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("tedee.code")}
                            name="pin"
                            rules={[{ required: true, message: 'Please enter PIN' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label={t("tedee.temporary/permanent")}>
                            <Switch
                                checkedChildren={t("tedee.temporary")}
                                unCheckedChildren={t("tedee.permanent")}
                                onChange={(checked) => {
                                    setIsTemporary(checked);
                                    setHasTimeRange(false); // Reset time range if switching to temporary
                                }}
                            />
                        </Form.Item>
                        {isTemporary && (
                            <Form.Item
                                label={t("tedee.daterange")}
                                name="dateRange"
                                rules={[{ required: isTemporary, message: t("tedee.error.daterange") }]}
                            >
                                <RangePicker
                                    showTime={{ format: 'HH:mm' }}
                                    format="YYYY-MM-DD HH:mm"
                                />
                            </Form.Item>
                        )}
                        {!isTemporary && (
                            <Form.Item label={t("tedee.timeslots")}>
                                <Switch
                                    checkedChildren={t("tedee.with")}
                                    unCheckedChildren={t("tedee.without")}
                                    onChange={setHasTimeRange}
                                />
                            </Form.Item>
                        )}
                        {!isTemporary && hasTimeRange && (
                            <>
                                <Form.Item
                                    label={t("tedee.starttime")}
                                    name="startTime"
                                    rules={[{ required: hasTimeRange, message: t("tedee.error.startetime") }]}
                                >
                                    <TimePicker format="HH:mm" />
                                </Form.Item>
                                <Form.Item
                                    label={t("tedee.endtime")}
                                    name="endTime"
                                    rules={[{ required: hasTimeRange, message: t("tedee.error.endtime") }]}
                                >
                                    <TimePicker format="HH:mm" />
                                </Form.Item>
                            </>
                        )}
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={isSubmitting}>
							{t("tedee.pincreate")}
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
		</Create>
    );
};
