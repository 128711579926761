import {
  useTranslate,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import {
  List,
  Tabs,
} from "@pankod/refine-antd";

import { ExcelStandardExtract } from './excel'
import { ClientFidelsFormComponent, BookingExtractFormComponent } from './csv'

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
dayjs.extend(localizedFormat)

export const ExtractList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  return (
    <List
      title={`${t("menu.extracts")}`}
    >
      <Tabs defaultActiveKey="1" centered>
        <Tabs.TabPane tab={`${t('Resa / Zones')}`} key="1">
          <ExcelStandardExtract />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`${t('Resa / Status')}`} key="2">
          <BookingExtractFormComponent />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`${t('Clients')}`} key="3">
          <ClientFidelsFormComponent />
        </Tabs.TabPane>
      </Tabs>
    </List>
  );
};
