import {
  useTranslate,
  IResourceComponentsProps,
  CrudFilters,
  HttpError
} from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  Space,
  EditButton,
  CreateButton,
  ShowButton,
  Form,
  Input,
  Icons,
  Button,
  ListButton,
} from "@pankod/refine-antd";
import { IPriceZone } from "interfaces";
import { EditOutlined, EyeOutlined, PlusOutlined, PlusSquareOutlined } from "@ant-design/icons";
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'

export const PriceZoneList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter, searchFormProps } = useTable<IPriceZone, HttpError, IPriceZoneFilterVariables>({
      onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name } = params;
      filters.push(
          {
              field: "name",
              operator: "contains",
              value: name,
          },
      );
      return filters;
    },
    initialSorter: [
      {
        field: "name",
        order: "asc",
      },
    ],
  });

    const isLoading = false;

  return (
    <List 
      title={t("menu.pricezones")}
      pageHeaderProps={{
        extra: [
          <Form 
            layout="horizontal"
            onValuesChange={() => searchFormProps.form?.submit()}
            key="page_header_extra_1"
            {...searchFormProps}
          >
            <Form.Item label="" name="name" style={{ marginBottom: 0 }}>
              <Input
                  placeholder="ID, Title, Content, etc."
                  prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
          </Form>
        ],
        // subTitle:`${t("yieldanalysis.yieldanalysis")} ${resource?.dateref ? ' - ' + dayjs(resource.dateref).locale(t("short_lang")).format('ddd D/M') : ''} ${resource?.modified ? ` (${t('last_modified')} ` + dayjs(resource.modified).locale(t("short_lang")).format('D/M, HH:mm') + ')' : ''}`
      }}
    >
      {/* <Form 
        // layout="horizontal"
        onValuesChange={() => searchFormProps.form?.submit()}
        {...searchFormProps}
      >
        <Form.Item label="" name="name">
          <Input
              placeholder="ID, Title, Content, etc."
              prefix={<Icons.SearchOutlined />}
          />
        </Form.Item>
      </Form> */}

      <Table
        {...tableProps} 
        pagination={{
          ...tableProps.pagination,
          showSizeChanger: true,
        }}
        rowKey="id"
      >
        {/* <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        /> */}
        <Table.Column
          dataIndex="name"
          key="name"
          title={t("rooms.room")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
        <Table.Column<IPriceZone>

          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => {
          return(
            <Space>
              {record?.yieldanalysis?.[0]?.id && <EditButton size="small" resourceNameOrRouteName="yieldanalysis" recordItemId={record?.yieldanalysis?.[0]?.id}>{`${t("buttons.edit")} ${t("menu.yield")} ${dayjs(record?.yieldanalysis?.[0]?.dateref).locale(t("short_lang")).format('ddd D/M')}`}</EditButton>}
              {<ListButton size="small" resourceNameOrRouteName="yieldanalysis"></ListButton>}
              {/* <CreateButton href="test" resourceNameOrRouteName="yieldanalysis" size="small">{`${t("buttons.create")} ${t("menu.yield")}`}</CreateButton> */}
              <Button href={`${t("menu.yield")}/create/${record.id}`} size="small" icon={<PlusSquareOutlined />}>{`${t("buttons.create")} ${t("menu.yield")}`}</Button>
              {/* <EditButton size="small"  recordItemId={`${record.id}`}>{`${t("buttons.edit")} ${t("pricezones.pricezone")}`}</EditButton> */}
            </Space>
          )}}
        />
      </Table>
    </List>
  );
};

interface IPriceZoneFilterVariables {
  name: string;
}
