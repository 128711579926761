import React, { useEffect } from "react";

const useScript = (url: string) => {
    useEffect(() => {
      const script = document.createElement('script');
  
      script.src = url;
      script.async = true;
  
      document.body.appendChild(script);
      console.log(script)
  
      return () => {
        document.body.removeChild(script);
      }
    }, [url]);
};
  
// const useScript = (url: string) => {
//     useEffect(() => {
//         fetch(url)
//         .then(response => response.text())
//         .then(response => {
//             const script = document.createElement('script');
//             script.text = response
//             // script.async = true

//             console.log(response)
//             document.body.appendChild(script);
//         })
//         .catch(error => console.log(error));


    
//     //   script.src = url;
//     //   script.async = true;
  
//     //   document.body.appendChild(script);
//     //   console.log(script)
  
//     //   return () => {
//     //     document.body.removeChild(script);
//     //   }
//     }, [url]);
// };

export const OffLayoutArea: React.FC = () => {

    // @import url('https://fonts.googleapis.com/css?family=Roboto');

    // useEffect(() => {
    //     const script = document.createElement('script');
      
    //     script.src = `${window.location.protocol}//${window.location.hostname}/js/sms.cell.js`;
    //     script.async = true;
      
    //     document.body.appendChild(script);
      
    //     return () => {
    //       document.body.removeChild(script);
    //     }
    //   }, []);
    // console.log(`${window.location.protocol}//${window.location.hostname}`)
    // let protocol = window.location.protocol;
    
    // let GET_NAMES = new URL('<?= $this->Url->build(['controller' => 'Clients', 'action' => 'getNamesByPhones'], true) ?>');
    // let SEARCH_CONTACT = new URL('<?= $this->Url->build(['controller' => 'Clients', 'action' => 'search'], true) ?>');
    // let CSRF = '<?php echo $this->request->getParam('_csrfToken') ?>'

    // let GET_NAMES = new URL(`http://admin.mihotel.fr/erp/clients/get-names-by-phones`);
    // let SEARCH_CONTACT = new URL(`http://admin.mihotel.fr/erp/clients/search`);
    // // let GET_NAMES = new URL(`${window.location.protocol}//${window.location.hostname}`);
    // // let SEARCH_CONTACT = new URL(`${window.location.protocol}//${window.location.hostname}`);
    // let CSRF = ''

    // GET_NAMES.protocol = protocol;
    // SEARCH_CONTACT.protocol = protocol;

    useScript(`https://code.jquery.com/jquery-3.6.3.min.js`);
    useScript(`https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.4/moment.min.js`);
    useScript(`${window.location.protocol}//${window.location.hostname}/js/sms.cell.helper.js`, );
    useScript(`${window.location.protocol}//${window.location.hostname}/js/sms.cell.js`);
    
    return (
        <div key="sms-plugin">
            <link href={`https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css`} rel="stylesheet" />
            <link href={`${window.location.protocol}//${window.location.hostname}/css/sms.cell.css`} rel="stylesheet" />
            <div className="sms-cell-button" key="sms-plugin">
                <span>
                    <i className="fa fa-comments"></i>
                    <span className="notify-label n-red" data-content="notification-sms"></span>
                </span>
            </div>
            <div className="sms-panel">
                <div className="sms-panel--header">
                    <div className="sms-panel--header-back_arrow">
                        <i className="fa fa-arrow-left"></i>
                    </div>
                    <div className="sms-panel--header-title">
                        <span>Gestion des SMS</span>
                    </div>
                </div>
                <div className="sms-panel--body">

                </div>
                <div className="sms-send-modal">
                    <div className="close-btn">
                        <i className="fa fa-close"></i>
                    </div>
                    <div className="sms-send-modal--wrapper">
                        <div className="wrapper--header">
                            <input type="text" className="sms-input" name="phoneNumber" />
                        </div>
                        <div className="wrapper--body">
                            <textarea className="sms-content" name="phoneMessage"></textarea>
                            <div className="sms-material c-action c-flex-right c-icon-right c-blue" data-action="create-message">
                                <span>Envoyer</span><i className="fa fa-send"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
