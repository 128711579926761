import { useModalForm, Modal, DatePicker, Form, Input, Typography, Select, Space, Button, useSelect, FormInstance, Icon, NumberField, InputNumber, DeleteButton } from "@pankod/refine-antd";
import { IResourceComponentsProps, useNavigation, useTranslate } from "@pankod/refine-core";
import { InputPlusMinus } from "components/inputs/inputplusminus";
import { IBooking, IBookingRoom, IConso, IConsoBookingRoom } from "interfaces";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'
import React from "react";
import { useNavigate } from "core/customNavigation";
dayjs.extend(localizedFormat)

const { Title, Text } = Typography;

interface EditConsosModalProps {
  refetch: () => void;
}

export const EditConsosModal = forwardRef<any, EditConsosModalProps>(({refetch}, ref) => {
  const t = useTranslate();

  const { modalProps, formProps, form, queryResult, id, show, close, formLoading, } = useModalForm<IConsoBookingRoom>({
    action: "edit",
    redirect: false,
    resource: "products/consosbookingroom"
  });

  modalProps.title = <Title level={4} >{`${t("buttons.edit")} ${t("pages.booking.consos")}`}</Title>

  useImperativeHandle(ref, () => ({
    show(id: string) {
      show(id);
    },
  }));

  useEffect(() => {
    form.resetFields()
  }, [])

  return (
    <Form  {...formProps}>
      <Modal {...modalProps} afterClose={() => refetch()}>
        <React.Fragment>
          <div style={{ fontWeight: 700, paddingBottom: '1rem', paddingTop: '1rem' }}>{queryResult?.data?.data?.conso?.name}</div>
          <Space wrap>

            <Form.Item name={["conso_id"]} hidden >
              <Input />
            </Form.Item>

            <Form.Item name={["number"]} >
              <InputPlusMinus buttons integerOnly={true} min={1} />
            </Form.Item>

            <Form.Item name={["custom_unit_price"]} >
              <InputNumber controls={false} min={0} addonAfter={'€'} style={{ maxWidth: "7rem" }} />
            </Form.Item>
            
            <Form.Item>
              <DeleteButton size="small" hideText recordItemId={queryResult?.data?.data?.id} resourceNameOrRouteName="products/consosbookingroom" onSuccess={() => close()}/>
            </Form.Item>
            <br></br>
          </Space>
        </React.Fragment>
      </Modal>
    </Form>
  )
});
