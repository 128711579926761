import { useState } from "react";
import { useTranslate, IResourceComponentsProps } from "@pankod/refine-core";
import {
  Create,
  Form,
  Input,
  Select,
  useSelect,
  useForm,
} from "@pankod/refine-antd";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IAgenda, IPriceZone } from "interfaces";

export const AgendaCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { selectProps: pricezoneSelectProps } = useSelect<IPriceZone>({
    resource: "pricezones",
    optionLabel: "name",
  });


  const { formProps, saveButtonProps } = useForm<IAgenda>({
    resource: 'agendas',
    redirect: 'list'
  });


  return (
    <Create title={`${t('buttons.create')} ${t('agendas.agenda')}`}  saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("agendas.name")}
          name="name"
          rules={[
            {
              required: true,
              max: 70,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("menu.pricezones")}
          name={"pricezone_ids"}
        >
          <Select
            mode="multiple"
            allowClear
            options={pricezoneSelectProps.options}
          >
          </Select>
        </Form.Item>
      </Form>
    </Create>
  );
};
